const actions = {
  SET_STATE: 'supportgroups/SET_STATE',
  GET_SUPPORT_GROUPS: 'supportgroups/GET_SUPPORT_GROUPS',
  GET_SINGLESUPPORT_GROUPS: 'supportgroups/GET_SINGLE',
  UPDATE_SUPPORT_GROUPS: 'supportgroups/UPDATE_SUPPORT_GROUPS',
  SAVE_SUPPORT_GROUPS: 'supportgroups/SAVE_SUPPORT_GROUPS',
  DELETE_SUPPORT_GROUPS: 'supportgroups/DELETE_SUPPORT_GROUPS',
}

export default actions
