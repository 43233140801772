const actions = {
  SET_STATE: 'diag_offline_booking/SET_STATE',
  GET_CLINICS: 'diag_offline_booking/GET_CLINICS',
  GET_PRODUCTS_BY_CLINIC: 'diag_offline_booking/GET_PRODUCTS_BY_CLINIC',
  CREATE_DG_BOOKING: 'diag_offline_booking/CREATE_DG_BOOKING',
  GET_TEMP_TRANSACTIONS: 'diag_offline_booking/GET_TEMP_TRANSACTIONS',
  GET_TEMP_TRANSACTIONS_BY_ID: 'diag_offline_booking/GET_TEMP_TRANSACTIONS_BY_ID',
  GET_ORDERS: 'diag_offline_booking/GET_ORDERS',
  SHOW_QR_LINK: 'diag_offline_booking/SHOW_QR_LINK',
  RESEND_LINK: 'diag_offline_booking/RESEND_LINK',
  VERIFY_PAYMENT: 'diag_offline_booking/VERIFY_PAYMENT',
  GET_CUSTOM_PRODUCT: 'diag_off_booking/GET_CUSTOM_PRODUCT',
}

export default actions
