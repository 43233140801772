import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import ConcernServices from 'services/concerns'
import { history } from 'redux/store'
import actions from './actions'

const services = new ConcernServices()

export function* GETCATEGORIES(action) {
  if (!action.payload.id && !action.payload.data) {
    const response = yield doApiCall(services.getConcerns, action.payload, actions.SET_STATE)
    if (response?.success) {
      yield put({ type: actions.SET_STATE, payload: { concernsData: response.data } })
    }
  }
}

export function* GETSINGLECATEGORY(action) {
  if (action.payload.id && !action.payload.data) {
    const response = yield doApiCall(services.getSingleConcerns, action.payload, actions.SET_STATE)
    if (response?.success) {
      yield put({ type: actions.SET_STATE, payload: { singleConcernData: response.data } })
    }
  }
}

export function* UPDATE_CATEGORY(action) {
  const response = yield doApiCall(services.updateConcerns, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Concern Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
    history.goBack(-1)
  }
}

export function* SAVE_CATEGORY(action) {
  if (!action.payload.id && action.payload.data) {
    const response = yield doApiCall(services.saveConcerns, action.payload, actions.SET_STATE)
    if (response?.success) {
      notification.success({
        description: 'Concern Addedd',
      })
      yield put({ type: actions.SET_STATE, payload: { saveConcern: response.data } })
      history.goBack(-1)
    }
  }
}

export function* DELETECONCERN(action) {
  const response = yield doApiCall(services.deleteConcerns, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Concern deleted',
    })
    yield put({ type: actions.SET_STATE, payload: { saveConcern: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_CONCERNS, GETCATEGORIES)])
  yield all([takeEvery(actions.GET_SINGLE_CONCERN, GETSINGLECATEGORY)])
  yield all([takeEvery(actions.UPDATE_CONCERN, UPDATE_CATEGORY)])
  yield all([takeEvery(actions.SAVE_CONCERN, SAVE_CATEGORY)])
  yield all([takeEvery(actions.DELETE_CONCERN, DELETECONCERN)])
}
