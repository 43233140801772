import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import ProfileServices from 'services/labtestprofile'
import { history } from 'redux/store'
import actions from './actions'

const services = new ProfileServices()

export function* GETPROFILES(action) {
  const response = yield doApiCall(services.getProfiles, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { profilesData: response.data } })
  }
}

export function* GETSINGLEPROFILE(action) {
  const response = yield doApiCall(services.getSingleProfile, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleProfileData: response.data } })
  }
}

export function* UPDATE_PROFILE(action) {
  const response = yield doApiCall(services.updateProfile, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Profile Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
    history.goBack(-1)
  }
}

export function* SAVE_PROFILE(action) {
  const response = yield doApiCall(services.saveProfile, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Profile Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCategory: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_PROFILES, GETPROFILES)])
  yield all([takeEvery(actions.GET_SINGLE_PROFILE, GETSINGLEPROFILE)])
  yield all([takeEvery(actions.UPDATE_PROFILE, UPDATE_PROFILE)])
  yield all([takeEvery(actions.SAVE_PROFILE, SAVE_PROFILE)])
}
