import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'helper'
import TestServices from 'services/testReferral'
import { notification } from 'antd'
import actions from './actions'
// import { notification } from 'antd';

const services = new TestServices()

export function* WATCH_GET_TESTS(action) {
  const response = yield doApiCall(services.getTests, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { testData: response.data } })
  }
}
export function* WATCH_GET_TEST_DETAILS(action) {
  const response = yield doApiCall(services.getTestDetails, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        singleTestData: response.data,
      },
    })
  }
}
export function* WATCH_DELETE_TEST(action) {
  const response = yield doApiCall(services.deleteTest, action.payload, actions.SET_STATE)
  if (!response) {
    yield put({
      type: actions.GET_TESTS,
      payload: {
        param: {
          perPage: 15,
          page: 1,
          include: 'doctor,parent,timestamp',
        },
      },
    })
  }
}

export function* WATCH_CREATE_TEST(action) {
  const response = yield doApiCall(services.createTest, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
  }
}

export function* WATCH_UPDATE_TEST(action) {
  const response = yield doApiCall(services.updateTest, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Updated Successfully',
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_TESTS, WATCH_GET_TESTS)])
  yield all([takeEvery(actions.GET_TEST_DETAILS, WATCH_GET_TEST_DETAILS)])
  yield all([takeEvery(actions.CREATE_TEST, WATCH_CREATE_TEST)])
  yield all([takeEvery(actions.UPDATE_TEST, WATCH_UPDATE_TEST)])
  yield all([takeEvery(actions.DELETE_TEST, WATCH_DELETE_TEST)])
}
