/* eslint-disable no-unused-vars */
import { apiClient1, apiClient2 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'
// import { store } from 'redux/store'

class TestServices {
  constructor() {
    this.client = apiClient1
  }

  getTests = (payload = {}) => {
    // const { user } = store.getState().auth
    return this.client
      .get(`${BACKEND_ROUTES.GET_TESTS}`, {
        params: payload.param,
      })
      .then((res) => {
        // this.tempholdingdata=res.data.data.data
        // console.log(this.tempholdingdata)
        // this.tempholdingdata.map((element) => {
        // if(element.parent?.id) {
        //   console.log(element)
        //   const submitdata = {
        //     data: {
        //       name: element.name,
        //       url: element.url,
        //       labTestList: element.labTestList,
        //       description: element.description,
        //       type: element.type,
        //       parentId: 'dc0fd7be-dc57-4e6a-bf23-a1fe4bbec02c'
        //     }

        //   }
        //   this.createTest(submitdata)
        // }  else {
        // console.log(element, ' parent')
        //   const {id} = element.parent
        //   let name
        //   let cid
        //   this.tempholdingdata.map((el) => {
        //     if(id===el.id) {
        //       // eslint-disable-next-line prefer-destructuring
        //       name = element.name
        //       this.createdlist.map((ele) => {
        //         if(ele.name === name) {
        //           cid = ele.id
        //         }
        //         return true
        //       })
        //     }
        //     const pseudodata = {
        //       data: {
        //         name: el.name,
        //         type: el.type,
        //         url: el.url,
        //         parentId: cid,
        //         labTestList: el.labTestList,
        //         description: el.description
        //       }
        //     }
        //     // this.createTest(pseudodata)
        //     return true
        //   })
        //   return true
        // }
        // return true
        // })
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getTestDetails = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_TESTS}/${payload.id}`, { params: payload.param })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createTest = (payload = {}) => {
    return this.client
      .post(BACKEND_ROUTES.GET_TESTS, payload.data)
      .then((res) => {
        // console.log(this.createdlist)
        // this.createdlist.push(res.data.data.data)
        // console.log(this.createdlist)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateTest = (payload = {}) => {
    return this.client
      .put(`${BACKEND_ROUTES.GET_TESTS}/${payload.id}`, payload.data, { params: payload.param })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteTest = (payload = {}) => {
    return this.client
      .delete(`${BACKEND_ROUTES.GET_TESTS}/${payload.id}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}
export default TestServices
