import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import TestimonialService from 'services/testimonials'
import { history } from 'redux/store'
import actions from './actions'

const services = new TestimonialService()
export function* GETTESTIMONIALS(action) {
  const response = yield doApiCall(services.getTestimonials, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { testimonialsData: response.data } })
  }
}
export function* GETSINGLETESTIMONIALS(action) {
  // console.log('dispatch heard')
  if (action.payload.id) {
    const response = yield doApiCall(
      services.getSingleTestimonials,
      action.payload,
      actions.SET_STATE,
    )
    if (response?.success) {
      // console.log('dispatch heard', response.data)
      yield put({ type: actions.SET_STATE, payload: { singleTestimonialData: response.data } })
    }
  }
}
export function* UPDATETESTIMONIALS(action) {
  console.log('update heard', action.payload)
  const response = yield doApiCall(services.updateTestimonials, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield history.push('/testimonials')
    yield put({ type: actions.SET_STATE, payload: { updateTestimonialData: response.data } })
  }
}
export function* SAVETESTIMONIALS(action) {
  const response = yield doApiCall(services.saveTestimonials, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield history.push('/testimonials')
    yield put({ type: actions.SET_STATE, payload: { newTestimonialData: response.data } })
  }
}
export default function* rootSaga() {
  yield all([takeEvery(actions.GET_TESTIMONIALS, GETTESTIMONIALS)])
  yield all([takeEvery(actions.GET_SINGLETESTIMONIALS, GETSINGLETESTIMONIALS)])
  yield all([takeEvery(actions.UPDATE_TESTIMONIALS, UPDATETESTIMONIALS)])
  yield all([takeEvery(actions.SAVE_TESTIMONIALS, SAVETESTIMONIALS)])
}
