const actions = {
  SET_STATE: 'diag_orders/SET_STATE',
  GET_DIAGNOSTIC_ORDERS: 'diag_orders/GET_DIAGNOSTIC_ORDERS',
  GET_SINGLE_DIAGNOSTIC_ORDERS: 'diag_orders/GET_SINGLE_DIAGNOSTIC_ORDERS',
  UPDATE_DIAGNOSTIC_ORDERS: 'diag_orders/UPDATE_DIAGNOSTIC_ORDERS',
  UPDATE_DIAGNOSTIC_ORDERS_NORELOAD: 'diag_orders/UPDATE_DIAGNOSTIC_ORDERS_NORELOAD',
  UPDATE_STATUS: 'diag_orders/UPDATE_STATUS',
  GET_LINKS: 'diag_orders/GET_LINKS',
  UPDATE_USER: 'diag_orders/UPDATE_USER',
  UPDATE_PATIENT_PROFILES: 'diag_orders/UPDATE_PATIENT_PROFILES',
  PROFILE_UPDATE: 'diag_orders/PROFILE_UPDATE',
  CREATE_DGPROFILE: 'diag_orders/CREATE_DGPROFILE',
  UPDATE_META: 'diag_orders/UPDATE_META',
  GET_REPORT: 'diag_orders/GET_REPORT',
  GET_REPORTURL: 'diag_orders/GET_REPORTURL',
  CREATE_COUPONS: 'diag_orders/CREATE_COUPONS',
  INVOICE: 'diag_orders/INVOICE',
}

export default actions
