const actions = {
  SET_STATE: 'specializations/SET_STATE',
  GET_SPECIALIZATIONS: 'specialization/GET_SPECIALIZATIONS',
  GET_SPECIALIZATION: 'specialization/GET_SPECIALIZATION',
  UPDATE_SPECIALIZATION: 'specialization/UPDATE_SPECIALIZATION',
  SAVE_SPECIALIZATION: 'specialization/SAVE_SPECIALIZATION',
  DELETE_SPECIALIZATION: 'specialization/DELETE_SPECIALIZATION',
}

export default actions
