const { apiClient } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class QuestionServices {
  constructor() {
    this.client = apiClient
    // https://api.pfhtesting.proactiveforher.com/api/v1
  }

  getQuestions = (payload = {}) => {
    // console.log('service called')
    return this.client
      .get(`${BACKEND_ROUTES.GET_QUESTIONS}`, { params: payload })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleQuestions = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_QUESTIONS.concat('/').concat(temp[1])}`, {
        params: {
          include: payload.include,
        },
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateQuestions = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_QUESTIONS.concat('/').concat(temp[1])}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveQuestions = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_QUESTIONS}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteQuestions = (payload = {}) => {
    const deleteID = payload.id.substring(1)
    return this.client
      .delete(`${BACKEND_ROUTES.GET_QUESTIONS}/${deleteID}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default QuestionServices
