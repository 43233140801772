import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
// import Sidebar from '@vb/components/Sidebar'
// import SupportChat from '@vb/components/SupportChat'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
  isAuthTopbar: settings.isAuthTopbar,
})

const AuthLayout = ({
  children,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  isAuthTopbar,
}) => {
  return (
    <Layout className="vb__layout">
      <Layout.Content>
        {/* <Sidebar /> */}
        {/* <SupportChat /> */}
        <div
          className={classNames(`${style.container}`, {
            vb__layout__squaredBorders: isSquaredBorders,
            vb__layout__cardsShadow: isCardShadow,
            vb__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/8.jpeg)' : '',
          }}
        >
          {isAuthTopbar && (
            <div
              className={classNames(`${style.topbar}`, {
                [style.topbarGray]: isGrayTopbar,
              })}
            >
              <div className={style.logoContainer}>
                <div className={style.logo}>
                  <svg
                    width="175"
                    height="47"
                    viewBox="0 0 210 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.0337356 20.6745V0.95285H6.78394C7.88206 0.95285 8.88329 1.11088 9.69073 1.42693C10.5305 1.74298 11.2087 2.18545 11.7578 2.72274C12.3068 3.26003 12.7267 3.89214 12.9851 4.61906C13.2435 5.34597 13.3727 6.10449 13.3727 6.89462C13.3727 7.71636 13.2112 8.5065 12.9205 9.23342C12.5975 9.96034 12.1454 10.5924 11.564 11.1613C10.9503 11.7302 10.2075 12.1411 9.30316 12.4571C8.39883 12.7732 7.3653 12.9312 6.20259 12.9312C5.52434 12.9312 4.87838 12.8996 4.29702 12.8048C3.71567 12.71 3.1989 12.6152 2.68214 12.4887V20.6745H0.0337356ZM2.64985 11.2878C3.03742 11.4458 3.48959 11.5406 4.00635 11.5722C4.49081 11.6038 5.03987 11.6354 5.65352 11.6354C6.52556 11.6354 7.26841 11.509 7.88207 11.2245C8.49572 10.9401 9.01248 10.5608 9.40005 10.1184C9.78763 9.67589 10.1106 9.17021 10.2721 8.60131C10.4659 8.03242 10.5305 7.49513 10.5305 6.95784C10.5305 6.38894 10.4336 5.82005 10.2398 5.25116C10.046 4.68227 9.72303 4.17658 9.33546 3.70251C8.94789 3.22843 8.43113 2.88077 7.81747 2.59632C7.20382 2.31188 6.49327 2.18545 5.68582 2.18545H2.64985V11.2878Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M29.1641 0.95285C30.456 0.95285 31.5218 1.11088 32.3616 1.45854C33.2013 1.80619 33.8796 2.24867 34.364 2.75435C34.8485 3.26003 35.2038 3.82892 35.3975 4.42942C35.5913 5.02992 35.6882 5.59881 35.6882 6.10449C35.6882 6.67339 35.5913 7.24228 35.3975 7.84278C35.2038 8.44328 34.9131 8.98057 34.4932 9.48626C34.0733 9.99194 33.5243 10.4028 32.8783 10.7505C32.2324 11.0981 31.4249 11.2878 30.5206 11.351V11.4774L30.8113 11.6038C31.0374 11.7302 31.2312 11.8566 31.3926 12.0147C31.5541 12.1727 31.7802 12.4571 32.0386 12.8364C32.297 13.2157 32.6522 13.753 33.0721 14.4167C33.5243 15.112 34.1379 16.0285 34.9131 17.1663C35.1715 17.5456 35.4298 17.9248 35.6882 18.2093C35.9466 18.5253 36.205 18.7782 36.4634 18.9994C36.7217 19.2207 37.0124 19.3787 37.3354 19.5051C37.6584 19.6315 38.0136 19.6631 38.4012 19.6631C38.4981 19.6631 38.595 19.6631 38.6919 19.6631C38.7888 19.6631 38.8857 19.6315 38.9826 19.6315V20.6745C38.7242 20.8325 38.4335 20.9273 38.1751 20.9589C37.9168 20.9905 37.5615 21.0222 37.1416 21.0222C36.5603 21.0222 36.0758 20.9589 35.6559 20.8009C35.236 20.6429 34.8808 20.4533 34.5578 20.2004C34.2348 19.9476 33.9442 19.6315 33.6535 19.2839C33.3628 18.9362 33.1044 18.5253 32.8137 18.1145L30.5206 14.8275C30.1653 14.2903 29.8747 13.8478 29.6163 13.5001C29.3579 13.1525 29.0995 12.8364 28.8088 12.6152C28.5182 12.3939 28.1952 12.2359 27.8399 12.1411C27.4846 12.0463 27.0325 12.0147 26.4834 12.0147H25.2238V20.7377H22.64V0.95285H29.1641ZM25.2561 10.6872H28.2275C29.0026 10.6872 29.6809 10.5608 30.2622 10.308C30.8436 10.0552 31.3603 9.70749 31.7479 9.26501C32.1678 8.85415 32.4585 8.38007 32.6522 7.84278C32.846 7.3371 32.9429 6.79981 32.9429 6.29413C32.9429 5.69363 32.846 5.12474 32.6199 4.61906C32.3939 4.11337 32.0709 3.6709 31.651 3.32324C31.2311 2.97558 30.7144 2.65953 30.133 2.4699C29.5517 2.24866 28.8734 2.15384 28.1629 2.15384H25.2561V10.6872Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M46.8623 7.4031C47.2821 6.2021 47.9281 5.09592 48.8001 4.08455C49.6721 3.07318 50.8026 2.25145 52.1591 1.58774C53.5156 0.924031 55.1627 0.607977 57.0683 0.607977C58.9739 0.607977 60.6533 0.955639 62.0744 1.61935C63.4955 2.28306 64.6582 3.1364 65.5626 4.14776C66.4992 5.15913 67.1775 6.26531 67.5973 7.46631C68.0495 8.66731 68.2756 9.77349 68.2756 10.8481C68.2756 11.5434 68.1787 12.2703 67.9849 13.0604C67.7911 13.819 67.5004 14.6091 67.0806 15.3676C66.693 16.1261 66.1762 16.8531 65.5626 17.5484C64.9489 18.2437 64.2384 18.8442 63.3986 19.3815C62.5589 19.9188 61.6223 20.3296 60.5887 20.6457C59.5552 20.9617 58.3602 21.1198 57.1006 21.1198C55.195 21.1198 53.5802 20.7721 52.1914 20.1084C50.8349 19.4447 49.7044 18.5913 48.8324 17.58C47.9604 16.5686 47.3144 15.4624 46.8946 14.2614C46.4747 13.0604 46.2809 11.9542 46.2809 10.8797C46.2486 9.74188 46.4747 8.60409 46.8623 7.4031ZM49.7044 14.4511C50.1243 15.5572 50.6734 16.5054 51.4162 17.2955C52.1591 18.0857 52.9988 18.6862 53.9677 19.1286C54.9367 19.5711 56.0025 19.7923 57.1652 19.7923C58.2956 19.7923 59.3614 19.5711 60.3626 19.1286C61.3639 18.6862 62.2682 18.0857 63.0111 17.2955C63.7539 16.5054 64.3676 15.5572 64.8197 14.4511C65.2719 13.3449 65.498 12.1439 65.498 10.8165C65.498 9.48905 65.2719 8.28805 64.8197 7.18187C64.3676 6.07568 63.7862 5.12753 63.0111 4.3374C62.2682 3.54727 61.3639 2.94676 60.3626 2.50429C59.3614 2.06182 58.2956 1.84058 57.1652 1.84058C56.0025 1.84058 54.9367 2.06182 53.9677 2.50429C52.9988 2.94676 52.1268 3.54727 51.4162 4.3374C50.7057 5.12753 50.1243 6.07568 49.7044 7.18187C49.2846 8.28805 49.0908 9.48905 49.0908 10.8165C49.0908 12.1439 49.2846 13.3449 49.7044 14.4511Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M79.4216 14.8591L76.9024 20.6745H75.5781L84.1047 0.95285H85.5581L94.9567 20.6745H92.1791L89.4015 14.8591H79.4216ZM84.2339 4.11337H84.1047L79.9706 13.5949H88.7556L84.2339 4.11337Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M122.023 17.8C121.765 18.0845 121.41 18.4005 120.925 18.7798C120.441 19.159 119.859 19.5067 119.117 19.8227C118.406 20.1388 117.566 20.4232 116.597 20.6445C115.628 20.8657 114.53 20.9921 113.303 20.9921C111.365 20.9921 109.686 20.7077 108.297 20.1072C106.908 19.5067 105.745 18.7482 104.841 17.7684C103.937 16.7886 103.258 15.6825 102.839 14.4499C102.419 13.1857 102.193 11.9214 102.193 10.594C102.193 9.07697 102.451 7.68634 103 6.45374C103.549 5.22113 104.292 4.17816 105.261 3.29322C106.23 2.40827 107.425 1.74456 108.781 1.27048C110.138 0.796405 111.688 0.575165 113.335 0.575165C114.498 0.575165 115.499 0.701588 116.404 0.922824C117.308 1.14406 118.083 1.46011 118.761 1.83937C119.44 2.21864 119.989 2.69271 120.441 3.26161C120.893 3.7989 121.248 4.3994 121.539 5.0315H119.407C119.278 4.65224 119.052 4.24138 118.697 3.86211C118.374 3.48285 117.922 3.13519 117.405 2.81914C116.888 2.50308 116.274 2.28185 115.596 2.09222C114.918 1.90259 114.175 1.80778 113.335 1.80778C112.14 1.80778 111.01 2.029 109.976 2.47148C108.943 2.91395 108.071 3.51445 107.328 4.27298C106.585 5.0315 106.004 5.94806 105.584 7.02264C105.164 8.09721 104.97 9.2666 104.97 10.5308C104.97 11.7318 105.164 12.9012 105.552 14.0074C105.939 15.1136 106.521 16.0933 107.296 16.9467C108.071 17.8 109.007 18.4637 110.17 18.9694C111.333 19.4751 112.657 19.7279 114.143 19.7279C116.823 19.7279 119.149 18.8114 121.119 17.0099L122.023 17.8Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M135.845 20.6745V2.5015H128.126V0.95285H146.18V2.5015H138.461V20.6745H135.845Z"
                      fill="#E1C0B0"
                    />
                    <path d="M158.937 0.95285V20.6745H156.321V0.95285H158.937Z" fill="#E1C0B0" />
                    <path
                      d="M172.471 0.95285L179.382 17.1979H179.511L186.229 0.95285H187.715L179.544 20.6745H177.8L169.37 0.95285H172.471Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M210 0.95285V2.5015H199.6V9.58107H208.514V10.8769H199.6V19.1258H210V20.6745H196.984V0.95285H210Z"
                      fill="#E1C0B0"
                    />
                    <path d="M209.965 35.8177H150.214V36.7659H209.965V35.8177Z" fill="#E1C0B0" />
                    <path d="M59.7506 35.8177H0V36.7659H59.7506V35.8177Z" fill="#E1C0B0" />
                    <path
                      d="M77.225 33.0026H75.1257L73.1879 41.9152C72.8649 43.4007 72.3804 44.4753 71.7668 45.139C71.1531 45.8343 70.2488 46.1503 69.1184 46.1503C68.7631 46.1503 68.4078 46.0871 68.0202 45.9923L68.1817 45.139C68.5047 45.2338 68.7954 45.2654 69.0538 45.2654C69.6674 45.2654 70.1196 45.0125 70.4749 44.5069C70.8301 44.0012 71.1208 43.1162 71.4115 41.8836L73.3816 32.9394H71.6699L71.7668 32.2756L73.5754 31.8964L73.7692 30.885C74.2214 28.7043 75.3841 27.5981 77.3219 27.5981C78.1294 27.5981 78.7107 27.7245 79.1306 27.9773C79.5505 28.2302 79.7442 28.5462 79.7442 28.9571C79.7442 29.7472 79.2275 30.1581 78.194 30.1581C78.194 29.0203 77.871 28.4514 77.2573 28.4514C76.8052 28.4514 76.4499 28.641 76.1915 29.0203C75.9331 29.3996 75.7394 29.9685 75.5779 30.727L75.3195 31.8964H77.3865L77.225 33.0026Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M84.7183 31.7745C85.8164 31.7745 86.6884 32.0906 87.3344 32.7543C87.9803 33.418 88.271 34.3345 88.271 35.5671C88.271 37.2106 87.7543 38.6645 86.753 39.9919C85.7195 41.3193 84.3953 41.9514 82.7482 41.9514C81.6177 41.9514 80.7457 41.6037 80.0997 40.94C79.4538 40.2763 79.1631 39.3282 79.1631 38.1588C79.1631 36.5153 79.6799 35.0299 80.7134 33.734C81.7146 32.4382 83.0388 31.7745 84.7183 31.7745ZM82.9419 41.0665C83.9755 41.0665 84.7829 40.466 85.3965 39.265C86.0102 38.064 86.3009 36.7365 86.3009 35.2827C86.3009 33.576 85.6872 32.7227 84.4599 32.7227C83.4264 32.7227 82.619 33.3232 82.0053 34.5242C81.3916 35.7252 81.0687 37.0526 81.0687 38.538C81.0687 40.2131 81.7146 41.0665 82.9419 41.0665Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M98.1882 34.4306C98.1882 33.4508 97.9298 32.9767 97.4453 32.9767C96.864 32.9767 96.2503 33.5456 95.6044 34.6518C94.9584 35.758 94.474 37.0222 94.151 38.4444L93.4404 41.7946H91.6964L93.2789 34.4306C93.3435 34.0829 93.3758 33.8301 93.3758 33.6088C93.3758 33.0399 92.8914 32.7555 91.8902 32.7555H91.7287L91.8902 31.997H95.2168L94.8292 34.6518H95.023C95.5075 33.5456 96.0242 32.8187 96.5087 32.4078C96.9932 32.0286 97.5745 31.8073 98.2205 31.8073C99.254 31.8073 99.7708 32.2182 99.7708 33.0715C99.7385 33.9565 99.2217 34.4306 98.1882 34.4306Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M113.27 34.2691C114.304 32.6256 115.466 31.8039 116.726 31.8039C118.05 31.8039 118.728 32.4676 118.728 33.8266C118.728 34.2059 118.632 34.9012 118.405 35.8493L117.921 38.0301C117.759 38.757 117.663 39.4207 117.663 39.958C117.663 40.5585 117.889 40.843 118.309 40.843C118.664 40.843 119.084 40.7166 119.504 40.4321L119.827 40.9694C119.019 41.6647 118.244 42.0124 117.533 42.0124C117.017 42.0124 116.597 41.8543 116.306 41.5067C115.983 41.1906 115.854 40.7481 115.854 40.1792C115.854 39.6104 115.983 38.7886 116.209 37.714L116.629 35.8177C116.823 35.0908 116.887 34.5219 116.887 34.1111C116.887 33.3841 116.597 33.0365 116.015 33.0365C115.305 33.0365 114.594 33.5106 113.884 34.4271C113.141 35.3437 112.689 36.2602 112.495 37.1768L111.494 41.8543H109.75L112.172 30.5081C112.301 29.9708 112.333 29.6547 112.333 29.5283C112.333 28.9594 111.881 28.675 111.009 28.675H110.848L111.009 27.9164H114.465L113.722 31.2666C113.496 32.278 113.27 33.2893 112.979 34.3007H113.27V34.2691Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M129.549 39.4822C129.678 39.5454 129.743 39.7034 129.743 39.9247C129.743 40.3987 129.452 40.8728 128.871 41.2837C128.289 41.7262 127.482 41.9158 126.513 41.9158C125.415 41.9158 124.543 41.5681 123.865 40.9044C123.186 40.2407 122.863 39.2926 122.863 38.1232C122.863 36.5113 123.38 35.0575 124.446 33.73C125.512 32.4026 126.836 31.7389 128.451 31.7389C129.258 31.7389 129.872 31.9286 130.292 32.3078C130.712 32.6871 130.938 33.1927 130.938 33.8565C130.938 34.8362 130.421 35.7212 129.355 36.4481C128.289 37.175 127.03 37.5543 125.512 37.5543H125.189C125.092 37.5543 124.963 37.5543 124.866 37.5227C124.833 37.6807 124.834 37.9335 124.834 38.2812C124.834 39.9247 125.512 40.778 126.901 40.778C127.934 40.8096 128.806 40.3671 129.549 39.4822ZM124.995 36.7009H125.189C126.287 36.7009 127.191 36.4481 127.934 35.9108C128.645 35.3735 129 34.7098 129 33.8565C129 33.0347 128.645 32.6239 127.902 32.6239C127.288 32.6239 126.739 33.0031 126.222 33.73C125.706 34.4886 125.286 35.4683 124.995 36.7009Z"
                      fill="#E1C0B0"
                    />
                    <path
                      d="M140.69 34.4306C140.69 33.4508 140.432 32.9767 139.947 32.9767C139.366 32.9767 138.752 33.5456 138.107 34.6518C137.461 35.758 136.976 37.0222 136.653 38.4444L135.943 41.7946H134.199L135.781 34.4306C135.846 34.0829 135.878 33.8301 135.878 33.6088C135.878 33.0399 135.394 32.7555 134.392 32.7555H134.231L134.392 31.997H137.719L137.331 34.6518H137.525C138.01 33.5456 138.526 32.8187 139.011 32.4078C139.495 32.0286 140.077 31.8073 140.723 31.8073C141.756 31.8073 142.273 32.2182 142.273 33.0715C142.241 33.9565 141.724 34.4306 140.69 34.4306Z"
                      fill="#E1C0B0"
                    />
                  </svg>
                </div>
              </div>
              {/* <div className="d-none d-sm-block">
                <span className="mr-2">Don&#39;t have an account?</span>
                <Link to="/auth/register" className="font-size-16 vb__utils__link">
                  Sign up
                </Link>
              </div> */}
            </div>
          )}
          <div className="mb-5">{children}</div>
          {/* <div className="mt-auto pb-5 pt-5">
            <ul
              className={`${style.footerNav} list-unstyled d-flex mb-0 flex-wrap justify-content-center`}
            >
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Compliance
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Support
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Contacts
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  About
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Advertising
                </a>
              </li>
            </ul>
            <div className="text-center">
              Copyright © {new Date().getFullYear()}{' '}
              <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer">
                Sellpixels.com
              </a>
              {' | '}
              <a href="https://sellpixels.com/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </div>
          </div> */}
          <div className="mt-auto pb-5 pt-5">
            <div className="text-center">
              Copyright © {new Date().getFullYear()}{' '}
              <a href="https://proactiveforher.com/" target="_blank" rel="noopener noreferrer">
                Proactive For Her
              </a>
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
