import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import FaqServices from 'services/faqs'
import { history } from 'redux/store'
import actions from './actions'

const services = new FaqServices()

export function* GETFAQS(action) {
  const response = yield doApiCall(services.getFaqs, action.payload, actions.SET_STATE)
  console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { faqs: response?.data } })
  }
}

export function* GETSINGLEFAQ(action) {
  const response = yield doApiCall(services.getSingleFaq, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleFaq: response.data } })
  }
}

export function* UPDATEFAQ(action) {
  const response = yield doApiCall(services.updateFaq, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Faq Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updateFaq: response.data } })
    history.goBack(-1)
  }
}

export function* SAVEFAQS(action) {
  const response = yield doApiCall(services.saveFaq, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Faq Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveFaq: response.data } })
    history.goBack(-1)
  }
}

export function* DELETEFAQ(action) {
  const response = yield doApiCall(services.deleteFaq, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Faq Deleted',
    })
    yield put({ type: actions.SET_STATE, payload: { deleteFaq: response.data } })
  }
  history.goBack(-1)
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_FAQS, GETFAQS)])
  yield all([takeEvery(actions.GET_SINGLEFAQ, GETSINGLEFAQ)])
  yield all([takeEvery(actions.UPDATE_FAQ, UPDATEFAQ)])
  yield all([takeEvery(actions.SAVE_FAQS, SAVEFAQS)])
  yield all([takeEvery(actions.DELETE_FAQ, DELETEFAQ)])
}
