const actions = {
  SET_STATE: 'concerns/SET_STATE',
  GET_CONCERNS: 'concerns/GET_CONCERNS',
  GET_SINGLE_CONCERN: 'concerns/GET_SINGLE_CONCERNS',
  UPDATE_CONCERN: 'concerns/UPDATE_CONCERNS',
  SAVE_CONCERN: 'concerns/SAVE_CONCERNS',
  DELETE_CONCERN: 'concerns/DELETE_CONCERNS',
}

export default actions
