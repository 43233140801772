import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import CompanyServices from 'services/companies'
import dayjs from 'dayjs'
import { history } from 'redux/store'

import actions from './actions'

const services = new CompanyServices()

export function* GETCOMPANIES(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.getCompanies, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { companiesData: response.data } })
  }
}

export function* GETSINGLECOMPANIES(action) {
  const response = yield doApiCall(services.getSingleCompanies, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleCompany: response.data } })
  }
}

export function* UPDATECOMPANIES(action) {
  const response = yield doApiCall(services.updateCompanies, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedCompany: response.data } })
  }
}

export function* SAVECOMPANIES(action) {
  const response = yield doApiCall(services.saveCompanies, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield put({ type: actions.SET_STATE, payload: { newCompany: response.data } })
  }
}

export function* GETCUSTOMERS(action) {
  const response = yield doApiCall(services.getCustomers, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { customersData: response.data } })
  }
}

export function* GETSTATS(action) {
  const response = yield doApiCall(services.getStats, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { statsData: response.data } })
  }
}

export function* GETSINGLEUSER(action) {
  const response = yield doApiCall(services.getSingleUser, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleUserData: response.data } })
  }
}

export function* UPDATEUSER(action) {
  const response = yield doApiCall(services.updateUser, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'User Updated Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { singleUserData: response.data } })
    GETSINGLEUSER(action)
    notification.success({
      message: 'Profile Updated Successfully',
    })
  }
}

export function* ADDUSER(action) {
  const response = yield doApiCall(services.addUser, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'User Added Successfully',
    })
    history.push(`/user/edit/:${response.data.id}`)
  }
}

export function* LOGINUSER(action) {
  const response = yield doApiCall(services.loginUser, action.payload, actions.SET_STATE)
  if (response?.success) {
    if (response.code === 201 || response.code === 200) {
      history.push(`/user/edit/:${response.data.id}`)
      notification.success({
        message: 'User Already Exists!',
      })
    } else if (response.code === 400) {
      /** Unregistered? */
      if (response.message.includes('not registered')) {
        history.push(`/user/add?mobile=${action.payload.data.mobile}}`)
      }
    }
  }
}

export function* SAVEUSER(action) {
  const response = yield doApiCall(services.saveUser, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleUserData: response.data } })
  }
}

export function* GETCSVDWNLD(action) {
  const response = yield doApiCall(services.getCSV, action.payload, actions.SET_STATE)
  if (response?.success) {
    let data = response.data.map(({ fullName, name, membershipDetails, ...keepAttrs }) => keepAttrs)
    data = data?.map((x) => {
      x.createdAt = dayjs(x.createdAt).format('DD/MM/YYYY hh:mm A')
      x.dob = x.dob ? dayjs(x.dob).format('DD/MM/YYYY') : 'NA'
      x.membershipStartDate = x.membershipStartDate
        ? dayjs(x.membershipStartDate).format('DD/MM/YYYY')
        : 'NA'
      x.membershipEndDate = x.membershipEndDate
        ? dayjs(x.membershipEndDate).format('DD/MM/YYYY')
        : 'NA'
      x.lastActive = x.lastActive ? dayjs(x.lastActive).format('DD/MM/YYYY hh:mm A') : 'NA'
      x.isPremiumMember = x.isPremiumMember === 1 ? 'YES' : 'NO'
      x.mobile = `${x.countryCode}${x.mobile}`
      x.aocs = x?.aocs.map((a) => a.name).join(',')
      delete x.countryCode
      return x
    })
    yield put({ type: actions.SET_STATE, payload: { downloadedCSV: data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_COMPANIES, GETCOMPANIES)])
  // yield all([takeEvery(actions.GET_SINGLECOMPANIES, GETSINGLECOMPANIES)])
  yield all([takeEvery(actions.GET_SINGLECOMPANIES, GETSINGLECOMPANIES)])
  yield all([takeEvery(actions.UPDATE_COMPANIES, UPDATECOMPANIES)])
  yield all([takeEvery(actions.SAVE_COMPANIES, SAVECOMPANIES)])
  yield all([takeEvery(actions.GET_CUSTOMERS, GETCUSTOMERS)])
  yield all([takeEvery(actions.GET_STATS, GETSTATS)])
  yield all([takeEvery(actions.GET_SINGLEUSER, GETSINGLEUSER)])
  yield all([takeEvery(actions.ADD_USER, ADDUSER)])
  yield all([takeEvery(actions.LOGIN_USER, LOGINUSER)])
  yield all([takeEvery(actions.UPDATE_USER, UPDATEUSER)])
  yield all([takeEvery(actions.SAVE_USERS, SAVEUSER)])
  yield all([takeEvery(actions.GET_CSV_DWNLD, GETCSVDWNLD)])
}
