import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import SymptomsService from 'services/symptoms'
import { history } from 'redux/store'
import actions from './actions'

const services = new SymptomsService()

export function* GETSYMPTOMS(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.getSymptoms, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { symptomsData: response.data } })
  }
}

export function* GETSINGLESYMPTOMS(action) {
  if (action?.payload?.id) {
    // console.log('dispatch heard')
    const response = yield doApiCall(services.getSingleSymptom, action.payload, actions.SET_STATE)
    if (response?.success) {
      // console.log('dispatch heard', response.data)
      yield put({ type: actions.SET_STATE, payload: { singleSymptom: response.data } })
    }
  }
}

export function* UPDATESYMPTOMS(action) {
  const response = yield doApiCall(services.updateSymptom, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield history.push('/symptoms')
    yield put({ type: actions.SET_STATE, payload: { updateSymptom: response.data } })
  }
}

export function* SAVESYMPTOMS(action) {
  const response = yield doApiCall(services.saveSymptom, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield history.push('/symptoms')
    yield put({ type: actions.SET_STATE, payload: { newSymptomSystem: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_SYMPTOMS, GETSYMPTOMS)])
  yield all([takeEvery(actions.GET_SINGLESYMPTOMS, GETSINGLESYMPTOMS)])
  yield all([takeEvery(actions.UPDATE_SYMPTOMS, UPDATESYMPTOMS)])
  yield all([takeEvery(actions.SAVE_SYMPTOMS, SAVESYMPTOMS)])
}
