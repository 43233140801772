// Read :- r
// Edit/Delete:- w

// eslint-disable-next-line import/prefer-default-export
export const config = {
  superAdmin: {
    role: 'Super Admin',
    roleId: 'superAdmin',
    name: 2,
    defaultRoute: '/appointments',
  },
  dgAdmin: {
    role: 'DG Admin',
    roleId: 'dgAdmin',
    name: 6,
    defaultRoute: '/appointments',
  },
  admin: {
    role: 'Admin',
    roleId: 'admin',
    name: 1,
    defaultRoute: '/companies',
  },
  ops: {
    role: 'Clinical Ops',
    roleId: 'ops',
    name: 3,
    defaultRoute: '/appointments',
  },
  content: {
    role: 'Content + Marketing',
    roleId: 'content',
    name: 4,
    // name: 7,
    defaultRoute: '/blogs',
  },
  support: {
    role: 'Support',
    roleId: 'support',
    name: 5,
    defaultRoute: '/appointments',
  },
  carePackage: {
    role: 'carePackage',
    roleId: 'carePackage',
    name: 6,
    defaultRoute: '/appointments',
  },
  supportRead: {
    role: 'Support Read',
    roleId: 'supportRead',
    name: 7,
    defaultRoute: '/companies',
  },
  global: null,
  bundlesTabs: [
    { name: 'TeleConsult', disabled: false, key: '1' },
    { name: 'Diagnostics', key: '2', disabled: false },
    { name: 'AtClinics', disabled: false, key: '3' },
    // Vaccines : [],
  ],
  physicianInputData: {
    Profile: [
      {
        name: 'name',
        value: String,
      },
      // {
      //   name: 'mobile',
      //   value: Number,
      // },
      {
        name: 'experience_years',
        value: Number,
      },
    ],
    Specialization: [
      {
        name: 'specialization_id',
        value: String,
      },
      {
        name: 'speciality',
        value: String,
      },
      {
        name: 'rank',
        value: Number,
      },
      {
        name: 'image_id',
        value: Number,
      },
    ],
    Consultation: [
      {
        name: 'consultation_amount',
        value: Number,
      },
      {
        name: 'promo_consultation_amount',
        value: Number,
      },
      {
        name: 'calendly_url',
      },
      {
        name: 'has_promo',
      },
      {
        name: 'payment_url',
      },
    ],
    Description: [
      {
        name: 'about',
        value: String,
      },
    ],
    Education: [],
    Experience: [],
    // Followup: [],
    AvailabilityPool: [],
    Unavailability: [],
    // Availability: []
    Clinic: [],
  },
}
export const RecommendationsAndReferrals = {
  1: 'diagnostics',
  2: 'program',
  3: 'vaccine',
  4: 'blrclinic',
  5: 'customtest',
  6: 'care packages',
}
export const BucketType = {
  0: 'GENERAL',
  1: 'USER',
  // 2: 'NEW_USER',
  3: 'DOCTOR',
  4: 'USER-DOCTOR',
  5: 'USER-SPECIALIZATION',
  // 6: 'PARTNERSHIP',
}

export const Programmes = [
  { key: 0, name: 'PCOS' },
  { key: 1, name: 'VAGINISMUS_PLAN_A' },
  { key: 2, name: 'VAGINISMUS_PLAN_B' },
  { key: 3, name: 'VAGINISMUS_PLAN_C' },
  { key: 4, name: 'VAGINISMUS_PLAN_D' },
  { key: 5, name: 'NUTRITION' },
  { key: 6, name: 'MENTAL_HEALTH' },
  { key: 7, name: 'CONSULTATION' },
  { key: 8, name: 'MEMBERSHIP' },
  { key: 9, name: 'SUPPORT_GROUPS' },
]

export const gender = [
  { key: 0, name: 'Male', value: 'male', view: true },
  { key: 1, name: 'Female', value: 'female', view: true },
  { key: 4, name: 'Both', value: 'both', view: true },
  { key: 2, name: 'Inter Sex', value: 'interSex', view: false },
  { key: 3, name: 'Prefer Not To Say', value: 'PreferNotToSay', view: false },
]

export const urineSampleType = [
  { name: 'Mid Stream', value: 'midStream' },
  { name: 'First Catch', value: 'firstCatch' },
  { name: 'NA', value: 'NA' },
]
export const cpStatuses = [
  { name: 'INACTIVE', value: 'INACTIVE', key: 0 },
  { name: 'ACTIVE', value: 'ACTIVE', key: 1 },
  { name: 'ARCHIVED', value: 'ARCHIVED', key: 2 },
]

export const servicableAt = [
  {
    name: 'At home',
    value: 0,
    key: 0,
    val: 'at_home',
  },
  {
    name: 'At Partner',
    value: 1,
    key: 1,
    val: 'at_partner',
  },
  {
    name: 'At Clinic',
    value: 2,
    key: 2,
    val: 'at_clinic',
  },
  {
    name: 'At Home + Partner',
    value: 3,
    key: 3,
    val: 'at_home_at_partner',
  },
  {
    name: 'At Clinic + Home',
    value: 4,
    key: 4,
    val: 'at_clinic_at_home',
  },
  {
    name: 'At Clinic + Partner',
    value: 5,
    key: 5,
    val: 'at_clinic_at_partner',
  },
  {
    name: 'All',
    value: 6,
    key: 6,
    val: 'all',
  },
]

export const CouponsAllowedTypes = [
  { name: 'Vaginismus', key: 'VAG' },
  { name: 'PCOS', key: 'PCOS' },
  { name: 'Nutrition', key: 'NUT' },
  { name: 'Mental Health', key: 'MH' },
  { name: 'Diagnostic', key: 'DIAGNOSTIC' },
  { name: 'Packages', key: 'PACKAGES' },
  { name: 'Consultation', key: 'CONSULTATION' },
]

export const CouponServices = {
  // 'All' : 'ALL',
  // 1: 'TELECONSULATION',
  'DIAGNOSTICS PRODUCT': 'DIAGNOSTICS_PRODUCT',
  'DIAGNOSTIC CUSTOM PRODUCT': 'DIAGNOSTIC_CUSTOM_PRODUCT',
  // 4: 'CLINICS',
  // 5: 'PROGRAMS',
  // 6: 'PACKAGES',
}
// [
//   {id: 'ALL', name: 'ALL'},
//   {id: 'TELECONSULATION', name: 'TELECONSULATION'},
//   {id: 'DIAGNOSTICS_PRODUCT', name: 'DIAGNOSTICS PRODUCT'},
//   {id: 'DIAGNOSTIC_CUSTOM_PRODUCT', name: 'DIAGNOSTIC CUSTOM PRODUCT'},
//   {id: 'CLINICS', name: 'CLINICS'},
//   {id: 'PROGRAMS', name: 'PROGRAMS'},
//   {id: 'PACKAGES', name: 'PACKAGES'},
// ]

export const dgTimeSlots = [
  {
    key: 'slot1',
    value: '07:00 AM - 10:00 AM',
  },
  {
    key: 'slot2',
    value: '10:00 AM - 01:00 PM',
  },
  {
    key: 'slot3',
    value: '1:00 PM - 04:00 PM',
  },
  {
    key: 'slot4',
    value: '04:00 PM - 06:00 PM',
  },
  {
    key: 'slot5',
    label: '11:00 AM - 07:00 PM',
  },
]

export const labNames = [
  {
    id: 0,
    key: 'ProactiveBangaloreClinic',
    value: 'Proactive Bangalore Clinic - Indiranagar',
  },
  {
    id: 10,
    key: 'ProactiveBangaloreClinicKormangala',
    value: 'Proactive Bangalore Clinic - Koramangala',
  },
  {
    id: 1,
    key: 'NeubergDiagnosticsPrivateLimited',
    value: 'Neuberg Diagnostics Private Limited',
  },
  {
    id: 2,
    key: 'MetropolisHealthcareLtd',
    value: 'Metropolis Healthcare Ltd',
  },
  {
    id: 3,
    key: 'ThyrocareTechnologiesLtd',
    value: 'Thyrocare Technologies Ltd',
  },
  {
    id: 4,
    key: 'IGeneticDiagnosticsPriavteLimited',
    value: 'IGenetic Diagnostics Priavte Limited',
  },
  {
    id: 5,
    key: 'RedcliffeLabs',
    value: 'Redcliffe Labs',
  },
  {
    id: 6,
    key: 'Healthians(ExpedientHealthcareMarketingPrivateLimited)',
    value: 'Healthians (Expedient Healthcare Marketing Private Limited)',
  },
  {
    id: 7,
    key: 'SoulDiagnostics(medray)',
    value: 'Soul Diagnostics (medray)',
  },
  {
    id: 8,
    key: 'NiramaiHealthAnalytix',
    value: 'Niramai Health Analytix',
  },
  {
    id: 9,
    key: 'KasperDiagnosticCenter',
    value: 'Kasper Diagnostic Center',
  },
  {
    id: 11,
    key: '1mgLabs',
    variable: '1mg Labs',
  },
]

export const couponStatus = [
  {
    status: 'Active',
    color: '#73d13d',
  },
  {
    status: 'Inactive',
    color: 'danger',
  },
  {
    status: 'Archived',
    color: 'cyan',
  },
]

export const ClinicTabs = [
  { name: 'Profile' },
  { name: 'Banner' },
  { name: 'Symptoms' },
  { name: 'Features' },
  { name: 'Providers' },
  { name: 'Testimonials' },
  { name: 'Membership' },
  { name: 'Blogs' },
  { name: 'Videos' },
]

export const testRecommendationTabs = {
  1: 'Diagnostics',
  2: 'Program',
  3: 'Vaccine',
  4: 'Banglore Clinic',
  6: 'Care Packages',
}

export const days = [
  { key: 0, day: 'Sunday', slots: [] },
  { key: 1, day: 'Monday', slots: [] },
  { key: 2, day: 'Tuesday', slots: [] },
  { key: 3, day: 'Wednesday', slots: [] },
  { key: 4, day: 'Thursday', slots: [] },
  { key: 5, day: 'Friday', slots: [] },
  { key: 6, day: 'Saturday', slots: [] },
]

// "availabilities": [
//   {
//     "Monday": "11am - 2pm , 4pm-9pm"
//   },
//   {
//     "Tuesday": "11am - 2pm , 4pm-9pm"
//   },
//   {
//     "Wednesday": "11am - 2pm , 4pm-9pm"
//   },
//   {
//     "Thursday": "11am - 2pm , 4pm-9pm"
//   },
//   {
//     "Friday": "11am - 2pm , 4pm-9pm"
//   },
//   {
//     "Saturday": "11am - 2pm , 4pm-9pm"
//   },
//   {
//     "Sunday": "11am - 2pm "
//   }
// ]
