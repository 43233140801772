import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import { history } from 'redux/store'
import ClinicServices from 'services/clinics'
import actions from './actions'

const services = new ClinicServices()

export function* GETCLINICS(action) {
  const response = yield doApiCall(services.getClinics, action.payload, actions.SET_STATE)
  console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { clinicsData: response.data } })
  }
}

export function* GETSINGLECLINIC(action) {
  const response = yield doApiCall(services.getSingleClinic, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleClinicData: response.data } })
  }
}

export function* UPDATE_CLINIC(action) {
  const response = yield doApiCall(services.updateClinic, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Clinic Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
    history.goBack(-1)
  }
}

export function* SAVE_CLINIC(action) {
  const response = yield doApiCall(services.saveClinic, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Clinic Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCategory: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_CLINICSB, GETCLINICS)])
  yield all([takeEvery(actions.GET_SINGLE_CLINICB, GETSINGLECLINIC)])
  yield all([takeEvery(actions.UPDATE_CLINICB, UPDATE_CLINIC)])
  yield all([takeEvery(actions.SAVE_CLINICB, SAVE_CLINIC)])
}
