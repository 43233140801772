import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import PartnerServices from 'services/partners'
import { history } from 'redux/store'
import actions from './actions'

const services = new PartnerServices()

export function* GETALLPARTNERS(action) {
  const response = yield doApiCall(services.getPartners, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { partnersData: response.data } })
  }
}

export function* GETSINGLEPARTNERS(action) {
  const response = yield doApiCall(services.getSinglePartner, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singlePartnerData: response.data } })
  }
}

export function* UPDATEPARTNERS(action) {
  const response = yield doApiCall(services.updatePartner, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Partner Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
    history.goBack(-1)
  }
}

export function* SAVEPARTNERS(action) {
  const response = yield doApiCall(services.savePartner, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Parner Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveData: response.data } })
    history.goBack(-1)
  }
}

export function* CREATEBRANCH(action) {
  const response = yield doApiCall(services.createBranch, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'A new Branch Created',
    })
    yield put({ type: actions.SET_STATE, payload: { branchSaveData: response.data } })
    history.goBack(-1)
  }
}

export function* UPDATEBRANCH(action) {
  const response = yield doApiCall(services.updateBranch, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Branch Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updateBranch: response.data } })
    history.goBack(-1)
  }
}

export function* GETBRANCH(action) {
  const response = yield doApiCall(services.getBranches, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { allBranches: response.data } })
  }
}

export function* GETSINGLEBRANCH(action) {
  const response = yield doApiCall(services.getSingleBranch, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleBranch: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALLPARTNERS, GETALLPARTNERS)])
  yield all([takeEvery(actions.GET_SINGLE_PARTNERS, GETSINGLEPARTNERS)])
  yield all([takeEvery(actions.UPDATE_PARTNER, UPDATEPARTNERS)])
  yield all([takeEvery(actions.SAVE_PARTNER, SAVEPARTNERS)])
  yield all([takeEvery(actions.CREATE_BRANCH, CREATEBRANCH)])
  yield all([takeEvery(actions.UPDATE_BRANCH, UPDATEBRANCH)])
  yield all([takeEvery(actions.GET_BRANCH, GETBRANCH)])
  yield all([takeEvery(actions.GET_SINGLEBRANCH, GETSINGLEBRANCH)])
}
