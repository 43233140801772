const actions = {
  SET_STATE: 'cities/SET_STATE',
  GET_ALLCITIES: 'cities/GET_ALLCITIES',
  GET_SINGLE_CITIES: 'cities/GET_SINGLE_CITIES',
  UPDATE_CITIES: 'cities/UPDATE_CITIES',
  SAVE_CITIES: 'cities/SAVE_CITIES',
  DELETE_CITIES: 'cities/DELETE_CITIES',
}

export default actions
