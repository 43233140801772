import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import VideosServices from 'services/videos'
import actions from './actions'

const services = new VideosServices()

export function* GETVIDEOS(action) {
  const response = yield doApiCall(services.getVideos, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { videosData: response.data } })
  }
}

export function* GETSINGLEVIDEOS(action) {
  if (action.payload.id) {
    const response = yield doApiCall(services.getSingleVideos, action.payload, actions.SET_STATE)
    if (response?.success) {
      yield put({ type: actions.SET_STATE, payload: { singleVideoData: response.data } })
    }
  }
}

export function* UPDATEVIDEOS(action) {
  const response = yield doApiCall(services.updateVideos, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedVideo: response.data } })
  }
}

export function* SAVEVIDEOS(action) {
  const response = yield doApiCall(services.saveVideos, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield put({ type: actions.SET_STATE, payload: { newVideo: response.data } })
  }
}

export function* DELETEVIDEOS(action) {
  const response = yield doApiCall(services.deleteVideos, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Deleted',
    })
    yield put({ type: actions.SET_STATE, payload: { delVideo: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_VIDEOS, GETVIDEOS)])
  yield all([takeEvery(actions.GET_SINGLEVIDEOS, GETSINGLEVIDEOS)])
  yield all([takeEvery(actions.UPDATE_VIDEOS, UPDATEVIDEOS)])
  yield all([takeEvery(actions.SAVE_VIDEOS, SAVEVIDEOS)])
  yield all([takeEvery(actions.DELETE_VIDEOS, DELETEVIDEOS)])
}
