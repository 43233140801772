const actions = {
  SET_STATE: 'tags/SET_STATE',
  GETALLTAGS: 'tags/GETALLTAGS',
  GET_SINGLE_TAG: 'tags/GET_SINGLE_TAG',
  UPDATE_TAG: 'tags/UPDATE_TAG',
  SAVE_TAG: 'tags/SAVE_TAG',
  DELETE_TAG: 'tags/DELETE_TAG',
}

export default actions
