import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import QuestionsServices from 'services/questions'
import actions from './actions'

const services = new QuestionsServices()

export function* GETSUPPORTG(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.getQuestions, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { questionsData: response.data } })
  }
}

export function* GETSINGLESUPPORTG(action) {
  const response = yield doApiCall(services.getSingleQuestions, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleQuestionData: response.data } })
  }
}

export function* UPDATESUPPORTG(action) {
  const response = yield doApiCall(services.updateQuestions, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedQuestionData: response.data } })
  }
}

export function* SAVESUPPORTG(action) {
  const response = yield doApiCall(services.saveQuestions, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield put({ type: actions.SET_STATE, payload: { newQuestionData: response.data } })
  }
}

export function* DELETESUPPORTG(action) {
  const response = yield doApiCall(services.deleteQuestions, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Deleted',
    })
    yield put({ type: actions.SET_STATE, payload: { newQuestionData: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_QUESTIONS, GETSUPPORTG)])
  yield all([takeEvery(actions.GET_SINGLEQUESTION, GETSINGLESUPPORTG)])
  yield all([takeEvery(actions.UPDATE_QUESTION, UPDATESUPPORTG)])
  yield all([takeEvery(actions.SAVE_QUESTION, SAVESUPPORTG)])
  yield all([takeEvery(actions.DELETE_QUESTION, DELETESUPPORTG)])
}
