/* eslint-disable no-unused-expressions */
// eslint-disable-next-line no-unused-vars
import axios from 'axios'

const { apiClient1, apiClient2, apiClient4, apiClient5 } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class DiagnosticOrdersServices {
  constructor() {
    this.client = apiClient1
    this.client2 = apiClient2
    this.client4 = apiClient4
    this.client1 = apiClient5
  }

  getDiagnosticOrders = (payload = {}) => {
    return this.client2
      .get(
        `${BACKEND_ROUTES.GET_DIAG_ORDERS.concat('?')
          .concat(payload.data)
          .concat('&populate=user')
          .concat('&filters[$or][0][orderItems][product][productType][$ne]=vaccine')
          .concat('&filters[$or][1][orderItems][cProduct][productType][$ne]=vaccine')
          .concat('&')
          .concat(payload?.con)}`,
        {
          // params: payload.data,
        },
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  // eslint-disable-next-line no-unused-vars
  getSingleDiagnosticOrders = (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_DIAG_ORDERS.concat('?').concat(payload.populate)}`, {
        // params: payload.data,
      })
      .then((res) => {
        // console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateDiagnosticOrders = (payload = {}) => {
    return this.client4
      .put(
        `${BACKEND_ROUTES.UPDATE_ORDERS.replace(':id', payload.id).concat(
          '?populate[0]=user&populate[1]=orderItems&populate[2]=orderItems.product&populate[3]=orderItems.cProduct&populate[4]=patientDetails&populate[5]=collectionLocation&populate[6]=collectionTime',
        )}`,
        { ...payload.data },
      )
      .then((res) => {
        // console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateDiagStatus = (payload = {}) => {
    return this.client4
      .put(`${BACKEND_ROUTES.UPDATE_ORDERS.replace(':id', payload.id)}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getUploadLinks = (payload = {}) => {
    console.log(payload.getlinkparams, ' getlink params at service')
    return this.client
      .get(`${BACKEND_ROUTES.UPLOAD_LINKSDG}`, { params: payload.getlinkparams })
      .then(async (res) => {
        const headers = {
          'Content-Type': `${payload.file.type}`,
        }
        await axios.put(res?.data?.data[0]?.url, payload.file, { headers }).then(async (res2) => {
          if (res2.status) {
            payload.patientDetails.map((element) => {
              if (element.id === payload.userId.id) {
                element.reportId = res.data.data[0].key
              }
              return true
            })
            const { meta } = payload
            meta?.verification?.reports?.map((element) => {
              if (element?.uuid === payload?.uuid) {
                element.reportId = res?.data?.data[0]?.key
                return true
              }
              return true
            })
            const data = {
              patientDetails: payload.patientDetails?.filter((ele) => ele.id !== ''),
              meta,
              eventName: 'pending',
            }
            // console.log(data)
            const res3 = await this.updateDiagnosticOrders({
              data,
              id: payload?.id,
            })
            if (res3) {
              window.location.reload()
            }
          }
        })
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateUser = (payload = {}) => {
    return this.client4
      .put(`${BACKEND_ROUTES.UPDATE_ORDERS.replace(':id', payload.id)}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateProfile = (payload = {}) => {
    return this.client
      .patch(
        `${BACKEND_ROUTES.CREATE_PROFILE.concat('/:id').replace(':id', payload.profileUUID)}`,
        { ...payload.data },
      )
      .then(async (res) => {
        // console.log(payload)
        const patientDetails = payload.patientDetails.filter(
          (element) => !(element.id === payload.profileId || element.id === ''),
        )
        // console.log(patientDetails, 'pd')
        payload.data.id = payload.profileId
        patientDetails.push(payload.data)
        const data = {
          patientDetails,
        }
        // console.log(data, res)
        await this.updateDiagnosticOrders({
          data,
          id: payload?.oId,
        })

        return res
      })

      .catch((error) => {
        throw error
      })
  }

  createProfile = (payload = {}) => {
    // return true
    return this.client
      .post(`${BACKEND_ROUTES.CREATE_PROFILE}`, { ...payload.data })
      .then(async (res) => {
        // console.log(res, payload)
        const { patientDetails } = payload
        let temp2 =
          patientDetails?.filter(
            (element) =>
              element.name === payload.data.name ||
              element.email === payload.data.email ||
              element.mobile === payload.data.mobile,
          ) || []
        if (temp2.length === 1) {
          const temp = {
            uuid: res?.data?.data?.id,
            reportId: null,
            name: payload.data.name,
            email: payload.data.email,
            gender: payload.data.gender,
            mobile: payload.data.mobile,
            relationship: payload.data.relationship,
            dob: payload.data.dob,
          }
          temp2 =
            patientDetails?.filter(
              (element) =>
                element.name !== payload.data.name ||
                element.email !== payload.data.email ||
                element.mobile !== payload.data.mobile,
            ) || []
          temp2.push(temp)
          payload.meta.eventName = 'patient_added'
          const data = {
            patientDetails: temp2,
            meta: payload.meta,
          }
          const res2 = await this.updateDiagnosticOrders({
            data,
            id: payload?.id,
          })
          if (res2) {
            window.location.reload()
          }
        } else {
          const temp = {
            uuid: res?.data?.data?.id,
            reportId: null,
            name: payload.data.name,
            email: payload.data.email,
            gender: payload.data.gender,
            mobile: payload.data.mobile,
            relationship: payload.data.relationship,
            dob: payload.data.dob,
          }
          payload.meta.eventName = 'patient_added'
          patientDetails.push(temp)
          const data = {
            patientDetails,
            meta: payload.meta,
          }
          const res2 = await this.updateDiagnosticOrders({
            data,
            id: payload?.id,
          })
          if (res2) {
            window.location.reload()
          }
        }
      })
      .catch((error) => {
        throw error
      })
  }

  updateMeta = (payload = {}) => {
    console.log(' at create profile service', payload)
    return this.client4
      .put(`${BACKEND_ROUTES.UPDATE_ORDERS.replace(':id', payload.id)}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  openReport = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.OPEN_REPORT}`, { ...payload.data })
      .then((res) => {
        window.open(res.data.data[0].signedUrl, '_blank')
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  openReportURL = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.OPEN_REPORT}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createCoupon = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.CREATE_COUPONS}`, { ...payload.data })
      .then(async (res) => {
        const temp = payload.meta
        temp.generatedCouponCode = res.data.data
        temp.eventName = 'free_coupon_code_generated'
        // window.open(res.data.data[0].signedUrl, '_blank')
        const data = {
          meta: temp,
        }
        const res2 = await this.updateDiagnosticOrders({
          data,
          id: payload?.id,
        })
        if (res2) {
          window.location.reload()
        }

        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getInvoice = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_INVOICES}/DIAGNOSTIC/${payload.id}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default DiagnosticOrdersServices
