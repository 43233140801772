import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import LabTestServices from 'services/labtests'
import { history } from 'redux/store'
import actions from './actions'

const services = new LabTestServices()

export function* GETLABTESTS(action) {
  const response = yield doApiCall(services.getLabtests, action.payload, actions.SET_STATE)
  console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { labTestsData: response.data } })
  }
}

export function* GETSINGLELABTEST(action) {
  const response = yield doApiCall(services.getSingleLabTest, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleLabData: response.data } })
  }
}

export function* UPDATELABTEST(action) {
  const response = yield doApiCall(services.updateTest, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Lab Test Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
    history.goBack(-1)
  }
}

export function* SAVELABTEST(action) {
  const response = yield doApiCall(services.saveTest, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Lab Test Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCategory: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_LABTESTS, GETLABTESTS)])
  yield all([takeEvery(actions.GET_SINGLE_LABTEST, GETSINGLELABTEST)])
  yield all([takeEvery(actions.UPDATE_LABTEST, UPDATELABTEST)])
  yield all([takeEvery(actions.SAVE_LABTEST, SAVELABTEST)])
}
