const actions = {
  SET_STATE: 'aoc/SET_STATE',
  GET_AOCS: 'aoc/GET_AOCS',
  GET_AOCS_DETAILS: 'aoc/GET_AOC_DETAILS',
  UPDATE_AOC_DATA: 'aoc/UPDATE_AOC_DATA',
  ADD_AOC_DATA: 'aoc/ADD_AOC_DATA',
  DELETE_AOC: 'aoc/DELETE_AOC',
  SET_CLINIC: 'aoc/SET_CLINIC',
}

export default actions
