/* eslint-disable no-unused-vars */
import axios from 'axios'
import { apiClient6 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'

class ClinicServices {
  constructor() {
    this.client = apiClient6
  }

  getClinics = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GETCLINICS}`, { params: payload })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleClinic = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GETSINGLECLINICS.replace('id', temp[1])}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateClinic = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client
      .put(`${BACKEND_ROUTES.GETSINGLECLINICS.replace('id', temp[1])}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveClinic = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GETCLINICS}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default ClinicServices
