import { doApiCall } from 'helper'
import { all, put, takeEvery } from 'redux-saga/effects'
import DiagnosticOrdersServices from 'services/dgOfflineBooking'
import actions from './actions'

const services = new DiagnosticOrdersServices()

export function* GET_DIAGNOSTIC_CLINICS(action) {
  const response = yield doApiCall(services.getClinics, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { clinic: response } })
  }
}

export function* GET_PRODUCTS_BY_CLINIC(action) {
  const response = yield doApiCall(services.getClinicProduct, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { product: response } })
  }
}
export function* CREATE_DG_BOOKING(action) {
  const response = yield doApiCall(services.createDgBooking, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { createdBooking: response } })
  }
}

export function* GET_TEMP_TRANSACTIONS(action) {
  const response = yield doApiCall(services.getTempTransaction, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { tempTransaction: response } })
  }
}

export function* GET_TEMP_TRANSACTIONS_BY_ID(action) {
  const response = yield doApiCall(
    services.getTempTransactionById,
    action.payload,
    actions.SET_STATE,
  )
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { singleTempTransaction: response.data } })
  }
}

export function* GET_ORDERS(action) {
  const response = yield doApiCall(services.getOrder, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { order: response } })
  }
}

export function* SHOW_QR_LINK(action) {
  const response = yield doApiCall(services.getQRLink, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { qrLink: response } })
  }
}

export function* RESEND_LINK(action) {
  const response = yield doApiCall(services.resendPaymentLink, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { paymentLink: response } })
  }
}

export function* VERIFY_PAYMENT(action) {
  const response = yield doApiCall(services.verifyPayment, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { paymentLink: response } })
  }
}

export function* GET_CUSTOM_PRODUCT(action) {
  const response = yield doApiCall(services.getCustomProduct, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { customProduct: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_CLINICS, GET_DIAGNOSTIC_CLINICS)])
  yield all([takeEvery(actions.GET_PRODUCTS_BY_CLINIC, GET_PRODUCTS_BY_CLINIC)])
  yield all([takeEvery(actions.CREATE_DG_BOOKING, CREATE_DG_BOOKING)])
  yield all([takeEvery(actions.GET_TEMP_TRANSACTIONS, GET_TEMP_TRANSACTIONS)])
  yield all([takeEvery(actions.GET_TEMP_TRANSACTIONS_BY_ID, GET_TEMP_TRANSACTIONS_BY_ID)])
  yield all([takeEvery(actions.GET_ORDERS, GET_ORDERS)])
  yield all([takeEvery(actions.SHOW_QR_LINK, SHOW_QR_LINK)])
  yield all([takeEvery(actions.RESEND_LINK, RESEND_LINK)])
  yield all([takeEvery(actions.VERIFY_PAYMENT, VERIFY_PAYMENT)])
  yield all([takeEvery(actions.GET_CUSTOM_PRODUCT, GET_CUSTOM_PRODUCT)])
}
