const actions = {
  SET_STATE: 'categories/SET_STATE',
  GET_CATEGORIES: 'categories/GET_CATEGORIES',
  SAVE_CATEGORY: 'categories/SAVE_CATEGORY',
  GET_SINGLE_CATEGORY: 'categories/GET_SINGLE_CATEGORY',
  UPDATE_CATEGORY: 'categories/UPDATE_CATEGORY',
  DELETE_CATEGORY: 'categories/DELETE_CATEGORY',
}

export default actions
