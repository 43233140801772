import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import DiagnosticOrdersServices from 'services/diagnosticOrders'
import actions from './actions'

const services = new DiagnosticOrdersServices()

export function* GETDIAGNOSTICORDERS(action) {
  // yield console.log('dispatch heard', action.payload)
  const response = yield doApiCall(services.getDiagnosticOrders, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { diagnosticOrders: response } })
  }
}

export function* GETSINGLEDIAGNOSTICORDERS(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(
    services.getSingleDiagnosticOrders,
    action.payload,
    actions.SET_STATE,
  )
  if (response) {
    response.data.check =
      response.data[0]?.patientDetails.length === response.data[0]?.totalQuantity
    response.data.rcheck =
      response.data[0]?.patientDetails.filter((element) => element?.reportId?.length > 0)
        ?.length === response.data[0]?.totalQuantity
    response.data.hasDoc =
      response.data[0]?.orderItems[0]?.product?.hasFreeConsult ||
      response.data[0]?.orderItems[0]?.cProduct?.hasFreeConsult
    response.data.setDoc =
      response.data[0]?.patientDetails.filter((element) => element.drId?.length > 0)?.length ===
      response.data[0]?.totalQuantity
    response.data.reportLength = response.data[0]?.patientDetails.filter(
      (element) => element?.reportId?.length > 0,
    )?.length
    response.data.uuidcheck = response.data[0]?.patientDetails.filter(
      (element) => element.uuid,
    )?.length
    yield put({ type: actions.SET_STATE, payload: { singleDiagnosticOrders: response.data } })
  }
}

export function* UPDATEDIAGNOSTICORDERS(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(
    services.updateDiagnosticOrders,
    action.payload,
    actions.SET_STATE,
  )
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { updatedDiagnosticOrders: response } })
    yield window.location.reload()
  }
}

export function* UPDATEDIAGNOSTICORDERSNORELOAD(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(
    services.updateDiagnosticOrders,
    action.payload,
    actions.SET_STATE,
  )
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { updatedDiagnosticOrders: response } })
  }
}

export function* UPDATEDIAGNOSTICSTATUS(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.updateDiagStatus, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { updatedDiagnosticStatus: response.data } })
  }
}

export function* GETLINKS(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.getUploadLinks, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { uploadLinks: response } })
    // yield window.location.reload()
  }
}

export function* UPDATEUSER(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.updateUser, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { uploadLinks: response } })
  }
}

export function* UPDATEPATIENTPROFILES(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.updatePDProfile, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { uploadLinks: response } })
  }
}

export function* PROFILEUPDATE(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.updateProfile, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { uploadLinks: response } })
  }
}

export function* CREATEPROFILE(action) {
  const response = yield doApiCall(services.createProfile, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { uploadLinks: response } })
    // window.location.reload()
  }
}

export function* UPDATEMETA(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.updateMeta, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { updateMeta: response } })
  }
}

export function* GETREPORT(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.openReport, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { reportData: response } })
  }
}

export function* GETREPORTURL(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.openReportURL, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { reportURLData: response } })
  }
}

export function* CREATECOUPONS(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.createCoupon, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { couponData: response } })
  }
}
export function* GET_INVOICE(action) {
  const response = yield doApiCall(services.getInvoice, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { invoice: response } })
  }
}
export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DIAGNOSTIC_ORDERS, GETDIAGNOSTICORDERS)])
  yield all([takeEvery(actions.GET_SINGLE_DIAGNOSTIC_ORDERS, GETSINGLEDIAGNOSTICORDERS)])
  yield all([takeEvery(actions.UPDATE_DIAGNOSTIC_ORDERS, UPDATEDIAGNOSTICORDERS)])
  yield all([takeEvery(actions.UPDATE_STATUS, UPDATEDIAGNOSTICSTATUS)])
  yield all([takeEvery(actions.GET_LINKS, GETLINKS)])
  yield all([takeEvery(actions.UPDATE_USER, UPDATEUSER)])
  yield all([takeEvery(actions.UPDATE_PATIENT_PROFILES, UPDATEPATIENTPROFILES)])
  yield all([takeEvery(actions.PROFILE_UPDATE, PROFILEUPDATE)])
  yield all([takeEvery(actions.CREATE_DGPROFILE, CREATEPROFILE)])
  yield all([takeEvery(actions.UPDATE_META, UPDATEMETA)])
  yield all([takeEvery(actions.GET_REPORT, GETREPORT)])
  yield all([takeEvery(actions.GET_REPORTURL, GETREPORTURL)])
  yield all([takeEvery(actions.CREATE_COUPONS, CREATECOUPONS)])
  yield all([takeEvery(actions.UPDATE_DIAGNOSTIC_ORDERS_NORELOAD, UPDATEDIAGNOSTICORDERSNORELOAD)])
  yield all([takeEvery(actions.INVOICE, GET_INVOICE)])
}
