/* eslint-disable no-unused-vars */
import { apiClient, apiClient1, apiClient2, apiClient3 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'
// import { store } from 'redux/store'

class CouponCodesServices {
  constructor() {
    this.client = apiClient
    this.client1 = apiClient1
    this.client2 = apiClient2
    this.client3 = apiClient3
  }

  getCouponCodes = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_COUPON_CODES}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getCouponCode = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_COUPON_CODES}/${payload.id}`, {
        params: payload.params,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  // UPDATE_COUPON_CODE
  updateCouponCode = (payload = {}) => {
    return this.client1
      .patch(`${BACKEND_ROUTES.GET_COUPON_CODES}/${payload.id}`, payload.body)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateCouponCodeBulk = (payload = {}) => {
    return this.client1
      .patch(`${BACKEND_ROUTES.UPDATE_COUPON_CODES_BULK}`, payload)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateCouponCodeConstriantBulk = (payload = {}) => {
    return this.client1
      .patch(
        `${BACKEND_ROUTES.UPDATE_COUPON_CONSTRAINT_BULK.replace(':name', payload.name)}`,
        payload.body,
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateCouponCodeConstriantBulk = (payload = {}) => {
    return this.client1
      .post(
        `${BACKEND_ROUTES.UPDATE_COUPON_CONSTRAINT_BULK.replace(':name', payload.name)}`,
        payload.body,
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addCouponCode = (payload = {}) => {
    if (payload.data.products.length > 0) {
      return this.client1
        .post(`${BACKEND_ROUTES.GET_COUPON_CODES}`, payload.body)
        .then((res) => {
          payload.data.couponId = res.data.data.id

          if (payload.data.products.length > 0) {
            this.client3
              .post(`${BACKEND_ROUTES.UPDATE_COUPON_CONSTRAINT_BULK.replace(':name', 'product')}`, {
                ...payload.data,
              })
              // axios.patch('https://api.v2.dev-stage.proactiveforher.com/v2/coupons-constraints/product/bulk', ...payload.data)
              .then((res2) => {
                return res2
              })
              .catch((error) => {
                throw error
              })
          }
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    delete payload.body.serviceType
    delete payload.body.productAction
    return this.client1
      .post(`${BACKEND_ROUTES.GET_COUPON_CODES}`, payload.body)
      .then((res) => {
        payload.data.couponId = res.data.data.id

        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getCouponConstraint = (payload = {}) => {
    return this.client1
      .get(BACKEND_ROUTES.GET_COUPON_CODES_CONSTRAINT.replace(':name', payload.id), {
        params: payload.params,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getDoctor = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.concat('/doctorDetails/filter')}}`, {
        params: payload.body,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSpecialization = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_SPECIALIZATION}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addCouponConstraint = (payload = {}) => {
    return this.client1
      .post(
        `${BACKEND_ROUTES.ADD_COUPON_CODES_CONSTRAINT.replace(':name', payload.name)}`,
        payload.body,
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateCouponConstraint = (payload = {}) => {
    return this.client1
      .patch(
        `${BACKEND_ROUTES.UPDATE_COUPON_CODES_CONSTRAINT.replace(':name', payload.name).replace(
          ':id',
          payload.id,
        )}`,
        payload.body,
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteCouponConstraint = (payload = {}) => {
    return this.client1
      .delete(
        `${BACKEND_ROUTES.UPDATE_COUPON_CODES_CONSTRAINT.replace(':name', payload.name).replace(
          ':id',
          payload.id,
        )}`,
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteCouponConstraintBulk = (payload = {}) => {
    return this.client1
      .delete(
        `${BACKEND_ROUTES.UPDATE_COUPON_CODES_CONSTRAINT.replace(':name', payload.name).replace(
          ':id',
          'bulk',
        )}`,
        { data: payload.data },
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getAllProducts = (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_DIAGNOSTIC_PRODUCTS.concat('?pagination[pageSize]=100')}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getCustomProducts = (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_DIAGNOSTIC_CUSTOMPRODUCTS.concat('?pagination[pageSize]=100')}`, {
        // params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getCouponProdConst = (payload = {}) => {
    return this.client3
      .get(`${BACKEND_ROUTES.GET_COUPON_PROD_CONST.replace(':cid', payload.id)}`, {
        // params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addDiagProds = (payload = {}) => {
    return (
      this.client3
        .post(`${BACKEND_ROUTES.UPDATE_COUPON_CONSTRAINT_BULK.replace(':name', 'product')}`, {
          ...payload.data,
        })
        // axios.patch('https://api.v2.dev-stage.proactiveforher.com/v2/coupons-constraints/product/bulk', ...payload.data)
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    )
  }

  createDiagCoup = (payload = {}) => {
    return this.client3
      .post(`${BACKEND_ROUTES.UPDATE_COUPON_CONSTRAINT_BULK.replace(':name', 'product')}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  // UPDATE_DIAGNOSTIC_PRODUCT
  updateDiagProds = (payload = {}) => {
    return this.client3
      .post(`${BACKEND_ROUTES.UPDATE_COUPON_CONSTRAINT_BULK.replace(':name', 'product')}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getConstraints = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.NEW_CONSTRAINTS.replace(':id', payload.id)}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateBulkDocs = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.NEW_BULK}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateBulkUsers = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.NEW_BULK.replace('doctor', 'user')}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateBulkDocUsers = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.NEW_BULK.replace('doctor', 'user-doctor')}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateBulkSpecUsers = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.NEW_BULK.replace('doctor', 'user-specialization')}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteBulkConstarints = (payload = {}) => {
    return this.client1
      .delete(BACKEND_ROUTES.DELETE_BULK, {
        data: {
          ...payload,
        },
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateUserSpecializationBulkConstarints = (payload = {}) => {
    return this.client1
      .patch(BACKEND_ROUTES.UPDATE_USER_SPECIALIZATION_BULK, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateUserDoctorBulkConstarints = (payload = {}) => {
    return this.client1
      .patch(BACKEND_ROUTES.UPDATE_DOCTOR_SPECIALIZATION_BULK, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  verifyCoupon = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.VERIFY_COUPON.replace(':code', payload.code)}`, {
        params: payload.couponDetails,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}
export default CouponCodesServices
