import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'helper'
import UsersServices from 'services/users'
import actions from './actions'
// import { notification } from 'antd';

const services = new UsersServices()
export function* WATCH_GET_USERS(action) {
  const response = yield doApiCall(services.getUsers, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { usersData: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_USERS, WATCH_GET_USERS)])
}
