import { apiClient1 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'

class BannerService {
  constructor() {
    this.client2 = apiClient1
  }

  // eslint-disable-next-line no-unused-vars
  getBanners = async (payload = {}) => {
    // console.log(payload)
    if (!payload.id) {
      return this.client2
        .get(`${BACKEND_ROUTES.GET_BANNERS}`, { params: payload })
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    return true
  }

  getSingleBanner = async (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_BANNERS.concat('/').concat(payload.id)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateBanner = async (payload = {}) => {
    return this.client2
      .patch(`${BACKEND_ROUTES.GET_BANNERS.concat('/').concat(payload.id)}`, {
        ...payload.submitdata,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveBanner = async (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.GET_BANNERS}`, { ...payload?.submitdata })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default BannerService
