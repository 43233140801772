import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import SampleTypeService from 'services/sampleType'
import { history } from 'redux/store'
import actions from './actions'

const services = new SampleTypeService()

export function* GETSAMPLETYPES(action) {
  console.log('at sagas')
  const response = yield doApiCall(services.getSamples, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { sampleTypeData: response.data } })
  }
}

export function* GETSINGLETYPE(action) {
  const response = yield doApiCall(services.getSingleSamples, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { getSingleSampleType: response.data } })
  }
}

export function* SAVESAMPLETYPE(action) {
  const response = yield doApiCall(services.createSamples, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    notification.success({
      message: 'Sample Created Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { createdSampleType: response.data } })
    history.goBack(-1)
  }
}

export function* UPDATESAMPLETYPE(action) {
  const response = yield doApiCall(services.updateSample, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    notification.success({
      message: 'Sample Updated Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedSample: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_SAMPLETYPES, GETSAMPLETYPES)])
  yield all([takeEvery(actions.SAVE_SAMPLETYPE, SAVESAMPLETYPE)])
  yield all([takeEvery(actions.GET_SINGLETYPE, GETSINGLETYPE)])
  yield all([takeEvery(actions.UPDATE_SAMPLETYPE, UPDATESAMPLETYPE)])
}
