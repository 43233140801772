import { apiClient } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'
// import { store } from 'redux/store'

class AocServices {
  constructor() {
    this.client = apiClient
  }

  getAocs = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_AOC}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getAoc = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_AOC}/${payload.id}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateAoc = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.GET_AOC}/${payload.id}`, payload.body)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addAoc = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_AOC}`, payload.body)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteAoc = (payload = {}) => {
    return this.client
      .delete(`${BACKEND_ROUTES.GET_AOC}/${payload.id}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  setClinic = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.GET_AOC}/${payload.id.concat('/is-clinic')}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}
export default AocServices
