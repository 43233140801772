import { all, put, call } from 'redux-saga/effects'
import getMenuData from 'services/menu'
// eslint-disable-next-line import/no-cycle
// import { store } from "../store"

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  // const user1 = store.getState().user
  const check = JSON.parse(localStorage.getItem('persist:authType'))
  const { user } = check
  const out = JSON.parse(user)
  const temp = []
  menuData?.map((element) => {
    if (element?.roles?.includes(out?.role)) {
      temp.push(element)
    }
    return true
  })
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
