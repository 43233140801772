import React, { Fragment } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'

import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ settings }) => ({ title: settings.logo })
let previousPath = ''

const Layout = ({ children, title, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }
  const Container = Layouts[getLayout()]
  const isUserAuthorized = true
  const isAuthLayout = getLayout() === 'auth'
  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (!isUserAuthorized && !isAuthLayout) {
      return null
    }
    // // console.log(pathname)
    // redirect to login page if current is not login page and user not authorized
    if (isUserAuthorized === false) {
      return (
        <>
          <Container>{children}</Container>
          <Redirect to="/auth/login" />
        </>
      )
      // if (!isAuthLayout && !isUserAuthorized) {
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate={`${title} | %s`} title={title} />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
