const actions = {
  SET_STATE: 'appointments/SET_STATE',
  GET_APPOINTMENTS: 'appointments/GET_APPOINTMENTS',
  GET_APPOINTMENTS_DETAILS: 'appointments/GET_APPOINTMENTS_DETAILS',
  RESCHEDULE_APPOINTMENTS: 'appointments/RESCHEDULE_APPOINTMENTS',
  SLOT_DATE_CHECK: 'appointments/SLOT_DATE_CHECK',
  SLOT_AVAILABILITY: 'appointments/SLOT_AVAILABILITY',
  SLOT_AVAILABILITY_FOLLOWUP: 'appointments/SLOT_AVAILABILITY_FOLLOWUP',
  INACTIVE_APPOINTMENT: 'appointments/INACTIVE_APPOINTMENT',
  GET_REPORT: 'appointments/GET_REPORT',
  INITIATE_REFUND: 'appointments/INITIATE_REFUND',
  REFRESH_PAYMENTS: 'appointments/REFRESH_PAYMENTS',
  GET_ALL_APOINTMENTS: 'appointments/GET_ALL_APPOINTMENTS',
  GET_PRESCRIPTION: 'appointments/GET_PRESCRIPTION',
  UPDATE_CONSULT_STATUS: 'appointments/UPDATE_CONSULT_STATUS',
  UPDATE_CONSULT_TAG: 'appointments/UPDATE_CONSULT_TAG',
  NOTIFY_USER: 'appointments/NOTIFY_USER',
  GET_APPOINTMENTSBYUUID: 'appointments/GET_APPOINTMENTSBYUUID',
  UPLOAD_PRESCRIPTION: 'appointments/UPLOAD_PRESCRIPTION',
  GET_INVOICE: 'appointments/GET_INVOICE',
  RESCHEDULE_GENERAL_APPOINT: 'appointments/RESCHEDULE_GENERAL_APPOINT',
}

export default actions
