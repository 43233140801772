import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import CityServices from 'services/cities'
import { history } from 'redux/store'
import actions from './actions'

const services = new CityServices()

export function* GETALLCITIES(action) {
  const response = yield doApiCall(services.getCities, action.payload, actions.SET_STATE)
  if (response?.success) {
    // console.log(response?.data)
    yield put({ type: actions.SET_STATE, payload: { cities: response.data } })
  }
}

export function* GETSINGLECITIES(action) {
  const response = yield doApiCall(services.getSingleCity, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleCity: response.data } })
  }
}

export function* UPDATECITIES(action) {
  const response = yield doApiCall(services.updateCity, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Order Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updateCity: response.data } })
  }
  history.goBack(-1)
}

export function* SAVECITIES(action) {
  const response = yield doApiCall(services.saveCity, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Order Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCity: response.data } })
    history.goBack(-1)
  }
}

export function* DELETECPORDERS(action) {
  const response = yield doApiCall(services.deleteTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Tag Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCpOrder: response.data } })
  }
  history.goBack(-1)
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALLCITIES, GETALLCITIES)])
  yield all([takeEvery(actions.GET_SINGLE_CITIES, GETSINGLECITIES)])
  yield all([takeEvery(actions.UPDATE_CITIES, UPDATECITIES)])
  yield all([takeEvery(actions.SAVE_CITIES, SAVECITIES)])
}
