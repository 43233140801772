const { apiClient6 } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class CpOfflineBookingService {
  constructor() {
    this.client = apiClient6
  }

  createCpBooking = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.OFFLINE_CP_BOOKING}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getTempTransaction = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_CP_TEMP_TRANSACTIONS}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getTempTransactionById = (payload = '') => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_CP_TEMP_TRANSACTIONS_BY_ID.replace(':id', payload.id)}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getQRLink = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.CP_QR_LINK.replace(':id', payload.id)}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  resendPaymentLink = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.CP_RESEND_PAYMENT_LINK.replace(':id', payload.id)}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  verifyPayment = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.CP_VERIFY_PAYMENT.replace(':id', payload.id)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default CpOfflineBookingService
