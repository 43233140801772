import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import ClinicsServices from 'services/clinic'
import actions from './actions'

const services = new ClinicsServices()

export function* GETCLINIC(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.getClinic, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { clinicData: response.data } })
  }
}

export function* GETSINGLECLINIC(action) {
  const response = yield doApiCall(services.getSingleClinicss, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleClinic: response.data } })
  }
}

export function* UPDATECLINIC(action) {
  const response = yield doApiCall(services.updateClinics, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedCompany: response.data } })
  }
}

export function* SAVECLINIC(action) {
  const response = yield doApiCall(services.saveClinics, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield put({ type: actions.SET_STATE, payload: { newCompany: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_CLINIC, GETCLINIC)])
  yield all([takeEvery(actions.GET_SINGLECLINIC, GETSINGLECLINIC)])
  yield all([takeEvery(actions.UPDATE_CLINIC, UPDATECLINIC)])
  yield all([takeEvery(actions.SAVE_CLINIC, SAVECLINIC)])
}
