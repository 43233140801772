/* eslint-disable consistent-return */
import { apiClient, apiClient1 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'

// import { store } from 'redux/store'

class PhysiciansServices {
  constructor() {
    this.client = apiClient
    this.client1 = apiClient1
  }

  getPhysicians = (payload = {}) => {
    // const { user } = store.getState().auth
    return this.client
      .get(`${BACKEND_ROUTES.GET_PHYSICIANS}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  filterPhysicians = (payload = {}) => {
    // const { user } = store.getState().auth
    return this.client1
      .get(`${BACKEND_ROUTES.FILTER_PHYSICIANS}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getUrl = (id) => {
    return this.client
      .get(`${BACKEND_ROUTES.editorImage(id)}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getPhysician = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_PHYSICIANS_DETAILS.replace(':id', payload.id)}`, {
        params: {
          include: payload.include,
        },
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSpecialization = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_SPECIALIZATION}`, {
        params: {
          ...payload,
        },
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getClinicdetails = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_CLINIC_DETAILS}`, {
        params: {
          ...payload,
        },
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getMultipleSlots = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_PHYSICIANS_SLOT.replace(':id', payload.id)}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getPhysicianClinicsDetailsV2 = (payload = {}) => {
    const { id, ...params } = payload
    return this.client1
      .get(`${BACKEND_ROUTES.GET_PHYSICIANS_DETAILS_V2.replace(':id', id)}`, {
        params,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  // eslint-disable-next-line no-unused-vars
  getUnavailabilitySlots = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_UNAVAILABILITY.replace('doctor', `general`)}`, {
        // ...payload.data
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updatePhysicianData = (payload = {}) => {
    // console.log(payload)
    return this.client
      .patch(`${BACKEND_ROUTES.UPDATE_PHYSICIAN_DATA.replace(':id', payload.id)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addPhysicianData = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.ADD_PHYSICIAN_DATA}`, payload.data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addSlots = (payload = {}) => {
    // console.log(payload)
    return this.client
      .post(`${BACKEND_ROUTES.ADD_SLOTS}`, { ...payload })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addUnavailabilitySlots = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.GET_UNAVAILABILITY.replace('doctor', 'general')}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  patchFollowUp = (payload = {}) => {
    return this.client1
      .patch(`${BACKEND_ROUTES.PATCH_FOLLOWUP.replace(':id', payload.id)}`, payload.data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  uploadImageService = async (
    payload = {},
    // maxWidth = 1920,
    // maxHeight = 1080,
    // quality = 100,
    // compressFormat = 'WEBP',
  ) => {
    // file = await resizeFile(file, maxWidth, maxHeight, compressFormat, quality);
    const { file } = payload
    const { name, type } = file
    const data = {
      fileName: name.split('.')[0],
      mimeType: type,
      objType: 'images',
      sourceDisk: 'media',
    }
    const formData = new FormData()
    formData.append('file', file)
    return this.client
      .post(BACKEND_ROUTES.uploads, data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw err
      })
  }

  getAllPools = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_POOLS.replace(':id', payload.id)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getAllDrDetails = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_ALLDRDETAILS}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createPool = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.CREATE_POOLS}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getPoolSlots = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_POOLSLOTS.replace(':id', payload?.id)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addSlotToPool = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.GET_POOLSLOTS.split('/:id')[0]}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getPoolByDrId = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.AVAILABILITYPOOL_BYDOCTOR.concat(`/${payload.id}`)}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  linkAvailabilityPoolid = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.LINK_DOCTORAVAILABILITYPOOLWITHDRSERVICE}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteLinkedPoolId = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.LINK_DOCTORAVAILABILITYPOOLWITHDRSERVICE.concat('/delete')}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteSlot = (payload = {}) => {
    return this.client1
      .delete(`${BACKEND_ROUTES.GET_POOLSLOTS.replace(':id', payload?.id)}`, {
        // ...payload.data
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getUnavailability = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_UNAVAILABILITY.concat(`/${payload?.id}`)}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addUnavailability = (payload = {}) => {
    return this.client1
      .post(`${BACKEND_ROUTES.GET_UNAVAILABILITY}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  patchUnavailability = (payload = {}) => {
    return this.client1
      .patch(`${BACKEND_ROUTES.GET_UNAVAILABILITY.replace('doctor', `${payload.id}`)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteUnavailability = (payload = {}) => {
    return this.client1
      .delete(`${BACKEND_ROUTES.GET_UNAVAILABILITY.replace('doctor', `${payload.id}`)}`, {
        // ...payload.data
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}
export default PhysiciansServices
