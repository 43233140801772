const actions = {
  SET_STATE: 'bundles/SET_STATE',
  GET_BUNDLES: 'bundles/GET_CLINICS',
  GET_SINGLE_BUNDLE: 'bundles/GET_SINGLE_CLINIC',
  UPDATE_BUNDLE: 'bundles/UPDATE_CLINIC',
  SAVE_BUNDLE: 'bundles/SAVE_CLINIC',
  SET_PRODUCT: 'bundles/SET_PRODUCT',
  GET_BUNDLEPRODUCTS: 'bundles/GET_BUNDLEPRODUCTS',
  DELETE_BUNDLEPROD: 'bundles/DELETE_BUNDLEPROD',
  GET_BUNDLEPRODUCTS_BYID: 'bundles/GET_BUNDLEPRODUCTS_BYID',
  UPDATE_BUNDLEPROD: 'bundles/UPDATE_BUNDLEPROD',
  BUNDLE_CAT: 'bundles/BUNDLE_CAT',
  UPDATE_BUNDLE_PROD_REDEEM_RANK: 'bundles/UPDATE_BUNDLE_PROD_REDEEM_RANK',
  SYNC_PINCODES: 'bundles/SYNC_PINCODES',
}

export default actions
