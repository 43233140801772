import { apiClient6 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'

class CategoryServices {
  constructor() {
    this.client2 = apiClient6
  }

  // eslint-disable-next-line no-unused-vars
  getCategories = async (payload = {}) => {
    if (!payload.id) {
      return this.client2
        .get(`${BACKEND_ROUTES.GET_CATEGORIES}`, { params: payload })
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    return true
  }

  getSingleCategory = async (payload = {}) => {
    const temp = payload.id
    return this.client2
      .get(`${BACKEND_ROUTES.GET_CATEGORIES.concat('/').concat(temp)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateCategory = async (payload = {}) => {
    if (payload.id && payload.data) {
      const temp = payload.id
      return this.client2
        .put(`${BACKEND_ROUTES.GET_CATEGORIES.concat(`/${temp}`)}`, payload.data)
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    return true
  }

  saveCategory = async (payload = {}) => {
    if (!payload.id) {
      return this.client2
        .post(`${BACKEND_ROUTES.GET_CATEGORIES}`, payload.data)
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    return true
  }

  // getSingleCategory = async (payload = {}) => {
  //   // return this.client
  //   return this.client
  //     .get(`${BACKEND_ROUTES.GET_CATEGORIES}`, {
  //       params: payload.id,
  //     })
  //     .then((res) => {
  //       return res
  //     })
  //     .catch((error) => {
  //       throw error
  //     })
  // }
}

export default CategoryServices
