import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import { history } from 'redux/store'
import ProductsServices from 'services/products'
import actions from './actions'

const services = new ProductsServices()

export function* GET_PRODUCTS(action) {
  const response = yield doApiCall(services.getProducts, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { productsData: response.data } })
  }
}

export function* GETSINGLEPRODUCTS(action) {
  const response = yield doApiCall(services.getSingleProducts, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleProductData: response.data } })
  }
}

export function* UPDATEPRODUCTS(action) {
  yield console.log(action.payload, 'dispatch heard')
  const response = yield doApiCall(services.updateProducts, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Updated Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { updateProduct: response.data } })
    // yield put({ type: actions.SET_STATE, payload: { loading: false }})
    history.goBack(-1)
  }
}

export function* SAVEPRODUCTS(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.saveProducts, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Product Created Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { saveData: response.data } })
    // yield put({ type: actions.SET_STATE, payload: { loading: false }})
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GETALLPRODUCTS, GET_PRODUCTS)])
  yield all([takeEvery(actions.GET_SINGLE_PRODUCTS, GETSINGLEPRODUCTS)])
  yield all([takeEvery(actions.UPDATE_PRODUCTS, UPDATEPRODUCTS)])
  yield all([takeEvery(actions.SAVE_PRODUCTS, SAVEPRODUCTS)])
}
