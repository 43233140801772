const actions = {
  SET_STATE: 'doc_services/SET_STATE',
  GET_DOCSERVICES: 'doc_services/GET_DOCSERVICES',
  SAVE_DOCSERVICE: 'doc_services/SAVE_DOCSERVICE',
  GETSINGLE_DOCSERVICE: 'doc_services/GETSINGLE_DOCSERVICE',
  UPDATE_DOCSERVICE: 'doc_services/UPDATE_DOCSERVICE',
  DELETE_DOCSERVICE: 'faws/DELETE_DOCSERVICE',
  DELETE_LINKED_AVAILABILITYPOOLID: 'doc_services/DELETE_LINKED_AVAILABILITYPOOLID',
  CHECK_DOCSERVICE: 'doc_services/CHECK_DOCSERVICE',
}

export default actions
