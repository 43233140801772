// eslint-disable-next-line import/prefer-default-export
export const BACKEND_ROUTES = {
  LOGIN: 'auth/login',
  userProfile: '/customers/profile',
  GET_APPOINTMENTS: '/appointments',
  GET_APPOINTMENTS_DETAILS: '/appointments',
  GET_COUPON_CODES: '/admin/coupons',
  UPDATE_COUPON_CONSTRAINT_BULK: '/coupons-constraints/:name/bulk',
  GET_USERS: '/users',
  SLOT_DATE_CHECK: '/slots/avalabilty',
  SLOT_AVAILABILITY_TIME: '/slots',
  INACTIVE_APPOINTMENTS: '/admin/appointment/cancel',
  RESCHEDULE_APPOINTMENTS: '/appointments',
  GET_PHYSICIANS: '/users',
  FILTER_PHYSICIANS: '/doctorDetails/search',
  GET_PHYSICIANS_DETAILS: '/users/:id',
  GET_SPECIALIZATION: '/specializations',
  GET_CLINIC_DETAILS: '/clinics',
  GET_REPORT: '/reports/:resource',
  INITIATE_REFUND: '/orders/initiateRefund/:id',
  GET_PHYSICIANS_SLOT: '/slots/multiple/:id',
  GET_PHYSICIANS_UNAVAILABILITY_SLOTS: '/slots/unavailability',
  GET_PHYSICIANS_DETAILS_V2: '/doctorDetails/public/v2/:id',
  uploads: '/uploads',
  UPDATE_PHYSICIAN_DATA: '/users/:id',
  ADD_PHYSICIAN_DATA: '/users',
  ADD_SLOTS: '/slots/multiple',
  ADD_UNAVAILABILITY_SLOTS: '/slots/unavailability',
  GET_BLOGS: '/blogs',
  GET_COMPANIES: '/companies',
  GET_AOC: '/aocs',
  GET_TOPICS: '/topics',
  GET_TESTS: '/admin/recommendations-and-referrals',
  ADD_COUPON_CODES_CONSTRAINT: '/coupons-constraints/:name',
  GET_COUPON_CODES_CONSTRAINT: '/admin/coupons-constraints/:name',
  UPDATE_COUPON_CODES_CONSTRAINT: '/coupons-constraints/:name/:id',
  GET_DOCTORS: '/users',
  UPDATE_COUPON_CODES_BULK: '/admin/coupons/update-all',
  GET_SPECIALIZATIONS: '/specializations?&page=id',
  EDIT_SPECIALIZATION: '/specializations/:id',
  UPDATE_SPECIALIZATION: '/specializations/:id',
  SAVE_SPECIALIZATION: '/specializations',
  GET_PAYMENTS: '/payments/checkout-logs?limit=10&page=id',
  GET_PROGRAMS: '/payments/checkout-logs?&page=id',
  REFRESH_PAYMENTS: '/orders/refreshPayments/:id',
  GET_PRESCRIPTION: 'admin/prescriptions',
  UPDATE_CONSULT_STATUS: '/admin/appointment/:id',
  GET_SINGLE_CATEGOREIS: '/categories/id',
  GET_CATEGORIES: '/admin/categories',
  GETSERVICES: '/admin/services',
  GETSINGLESERVICES: '/admin/services/id',
  GETCLINICS: '/admin/clinics',
  GETSINGLECLINICS: '/admin/clinics/id',
  GETLABTESTS: '/admin/lab-tests',
  GETSINGLELABTESTS: '/v2/admin/lab-tests/:id',
  GETLABTESTPROFILES: '/admin/lab-tests/profiles',
  GETSINGLEPROFILE: '/v2/admin/lab-tests/profiles/:id?include=tests',
  GET_TAGS: 'admin/tags',
  UPDATEBANNER: '/admin/banners/id',
  GET_WEBINARS: '/webinars',
  GET_SUPPORTGROUPS: '/support-groups',
  GET_QUESTIONS: '/questions',
  GET_CUSTOMERS: '/customers',
  LOGIN_USER: 'auth/customers/login',
  SIGNUP_USER: '/users/signup',
  GET_VIDEOS: '/videos',
  GET_SYMPTOMS: '/symptoms',
  GET_TESTIMONIALS: '/testimonials',
  UPDATE_APPOINTMENT_TAG: '/appointments/:id',
  POST_FOLLOWUP: '/doctorDetails/:id',
  GET_PARTNERS: '/admin/partners',
  GET_PINCODES: '/admin/city-pincode',
  GET_PRODUCTS: '/admin/products',
  GET_BUNDLES: '/admin/bundles',
  CREATE_BUNDLEPRODS: '/admin/bundle-products',
  GET_CLINICS: '/clinics',
  GET_KEYWORDS: '/admin/keywords',
  GET_CONCERNS: '/admin/concerns',
  GET_MESSAGES: '/typeform/sent-messages',
  BUNDLE_CAT: '/admin/bundles/category',
  UPLOAD_LINKS: '/publicFilesServices/urls?n=1',
  UPLOAD_LINKSDG: '/privateFilesServices/urls',
  GET_SAMPLES: 'admin/sampleType',
  GET_PROVIDER_BRANCHES: 'admin/partnersbranch',
  GET_TESTTYPE: '/admin/test-type',
  GET_FAQ: '/admin/faq',
  GET_POOLS: '/admin/availabilityPool/doctor/:id',
  GET_CPORDERS: '/admin/pfh/orders',
  GET_DIAGNOSTIC_PRODUCTS: '/products',
  GET_DIAGNOSTIC_CUSTOMPRODUCTS: '/custom-products',
  GET_COUPON_PROD_CONST: '/coupons-constraints/product/:cid',
  GET_DIAG_ORDERS: '/orders',
  UPDATE_ORDERS: '/update-order/:id',
  CREATE_PROFILE: '/profiles',
  OPEN_REPORT: '/privateFilesServices/files',
  CREATE_COUPONS: '/admin/coupons/free-consultation',
  VERIFY_COUPON: 'coupons/verify/:code',
  OPTINUSERS: '/typeform/optin-users',
  EXPORTUSERS: '/customers/export',
  GET_ALLDRDETAILS: '/doctorDetails',
  CREATE_POOLS: '/admin/availabilityPool',
  GET_POOLSLOTS: '/admin/docAvailability/:id',
  DOWNLOAD_PAYMENTS: '/reports/payment',
  DOCTOR_SERVICE: '/admin/docServices',
  DOCTOR_SERVICE_AVAILABILITY: '/admin/docServiceAvailability',
  AVAILABILITYPOOL_BYDOCTOR: '/admin/availabilityPool/doctor',
  LINK_DOCTORAVAILABILITYPOOLWITHDRSERVICE: '/admin/docServiceAvailability',
  editorImage: (id) => `/uploads/get?id=${id}&is_public=true`,
  PATCH_FOLLOWUP: '/doctorDetails/:id',
  NOTIFY_USER: '/appointments/no-show-notification/:id',
  GET_BANNERS: '/admin/banners',
  GET_CITY: '/admin/city',
  AVAILTCORDER: '/orders/appointment',
  GETCP_ORDERITEMS: '/customer/pfh/orderitems',
  // GETCP_ORDERITEMS: '/admin/pfh/orders/orderitem',
  GET_DOCSLOTS: '/docSlots',
  GET_AVAILEDORDERS: '/admin/pfh/orders/getAvailed/',
  UPDATE_CPORDER_ITEM: '/admin/pfh/orderitems',
  DELETE_DOC_SERVICE_AVAILABILITY: '/admin/docServiceAvailability/delete',
  GET_DOCSLOTSV2: '/docSlots/v2/:id/service/:serviceId/clinic/:clinicId',
  GET_DOCSLOTSV2_RR: '/docSlots/v2/service/:serviceId/clinic/:clinicId',
  CREATE_OFFLINEAPPOINTMENTS: '/admin/appointment/orders',
  DOC_SERVICES: '/admin/docServiceTags',
  GET_INVOICES: '/invoice',
  RESCHEDULE_GENERAL_APPOINT: '/admin/appointment/reschedule/',
  FOLLOWUP_SLOTS: '/docSlots/v2/followUp/',
  GET_UNAVAILABILITY: '/admin/docUnavailability/doctor',
  NEW_CONSTRAINTS: '/admin/coupons/linked/:id',
  NEW_BULK: '/coupons-constraints/doctor/bulk',
  DELETE_BULK: 'admin/coupons-constraints/bulk',
  UPDATE_USER_SPECIALIZATION_BULK: 'coupons-constraints/user-specialization/bulk',
  UPDATE_DOCTOR_SPECIALIZATION_BULK: 'coupons-constraints/user-doctor/bulk',
  CREATE_OFFLINE_DG_APPOINTMENTS: 'create-admin-order',
  GET_DIAG_TEMP_TRANSACTIONS: 'temp-transactions',
  GET_DIAG_TEMP_TRANSACTIONS_BY_ID: 'temp-transactions/:id',
  DIAG_QR_LINK: 'qrcode/:id',
  DIAG_RESEND_PAYMENT_LINK: 'resendPlink/:id',
  DIAG_VERIFY_PAYMENT: 'admin-walkin-refresh/:id',
  CP_QR_LINK: 'admin/pfh/orders/qrCode/:id',
  CP_RESEND_PAYMENT_LINK: 'admin/pfh/orders/resendPlink/:id',
  CP_VERIFY_PAYMENT: 'admin/pfh/orders/verifyAdmin/:id',
  OFFLINE_CP_BOOKING: 'admin/pfh/orders',
  GET_CP_TEMP_TRANSACTIONS: 'admin/pfh/orders/tempTransactions/filter',
  GET_CP_TEMP_TRANSACTIONS_BY_ID: 'admin/pfh/orders/tempTransactions/:id',
  GET_CUSTOM_PRODUCTS: 'custom-products/custom-lab-tests',
  MULTI_CART: 'admin/multi-cart',
  GET_MULTI_CART_ORDER_ID: 'admin/multi-cart/:id',
  GET_MULTI_CART_ORDER_QR_LINK: 'admin/multi-cart/qrCode/:id',
  GET_MULTI_CART_ORDER_RESEND_PAYMENT_LINK: 'admin/multi-cart/resendPlink/:id',
  GET_MULTI_CART_ORDER_VERIFY_PAYMENT: 'admin/multi-cart/verifyAdmin/:id',
}
