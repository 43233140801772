import { apiClient } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'
// import { store } from 'redux/store'

class TopicsServices {
  constructor() {
    this.client = apiClient
  }

  getTopics = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_TOPICS}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getTopic = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_TOPICS}/${payload.id}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateTopic = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.GET_TOPICS}/${payload.id}`, payload.body)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addTopic = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_TOPICS}`, payload.body)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteTopic = (payload = {}) => {
    return this.client
      .delete(`${BACKEND_ROUTES.GET_TOPICS}/${payload.id}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}
export default TopicsServices
