const actions = {
  SET_STATE: 'blogs/SET_STATE',
  GET_BLOGS: 'blogs/GET_BLOGS',
  GET_BLOGS_DETAILS: 'blogs/GET_BLOGS_DETAILS',
  GET_AOCS: 'blogs/GET_AOCS',
  GET_COMPANIES: 'blogs/GET_COMPANIES',
  GET_TOPICS: 'blogs/GET_TOPICS',
  UPDATE_BLOGS_DATA: 'blogs/UPDATE_BLOGS_DATA',
  ADD_BLOGS_DATA: 'blogs/ADD_BLOGS_DATA',
}

export default actions
