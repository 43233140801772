const actions = {
  SET_STATE: 'offlineAppointments/SET_STATE',
  GET_OFFLINEAPPOINTMENTS: 'offlineAppointments/GET_OFFLINEAPPOINTMENTS',
  GET_RR_SLOTS: 'offlineAppointments/GET_RR_SLOTS',
  GET_SLOTS: 'offlineAppointments/GET_SLOTS',
  CREATE_BOOKING: 'offlineAppointments/CREATE_BOOKING',
  VERIFY_PAYMENT: 'offlineAppointments/VERIFY_PAYMENT',
  DELETE_APPOINTMENT: 'offlineAppointments/DELETE_APPOINTMENT',
  RESEND_LINK: 'offlineAppointments/RESEND_LINK',
  SHOW_PAYMENTLINK: 'offlineAppointments/SHOW_PAYMENTLINK',
  GET_SINGLEOFFAPP: 'offlineAppointments/GET_SINGLEOFFAPP',
  VERIFY_QR: 'offlineAppointments/VERIFY_QR',
  GET_FOLLOWUPSLOTS: 'offlineAppointments/GET_FOLLOWUPSLOTS',
}

export default actions
