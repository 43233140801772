import React, { Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { store } from '../redux/store'

function ProtectedRoute({
  component: Component,
  path,
  exact,
  role,
  roleId,
  routerAnimation,
  writeScope,
}) {
  let authorized = ''
  const check = JSON.parse(localStorage.getItem('persist:authType'))
  if (check) {
    authorized = JSON.parse(check?.user)?.authorized || store.getState().user.authorized
  } else {
    return <Redirect to="auth/login" />
  }
  if (authorized) {
    const user = JSON.parse(check.user).role || store.getState().user.user.role
    roleId = user
    const data = role === null ? true : role.find((val) => val.user === roleId)
    // eslint-disable-next-line no-nested-ternary
    writeScope = role === null ? true : data?.access?.length > 1 ? data?.access[1] === 'w' : false
    if (role === null || data) {
      return (
        <Route
          path={path}
          key={path}
          exact={exact}
          render={() => {
            return (
              <div className={routerAnimation}>
                <Suspense fallback={null}>
                  <Component writeScope={writeScope} />
                </Suspense>
              </div>
            )
          }}
        />
      )
    }
  } else {
    return <Redirect to="/auth/login" />
  }
}

export default ProtectedRoute
