const { apiClient1, apiClient2, apiClient4, apiClient5 } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class DiagnosticOfflineBookingService {
  constructor() {
    this.client = apiClient1
    this.client2 = apiClient2
    this.client4 = apiClient4
    this.client1 = apiClient5
  }

  getClinics = (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_CLINICS}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getClinicProduct = (payload = '') => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_DIAGNOSTIC_PRODUCTS}?${payload.data}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createDgBooking = (payload = {}) => {
    return this.client4
      .post(`${BACKEND_ROUTES.CREATE_OFFLINE_DG_APPOINTMENTS}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getTempTransaction = (payload = '') => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_DIAG_TEMP_TRANSACTIONS}?${payload}`, {
        // params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getOrder = (payload = '') => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_DIAG_ORDERS}?${payload}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getTempTransactionById = (payload = '') => {
    return this.client2
      .get(
        `${BACKEND_ROUTES.GET_DIAG_TEMP_TRANSACTIONS_BY_ID.replace(':id', payload.id)}?${
          payload?.data
        }`,
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getQRLink = (payload = {}) => {
    return this.client4
      .get(`${BACKEND_ROUTES.DIAG_QR_LINK.replace(':id', payload.id)}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  resendPaymentLink = (payload = {}) => {
    return this.client4
      .get(`${BACKEND_ROUTES.DIAG_RESEND_PAYMENT_LINK.replace(':id', payload.id)}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  verifyPayment = (payload = {}) => {
    return this.client4
      .post(`${BACKEND_ROUTES.DIAG_VERIFY_PAYMENT.replace(':id', payload.id)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getCustomProduct = () => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_CUSTOM_PRODUCTS}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default DiagnosticOfflineBookingService
