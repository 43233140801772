const { BACKEND_ROUTES } = require('common/constant')
const { apiClient } = require('utilities/configureAxios')

class MessageServices {
  constructor() {
    this.client = apiClient
  }

  getMessages = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_MESSAGES}`, { params: payload })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleMessage = (payload = {}) => {
    // console.log(`${BACKEND_ROUTES.GET_WEBINARS}`)
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_MESSAGES.concat('/').concat(temp[1])}`, {})
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateSingleMessage = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_MESSAGES.concat('/').concat(temp[1])}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveMessage = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.GET_MESSAGES}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  uploadCSV = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.GET_MESSAGES}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  optinUsers = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.OPTINUSERS}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  optinUsers = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.OPTINUSERS}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default MessageServices
