const { apiClient } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class PaymentServices {
  constructor() {
    this.client = apiClient
  }

  getPayments = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_PAYMENTS.replace('id', payload.page)}`, { params: payload })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getPaymentKey = (payload = {}) => {
    let api = `${BACKEND_ROUTES.GET_PAYMENTS}`
    const temp = payload.search
    const search = '&keyword='.concat(temp)
    api = api.concat(search)
    return this.client
      .get(`${api.replace('id', payload.page)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default PaymentServices
