import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import { history } from 'redux/store'
import DoctorServiceServices from 'services/doctorServices'
import actions from './actions'

const services = new DoctorServiceServices()

export function* GETDOCSERVICES(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.getDoctorServices, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { doctorServices: response.data } })
  }
}

export function* GETSINGLEDOCSERVICE(action) {
  const response = yield doApiCall(
    services.getSingleDoctorService,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleDocServiceData: response.data } })
  }
}

export function* UPDATEDOCSERVICE(action) {
  const response = yield doApiCall(services.updateDoctorService, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedDocServiceData: response.data } })
    history.goBack(-1)
  }
}

export function* SAVEDOCSERVICE(action) {
  const response = yield doApiCall(services.createDoctorService, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield put({ type: actions.SET_STATE, payload: { newDocServiceData: response.data } })
    history.goBack(-1)
  }
}

export function* DELETESUPPORTG(action) {
  const response = yield doApiCall(services.deleteQuestions, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Deleted',
    })
    yield put({ type: actions.SET_STATE, payload: { deleted: response.data } })
  }
}

export function* DELETELINKEDAVAILABILITYPOOLID(action) {
  const response = yield doApiCall(services.deleteLinkedPoolId, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Deleted',
    })
    yield put({ type: actions.SET_STATE, payload: { newQuestionData: response.data } })
    window.location.reload()
  }
}

export function* CHECKDOCSERVICE(action) {
  const response = yield doApiCall(services.checkDocService, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { checkDocservice: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DOCSERVICES, GETDOCSERVICES)])
  yield all([takeEvery(actions.GETSINGLE_DOCSERVICE, GETSINGLEDOCSERVICE)])
  yield all([takeEvery(actions.UPDATE_DOCSERVICE, UPDATEDOCSERVICE)])
  yield all([takeEvery(actions.SAVE_DOCSERVICE, SAVEDOCSERVICE)])
  yield all([takeEvery(actions.DELETE_LINKED_AVAILABILITYPOOLID, DELETELINKEDAVAILABILITYPOOLID)])
  yield all([takeEvery(actions.CHECK_DOCSERVICE, CHECKDOCSERVICE)])
}
