import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'helper'
import PhysiciansServices from 'services/physicians'
import axios from 'axios'
import { notification } from 'antd'
import { history } from 'redux/store'
import dayjs from 'dayjs'
import actions from './actions'

// import { notification } from 'antd';

const services = new PhysiciansServices()
export function* WATCH_GET_PHYSICIANS(action) {
  const response = yield doApiCall(services.getPhysicians, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { physiciansData: response.data } })
  }
}
export function* WATCH_FILTER_PHYSICIANS(action) {
  const response = yield doApiCall(services.filterPhysicians, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { filteredPhysiciansData: response.data.data } })
  }
}
export function* WATCH_GET_PHYSICIANS_DETAILS(action) {
  const response = yield doApiCall(services.getPhysician, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singlephysicianData: response.data } })
  }
}
export function* WATCH_GET_SPECIALIZATION(action) {
  const response = yield doApiCall(services.getSpecialization, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { specializationData: response.data } })
  }
}
export function* WATCH_GET_CLINIC_DETAILS(action) {
  const response = yield doApiCall(services.getClinicdetails, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { clinicDetails: response.data } })
  }
}
export function* WATCH_GET_PHYSICIAN_AVAILABLE_SLOTS(action) {
  const response = yield doApiCall(services.getMultipleSlots, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { availableSlots: response.data } })
  }
}

export function* WATCH_PHYSICIANS_CLINICS_DETAILS_V2(action) {
  const response = yield doApiCall(
    services.getPhysicianClinicsDetailsV2,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { clinics: response.data.clinics } })
  }
}

export function* WATCH_GET_PHYSICIAN_UNAVAILABLE_SLOTS(action) {
  const response = yield doApiCall(
    services.getUnavailabilitySlots,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { unavailableSlots: response.data } })
  }
}

export function* WATCH_POST_UPLOAD_IMAGE(action) {
  // console.log(action.payload)
  const response = yield doApiCall(services.uploadImageService, action.payload, actions.SET_STATE)

  if (response?.success) {
    // console.log(response)
    yield put({
      type: actions.SET_STATE,
      payload: { image: response?.data },
    })

    yield axios
      .put(response.data.url, action.payload.file, {
        headers: {
          'Content-Type': action.payload.file.type,
        },
      })
      .then(async (s3Response) => {
        if (s3Response.status === 200) {
          notification.success({
            description: 'Image Uploaded',
          })
          return response.data
        }
        return false
      })
      .catch((err) => {
        notification.error({
          description: 'An error occurred while trying to upload file.',
        })
        throw err
      })
  }
}
export function* WATCH_PATCH_UPDATE_PHYSICIAN(action) {
  const response = yield doApiCall(services.updatePhysicianData, action.payload, actions.SET_STATE)

  if (response?.success) {
    notification.success({
      message: 'Updated Succesfully',
    })
    yield put({
      type: actions.GET_PHYSICIANS_DETAILS,
      payload: {
        id: action.payload.id,
        include: 'doctorDetails',
      },
    })
  }
}
export function* WATCH_POST_ADD_PHYSICIAN(action) {
  // console.log('inside saga ', action.payload)
  // console.log('dispatch heard')
  const response = yield doApiCall(services.addPhysicianData, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Created Succesfully',
    })
    history.goBack(-1)
  }
}
export function* WATCH_POST_ADD_SLOTS(action) {
  const response = yield doApiCall(services.addSlots, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Availability added Succesfully',
    })
    yield put({ type: actions.SET_STATE, payload: { availableSlots: response.data.schedule } })
  }
}
export function* WATCH_POST_FOLLOWUP(action) {
  const response = yield doApiCall(services.patchFollowUp, action.payload, actions.SET_STATE)
  if (response?.success) {
    if (action.payload.cb) {
      action.payload.cb()
    }
    notification.success({
      message: 'Follow Up added Succesfully',
    })
    // yield put({ type: actions.SET_STATE, payload: { availableSlots: response.data } })
  }
}
export function* WATCH_POST_ADD_UNAVAILABILITY_SLOTS(action) {
  yield console.log(action.payload)
  const payload = {
    from: dayjs(action.payload?.unavailability[0]?.from, 'YYYY-MM-DD HH:mm').format(
      'YYYY-MM-DD HH:mm:ss',
    ),
    to: dayjs(action.payload?.unavailability[0]?.to, 'YYYY-MM-DD HH:mm').format(
      'YYYY-MM-DD HH:mm:ss',
    ),
  }
  const response = yield doApiCall(services.addUnavailabilitySlots, payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'UnAvailability added Succesfully',
    })
    yield put({ type: actions.SET_STATE, payload: { unavailableSlots: response.data } })
    window.location.reload()
  }
}

export function* POSTPHYSICIAN(action) {
  // console.log('dispatch heard')
  // yield console.log(action.payload)
  const response = yield doApiCall(services.addPhysicianData, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'New Doctor Profile Created, Continue updatiing the details',
    })
    history.goBack(-1)
  }
  // console.log(response?.status)
}

export function* GETALLPOOLS(action) {
  const response = yield doApiCall(services.getAllPools, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { drAvailPools: response.data } })
  }
}

export function* GETALLDRDETAILS(action) {
  const response = yield doApiCall(services.getAllDrDetails, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { drAvailPools: response.data } })
  }
}

export function* CREATEPOOL(action) {
  yield put({ type: actions.SET_STATE, payload: { loadPool: true } })
  const response = yield doApiCall(services.createPool, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { loadPool: true } })
    yield put({ type: actions.SET_STATE, payload: { createdPool: response.data } })
    window.location.reload()
  }
}

export function* GETSLOTS(action) {
  const response = yield doApiCall(services.getPoolSlots, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { poolSlots: response.data } })
  }
}

export function* ADDSLOTTOPOOL(action) {
  const response = yield doApiCall(services.addSlotToPool, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { addslottopool: response.data } })
    notification.success({
      message: 'Slot Added Successfully',
    })
  }
}

export function* GETPOOLBYDRID(action) {
  const response = yield doApiCall(services.getPoolByDrId, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { addslottopool: response.data } })
    notification.success({
      message: 'Slot Added Successfully',
    })
  }
}

export function* LINKAVAILABILITYPOOLID(action) {
  const response = yield doApiCall(
    services.linkAvailabilityPoolid,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { drService_AvailPool: response.data } })
    notification.success({
      message: 'Pool Added Successfully',
    })
    window.location.reload()
  }
}

export function* DELETELINKEDAVAILABILITYPOOLID(action) {
  const response = yield doApiCall(services.deleteLinkedPoolId, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { drService_AvailPool: response.data } })
    notification.success({
      message: 'Pool Deleted Successfully',
    })
    history.goBack(-1)
  }
}

export function* DELETESLOT(action) {
  console.log(action.payload)
  const response = yield doApiCall(services.deleteSlot, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { drService_AvailPool: response.data } })
    // notification.success({
    //   message: 'Slot Deleted Successfully'
    // })
    // history.goBack(-1)
  }
}

export function* GETUNAVAILABILITY(action) {
  const response = yield doApiCall(services.getUnavailability, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { unavalibility: response.data } })
  }
}

export function* ADDUNAVAILABILITY(action) {
  const response = yield doApiCall(services.addUnavailability, action.payload, actions.SET_STATE)
  if (response.success) {
    yield put({ type: actions.GET_UNAVAILABILITY, payload: { id: action.payload.data.doctorId } })
  }
}

export function* UPDATEUNAVAILABILITY(action) {
  const response = yield doApiCall(services.patchUnavailability, action.payload, actions.SET_STATE)
  if (response.success) {
    yield put({ type: actions.GET_UNAVAILABILITY, payload: { id: action.payload.doctorId } })
  }
}

export function* DELETEUNAVAILIBILITY(action) {
  const response = yield doApiCall(services.deleteUnavailability, action.payload, actions.SET_STATE)
  if (response.success) {
    yield put({ type: actions.GET_UNAVAILABILITY, payload: { id: action.payload.doctorId } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_PHYSICIANS, WATCH_GET_PHYSICIANS)])
  yield all([takeEvery(actions.FILTER_PHYSICIANS, WATCH_FILTER_PHYSICIANS)])
  yield all([takeEvery(actions.GET_PHYSICIANS_DETAILS, WATCH_GET_PHYSICIANS_DETAILS)])
  yield all([takeEvery(actions.GET_SPECIALIZATION, WATCH_GET_SPECIALIZATION)])
  yield all([takeEvery(actions.GET_CLINIC_DETAILS, WATCH_GET_CLINIC_DETAILS)])
  yield all([
    takeEvery(actions.GET_PHYSICIANS_AVAILABILE_SLOTS, WATCH_GET_PHYSICIAN_AVAILABLE_SLOTS),
  ])
  yield all([takeEvery(actions.GET_UNAVAILABILE_SLOTS, WATCH_GET_PHYSICIAN_UNAVAILABLE_SLOTS)])
  yield all([takeEvery(actions.UPLOAD_IMAGE, WATCH_POST_UPLOAD_IMAGE)])
  yield all([takeEvery(actions.UPDATE_PHYSICIAN_DETAIL, WATCH_PATCH_UPDATE_PHYSICIAN)])
  yield all([takeEvery(actions.ADD_PHYSICIAN, WATCH_POST_ADD_PHYSICIAN)])
  yield all([takeEvery(actions.ADD_SLOTS, WATCH_POST_ADD_SLOTS)])
  yield all([takeEvery(actions.ADD_UNAVAILABILITY_SLOTS, WATCH_POST_ADD_UNAVAILABILITY_SLOTS)])
  yield all([takeEvery(actions.POST_PHYSICIAN, POSTPHYSICIAN)])
  yield all([takeEvery(actions.PATCH_FOLLOWUP, WATCH_POST_FOLLOWUP)])
  yield all([takeEvery(actions.GETALLPOOLS, GETALLPOOLS)])
  yield all([takeEvery(actions.GET_ALLDRDETAILS, GETALLDRDETAILS)])
  yield all([takeEvery(actions.CREATE_POOL, CREATEPOOL)])
  yield all([takeEvery(actions.GET_SLOTS, GETSLOTS)])
  yield all([takeEvery(actions.ADD_SLOTTOPOOL, ADDSLOTTOPOOL)])
  yield all([takeEvery(actions.GET_POOLBYDRID, GETPOOLBYDRID)])
  yield all([takeEvery(actions.LINK_AVAILABILITYPOOLID, LINKAVAILABILITYPOOLID)])
  yield all([takeEvery(actions.DELETE_LINKED_AVAILABILITYPOOLID, DELETELINKEDAVAILABILITYPOOLID)])
  yield all([takeEvery(actions.DELETE_SLOT, DELETESLOT)])
  yield all([takeEvery(actions.GET_UNAVAILABILITY, GETUNAVAILABILITY)])
  yield all([takeEvery(actions.ADD_UNAVAILABILITY, ADDUNAVAILABILITY)])
  yield all([takeEvery(actions.UPDATE_UNAVAILABILITY, UPDATEUNAVAILABILITY)])
  yield all([takeEvery(actions.DELETE_UNAVAILIBILITY, DELETEUNAVAILIBILITY)])
  yield all([takeEvery(actions.PHYSICIANS_CLINICS_DETAILS_V2, WATCH_PHYSICIANS_CLINICS_DETAILS_V2)])
}
