import { apiClient } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'
// import { store } from 'redux/store'

class UsersServices {
  constructor() {
    this.client = apiClient
  }

  getUsers = (payload = {}) => {
    // const { user } = store.getState().auth
    return this.client
      .get(`${BACKEND_ROUTES.GET_USERS}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}
export default UsersServices
