const actions = {
  SET_STATE: 'partners/SET_STATE',
  GET_ALLPARTNERS: 'partners/GET_partners',
  GET_SINGLE_PARTNERS: 'partners/GET_SINGLE_CATEGORY',
  UPDATE_PARTNER: 'partners/UPDATE_CATEGORY',
  SAVE_PARTNER: 'partners/SAVE_CATEGORY',
  UPDATE_BRANCH: 'partners/UPDATE_BRANCH',
  CREATE_BRANCH: 'partners/CREATE_BRANCH',
  GET_BRANCH: 'partners/GET_BRANCH',
  GET_SINGLEBRANCH: 'partners/GET_SINGLEBRANCH',
}

export default actions
