import { config } from '../../../config'

export default async function getMenuData() {
  return [
    {
      title: 'Appointments',
      key: 'iasepr',
      icon: 'fe fe-calendar',
      url: '/appointments',
      roles: [
        config.superAdmin.roleId,
        config.ops.roleId,
        config.support.roleId,
        config.dgAdmin.roleId,
        config.carePackage.roleId,
        config.supportRead.roleId,
      ],
    },
    {
      title: 'Coupon Codes',
      key: 'coupon-codes',
      url: '/coupon-codes',
      icon: 'fe fe-book-open',
      roles: [
        config.superAdmin.roleId,
        config.ops.roleId,
        config.support.roleId,
        config.dgAdmin.roleId,
        config.carePackage.roleId,
      ],
    },
    {
      title: 'Diagnostics',
      key: 'submenu',
      url: '/submenu',
      icon: 'fe fe-star',
      roles: [
        config.dgAdmin.roleId,
        config.superAdmin.roleId,
        config.carePackage.roleId,
        config.supportRead.roleId,
      ],
      children: [
        {
          title: 'Orders',
          key: 'diagnosticOrders',
          url: '/diagnostic/orders',
          icon: 'fa fa-edit',
        },
      ],
    },
    {
      title: 'Package Orders',
      key: 'cporders',
      url: '/submenu/cpOrders',
      icon: 'fa fa-edit',
      roles: [config.dgAdmin.roleId, config.supportRead.roleId],
    },
    {
      title: 'Care Packages',
      key: 'carepackages',
      url: '/submenu',
      icon: 'fa fa-gift',
      roles: [
        config.superAdmin.roleId,
        config.ops.roleId,
        config.support.roleId,
        config.carePackage.roleId,
      ],
      children: [
        {
          title: 'Banner',
          key: 'banner',
          url: '/banner',
          icon: 'fa fa-image',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Categories',
          key: 'categories',
          url: '/submenu/categories',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Cities',
          key: 'cities',
          url: '/submenu/cities',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Clinics',
          key: 'clinic',
          url: '/submenu/clinics',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Concerns',
          key: 'concerns',
          url: '/submenu/concerns',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'FAQs',
          key: 'faqs',
          url: '/submenu/faqs',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Keywords',
          key: 'keywords',
          url: '/submenu/keywords',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Lab',
          key: 'lab',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
          children: [
            {
              title: 'Lab Test Profiles',
              key: 'labtestprofiles',
              url: '/submenu/labtestprofiles',
            },
            {
              title: 'Lab Test Components',
              key: 'labtests',
              url: '/submenu/labtests',
            },
          ],
        },
        {
          title: 'Orders',
          key: 'orders',
          url: '/submenu/cpOrders',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
            config.dgAdmin.roleId,
          ],
        },
        {
          title: 'Packages',
          key: 'bundles',
          url: '/submenu/bundles',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Pincodes - Cities',
          key: 'pincodes',
          url: '/submenu/pincodes-cities',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Products',
          key: 'products',
          url: '/submenu/products',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Providers',
          key: 'partners',
          url: '/submenu/partners',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
          children: [
            {
              title: 'Partners',
              key: 'partners',
              url: '/submenu/partners',
            },
            {
              title: 'Providers / Branches',
              key: 'provider-branch',
              url: '/submenu/partners/branch',
            },
          ],
        },
        {
          title: 'Sample Type',
          key: 'sampleType',
          url: '/submenu/sampleType',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Services',
          key: 'services',
          url: '/submenu/services',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Tags',
          key: 'tags',
          url: '/submenu/tags',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
        {
          title: 'Test Type',
          key: 'testType',
          url: '/submenu/testType',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            config.carePackage.roleId,
          ],
        },
      ],
    },
    {
      title: 'Tele Consult',
      key: 'tc',
      // url: '/submenu',
      icon: 'fa fa-stethoscope',
      roles: [
        config.superAdmin.roleId,
        config.ops.roleId,
        config.support.roleId,
        // config.admin.roleId,
      ],
      children: [
        {
          title: 'Physicians',
          key: 'physician',
          url: '/physicians',
          icon: 'fa fa-stethoscope',
        },
        {
          title: 'Specializations',
          key: 'specializations',
          url: '/specializations',
          icon: 'lnr lnr-magic-wand',
        },
        {
          title: 'Doctor Services',
          key: 'drservices',
          url: '/submenu/doctorServices',
          icon: 'fa fa-users',
        },
        {
          title: 'Doctor Service Tags',
          key: 'serviceTags',
          url: '/serviceTags',
          icon: 'lnr lnr-bookmark',
        },
      ],
    },
    {
      title: 'Blogs',
      key: 'blogs',
      // url: '/submenu',
      icon: 'fa fa-newspaper-o',
      roles: [
        config.superAdmin.roleId,
        config.ops.roleId,
        config.support.roleId,
        config.content.roleId,
        // config.admin.roleId,
      ],
      children: [
        {
          title: 'Area Of Content',
          key: 'aoc',
          url: '/aoc',
          icon: 'fa fa-map',
        },
        {
          title: 'Authors',
          key: 'authors',
          url: '/authors',
          icon: 'fa fa-pied-piper',
        },
        {
          title: 'Blogs',
          key: 'blogs',
          url: '/blogs',
          icon: 'fa fa-newspaper-o',
        },
        {
          title: 'Topics',
          key: 'topics',
          url: '/topics',
          icon: 'fe fe-clipboard',
        },
      ],
    },
    {
      title: 'Others',
      key: 'others',
      // url: '/submenu',
      icon: 'fe fe-star',
      roles: [config.superAdmin.roleId, config.ops.roleId, config.support.roleId],
      children: [
        {
          title: 'Health Clinics',
          key: 'clinics',
          url: '/clinics',
          icon: 'fa fa-plus-square',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            // config.admin.roleId,
          ],
        },
        {
          title: 'Messages',
          key: 'messages',
          url: '/messages',
          icon: 'fe fe-mail',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            // config.admin.roleId,
          ],
        },
        {
          title: 'Questions',
          key: 'questions',
          url: '/questions',
          icon: 'fa fa-question-circle-o',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            // config.admin.roleId,
          ],
        },
        {
          title: 'Support Groups',
          key: 'supportgroups',
          url: '/support-groups',
          icon: 'fa fa-users',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            // config.admin.roleId,
          ],
        },
        {
          title: 'Testimonials',
          key: 'testimonials',
          url: '/testimonials',
          icon: 'fa fa-edit',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            // config.admin.roleId,
          ],
        },
        {
          title: 'Videos',
          key: 'videos',
          url: '/videos',
          icon: 'fa fa-youtube-play',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            // config.admin.roleId,
          ],
        },
        {
          title: 'Webinars',
          key: 'webinars',
          url: '/webinars',
          icon: 'fa fa-video-camera',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            // config.admin.roleId,
          ],
        },
        {
          title: 'Symptoms',
          key: 'symptoms',
          url: '/symptoms',
          icon: 'fa fa-plus-square',
          roles: [
            config.superAdmin.roleId,
            config.ops.roleId,
            config.support.roleId,
            // config.admin.roleId,
          ],
        },
      ],
    },
    {
      title: 'Programmes',
      key: 'programmes',
      url: '/programmes',
      icon: 'fe fe-radio',
      roles: [
        config.superAdmin.roleId,
        config.ops.roleId,
        config.support.roleId,
        config.carePackage.roleId,
        config.supportRead.roleId,
      ],
    },
    {
      title: 'Test & Referral',
      key: 'test',
      url: '/test',
      icon: 'fa fa-heartbeat',
      roles: [config.superAdmin.roleId, config.ops.roleId, config.support.roleId],
    },

    {
      title: 'Users',
      key: 'companies',
      url: '/companies',
      icon: 'fa fa-user',
      roles: [
        config.superAdmin.roleId,
        config.ops.roleId,
        config.support.roleId,
        config.dgAdmin.roleId,
        config.admin.roleId,
        config.carePackage.roleId,
        config.supportRead.roleId,
      ],
    },
    // {
    //   title: 'Take Bookings',
    //   key: 'booking',
    //   url: '/offline',
    //   icon: 'fe fe-calendar',
    //   roles: [config.superAdmin.roleId, config.dgAdmin.roleId],
    // },

    {
      title: 'MultiCart Bookings',
      key: 'multicart-booking',
      url: '/multicart-booking',
      icon: 'fe fe-calendar',
      roles: [config.superAdmin.roleId, config.dgAdmin.roleId],
    },
  ]
}
