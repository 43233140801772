const actions = {
  SET_STATE: 'physicians/SET_STATE',
  GET_PHYSICIANS: 'physicians/GET_PHYSICIANS',
  FILTER_PHYSICIANS: 'physicians/FILTER_PHYSICIANS',
  GET_PHYSICIANS_DETAILS: 'physicians/GET_PHYSICIANS_DETAILS',
  GET_SPECIALIZATION: 'physicians/GET_SPECIALIZATION',
  GET_CLINIC_DETAILS: 'physicians/GET_CLINIC_DETAILS',
  GET_PHYSICIANS_AVAILABILE_SLOTS: 'physicians/GET_PHYSICIANS_AVAILABILE_SLOTS',
  GET_UNAVAILABILE_SLOTS: 'physicians/GET_UNAVAILABILE_SLOTS',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  UPDATE_PHYSICIAN_DETAIL: 'physicians/UPDATE_PHYSICIAN_DETAIL',
  ADD_PHYSICIAN: 'physicians/ADD_PHYSICIAN',
  ADD_SLOTS: 'physicians/ADD_SLOTS',
  ADD_UNAVAILABILITY_SLOTS: 'physicians/ADD_UNAVAILABILITY_SLOTS',
  POST_PHYSICIAN: 'physicians/POST_PHYSICIAN',
  UPDATEIMAGE: 'physicians/UPDATEIMAGE',
  PATCH_FOLLOWUP: 'physicians/PATCH_FOLLOWUP',
  GETALLPOOLS: 'physicians/GETALLPOOLS',
  CREATE_POOL: 'physicians/CREATE_POOL',
  GET_ALLDRDETAILS: 'physicians/GET_ALLDRDETAILS',
  GET_SLOTS: 'physicians/GET_SLOTS',
  ADD_SLOTTOPOOL: 'physicians/ADD_SLOTTOPOOL',
  GET_POOLBYDRID: 'physicians/GET_POOLBYDRID',
  LINK_AVAILABILITYPOOLID: 'physicians/LINK_AVAILABILITYPOOLID',
  DELETE_LINKED_AVAILABILITYPOOLID: 'physicians/DELETE_LINKED_AVAILABILITYPOOLID',
  DELETE_SLOT: 'physicians/DELETE_SLOT',
  GET_UNAVAILABILITY: 'physicians/GET_UNAVAILABILITY',
  ADD_UNAVAILABILITY: 'physicians/ADD_UNAVAILABILITY',
  UPDATE_UNAVAILABILITY: 'physicians/UPDATE_UNAVAILABILITY',
  DELETE_UNAVAILIBILITY: 'physicians/DELETE_UNAVAILIBILITY',
  PHYSICIANS_CLINICS_DETAILS_V2: 'physicians/PHYSICIANS_DETAILS_V2',
}

export default actions
