const actions = {
  SET_STATE: 'keywords/SET_STATE',
  GET_KEYWORDS: 'keywords/GET_PROGRAMS',
  SAVE_KEYWORD: 'keywords/GET_PROGRAMBYTYPE',
  GET_SINGLE_KEYWORD: 'keywords/GET_SIINGLE_KEYWORD',
  UPDATE_KEYWORD: 'keywords/UPDATE_KEYWORD',
  DELETE_KEYWORD: 'keywords/DELETE_KEYWORD',
}

export default actions
