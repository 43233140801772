import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import SupportGroupsServices from 'services/supportgroups'
import { history } from 'redux/store'
import actions from './actions'

const services = new SupportGroupsServices()

export function* GETSUPPORTG(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.getSupportGroups, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { supportGroupsData: response.data } })
  }
}

export function* GETSINGLESUPPORTG(action) {
  const response = yield doApiCall(
    services.getSingleSupportGroup,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleSupportGroupData: response.data } })
  }
}

export function* UPDATESUPPORTG(action) {
  const response = yield doApiCall(services.updateSupportGroup, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield history.push('/support-groups')
    yield put({ type: actions.SET_STATE, payload: { updatedSupportGroupData: response.data } })
  }
}

export function* SAVESUPPORTG(action) {
  const response = yield doApiCall(services.saveSupportGroup, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield history.push('/support-groups')
    yield put({ type: actions.SET_STATE, payload: { newSupportGroupData: response.data } })
  }
}

export function* DELETESUPPORTG(action) {
  const response = yield doApiCall(services.deleteSupportGroup, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Deleted',
    })
    yield history.push('/support-groups')
    yield put({ type: actions.SET_STATE, payload: { newSupportGroupData: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_SUPPORT_GROUPS, GETSUPPORTG)])
  yield all([takeEvery(actions.GET_SINGLESUPPORT_GROUPS, GETSINGLESUPPORTG)])
  yield all([takeEvery(actions.UPDATE_SUPPORT_GROUPS, UPDATESUPPORTG)])
  yield all([takeEvery(actions.SAVE_SUPPORT_GROUPS, SAVESUPPORTG)])
  yield all([takeEvery(actions.DELETE_SUPPORT_GROUPS, DELETESUPPORTG)])
}
