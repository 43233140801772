import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'helper'
import TopicsServices from 'services/topics'
import { notification } from 'antd'
import { history } from 'redux/store'
import actions from './actions'

const services = new TopicsServices()

export function* WATCH_GET_TOPICS(action) {
  const response = yield doApiCall(services.getTopics, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { topicsData: response.data } })
  }
}
export function* WATCH_GET_TOPICS_DETAILS(action) {
  const response = yield doApiCall(services.getTopic, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { topicsData: response.data } })
  }
}

export function* WATCH_POST_TOPICS(action) {
  const response = yield doApiCall(services.addTopic, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Added Succesfully',
    })
    yield history.push('/topics')
  }
}
export function* WATCH_PATCH_TOPICS(action) {
  const response = yield doApiCall(services.updateTopic, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Updated Succesfully',
    })
    yield history.push('/topics')
  }
}

export function* WATCH_DELETE_TOPIC(action) {
  const response = yield doApiCall(services.deleteTopic, action.payload, actions.SET_STATE)
  if (!response) {
    yield history.push('/topics')
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_TOPICS, WATCH_GET_TOPICS)])
  yield all([takeEvery(actions.GET_TOPICS_DETAILS, WATCH_GET_TOPICS_DETAILS)])
  yield all([takeEvery(actions.UPDATE_TOPICS_DATA, WATCH_PATCH_TOPICS)])
  yield all([takeEvery(actions.ADD_TOPICS_DATA, WATCH_POST_TOPICS)])
  yield all([takeEvery(actions.DELETE_TOPIC, WATCH_DELETE_TOPIC)])
}
