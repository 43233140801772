const actions = {
  SET_STATE: 'clinic/SET_STATE',
  GET_CLINIC: 'clinic/GET_CLINIC',
  GET_SINGLECLINIC: 'clinic/GET_CLINIC',
  UPDATE_CLINIC: 'clinic/UPDATE_CLINIC',
  SAVE_CLINIC: 'clinic/SAVE_CLINIC',
  // GET_CUSTOMERS: 'companies/GET_CUSTOMERS',
  // GET_STATS: 'companies/GET_STATS',
  // GET_SINGLEUSER: 'companies/GET_SINGLEUSER',
  // UPDATE_USER: 'companies/UPDATE_USER',
  // SAVE_USERS: 'companies/SAVE_USER',
}

export default actions
