import { doApiCall } from 'helper'
import { put, takeEvery, all } from 'redux-saga/effects'
import { history } from 'redux/store'
import actions from './actions'

const { default: SpecializationServices } = require('services/specializations')

const services = new SpecializationServices()

export function* GETSPECIALIZATIONS(action) {
  const response = yield doApiCall(services.getspecializations, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { specializationsData: response.data } })
  }
}

export function* GETSPECIALIZATION(action) {
  // console.log('single specialization called')
  const response = yield doApiCall(
    services.getSinglespecializations,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleSpecializationData: response.data } })
  }
}

export function* UPDATESPECIALIZATION(action) {
  const response = yield doApiCall(services.updateSpecialization, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { updatedspecializationData: response.data } })
  }
}

export function* SAVESPECIALIZATION(action) {
  const response = yield doApiCall(services.saveSpecialization, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { savedSpecializationData: response.data } })
  }
}

export function* DELETESPECIALIZATION(action) {
  const response = yield doApiCall(services.deleteSpecialization, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { savedSpecializationData: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_SPECIALIZATIONS, GETSPECIALIZATIONS)])
  yield all([takeEvery(actions.GET_SPECIALIZATION, GETSPECIALIZATION)])
  yield all([takeEvery(actions.UPDATE_SPECIALIZATION, UPDATESPECIALIZATION)])
  yield all([takeEvery(actions.SAVE_SPECIALIZATION, SAVESPECIALIZATION)])
  yield all([takeEvery(actions.DELETE_SPECIALIZATION, DELETESPECIALIZATION)])
}
