import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import TagsServices from 'services/tags'
import { history } from 'redux/store'
import actions from './actions'

const services = new TagsServices()

export function* GETTAGS(action) {
  const response = yield doApiCall(services.getTags, action.payload, actions.SET_STATE)
  console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { tagsData: response.data } })
  }
}

export function* GETSINGLETAG(action) {
  const response = yield doApiCall(services.getSingleTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleTagData: response.data } })
  }
}

export function* UPDATE_TAG(action) {
  const response = yield doApiCall(services.updateTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Tag Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
  }
  history.goBack(-1)
}

export function* SAVE_TAG(action) {
  const response = yield doApiCall(services.saveTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Tag Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCategory: response.data } })
    history.goBack(-1)
  }
}

export function* DELETETAG(action) {
  console.log('here')
  const response = yield doApiCall(services.deleteTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Tag Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCategory: response.data } })
  }
  history.goBack(-1)
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GETALLTAGS, GETTAGS)])
  yield all([takeEvery(actions.GET_SINGLE_TAG, GETSINGLETAG)])
  yield all([takeEvery(actions.UPDATE_TAG, UPDATE_TAG)])
  yield all([takeEvery(actions.SAVE_TAG, SAVE_TAG)])
  yield all([takeEvery(actions.DELETE_TAG, DELETETAG)])
}
