import { doApiCall } from 'helper'
import { all, put, takeEvery } from 'redux-saga/effects'
import CpOfflineBookingService from 'services/cpOfflineBooking'
import actions from './actions'

const services = new CpOfflineBookingService()

export function* CREATE_CP_BOOKING(action) {
  const response = yield doApiCall(services.createCpBooking, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { createdBooking: response } })
  }
}

export function* GET_TEMP_TRANSACTIONS(action) {
  const response = yield doApiCall(services.getTempTransaction, action.payload, actions.SET_STATE)
  yield put({ type: actions.SET_STATE, payload: { tempTransaction: { data: [], pagination: {} } } })
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { tempTransaction: response.data } })
  }
}

export function* GET_TEMP_TRANSACTIONS_BY_ID(action) {
  const response = yield doApiCall(
    services.getTempTransactionById,
    action.payload,
    actions.SET_STATE,
  )
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { singleTempTransaction: response.data } })
  }
}

export function* SHOW_QR_LINK(action) {
  const response = yield doApiCall(services.getQRLink, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { qrLink: response.data } })
  }
}

export function* RESEND_LINK(action) {
  const response = yield doApiCall(services.resendPaymentLink, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { paymentLink: response } })
  }
}

export function* VERIFY_PAYMENT(action) {
  const response = yield doApiCall(services.verifyPayment, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { paymentLink: response } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_CP_BOOKING, CREATE_CP_BOOKING)])
  yield all([takeEvery(actions.GET_TEMP_TRANSACTIONS, GET_TEMP_TRANSACTIONS)])
  yield all([takeEvery(actions.GET_TEMP_TRANSACTIONS_BY_ID, GET_TEMP_TRANSACTIONS_BY_ID)])
  yield all([takeEvery(actions.SHOW_QR_LINK, SHOW_QR_LINK)])
  yield all([takeEvery(actions.RESEND_LINK, RESEND_LINK)])
  yield all([takeEvery(actions.VERIFY_PAYMENT, VERIFY_PAYMENT)])
}
