const { apiClient, apiClient1 } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class SpecializationServices {
  constructor() {
    this.client = apiClient
    this.client1 = apiClient1
  }

  getspecializations = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_SPECIALIZATIONS.replace('id', payload.page)}`, { params: payload })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSinglespecializations = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.EDIT_SPECIALIZATION.replace(':id', payload.id)}`, {
        params: {
          include: payload.include,
        },
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateSpecialization = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.UPDATE_SPECIALIZATION.replace(':id', payload.id)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveSpecialization = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.SAVE_SPECIALIZATION}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteSpecialization = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.SAVE_SPECIALIZATION.concat(`/${payload.id}/status`)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default SpecializationServices
