const actions = {
  SET_STATE: 'multiCart/SET_STATE',
  CLEAR_CART: 'multiCart/CLEAR_CART',
  CREATE_MULTI_CART: 'multiCart/CREATE_MULTI_CART',
  ADD_MULTI_CART: 'multiCart/ADD_MULTI_CART',
  REMOVE_MULTI_CART: 'multiCart/REMOVE_MULTI_CART',
  EDIT_MULTI_CART: 'multiCart/EDIT_MULTI_CART',
  GET_ORDERS: 'multiCart/GET_ORDERS',
  GET_ORDERS_BY_ID: 'multiCart/GET_ORDERS_BY_ID',
  SHOW_QR_LINK: 'multiCart/SHOW_QR_LINK',
  RESEND_LINK: 'multiCart/RESEND_LINK',
  VERIFY_PAYMENT: 'multiCart/VERIFY_PAYMENT',
}

export default actions
