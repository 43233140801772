const actions = {
  SET_STATE: 'banners/SET_STATE',
  GET_ALLBANNERS: 'banners/GET_ALLBANNERS',
  GET_SINGLE_BANNERS: 'banners/GET_SINGLE_BANNERS',
  UPDATE_BANNERS: 'banners/UPDATE_BANNERS',
  SAVE_BANNERS: 'banners/SAVE_BANNERS',
  DELETE_BANNERS: 'banners/DELETE_BANNERS',
}

export default actions
