import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import { history } from 'redux/store'
import BundleServices from 'services/bundles'
import actions from './actions'

const services = new BundleServices()

export function* GETBUNDLES(action) {
  const response = yield doApiCall(services.getBundles, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { bundlesData: response.data } })
  }
}

export function* GETSINGLEBUNDLES(action) {
  const response = yield doApiCall(services.getSingleBundles, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleBundleData: response.data } })
  }
}

export function* UPDATEBUNDLES(action) {
  // console.log(action.payload)
  const response = yield doApiCall(services.updateBundles, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Bundle Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
  }
}

export function* SAVEBUNDLES(action) {
  const response = yield doApiCall(services.saveBundles, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Bundle Created Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { saveBundleData: response.data } })
    history.goBack(-1)
  }
}

export function* SETPRODUCT(action) {
  const response = yield doApiCall(services.bundleProduct, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Bundle Product Created Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { createbundleProduct: response.data } })
    window.location.reload()
  }
}

export function* GETBUNDLEPRODUCTS(action) {
  const response = yield doApiCall(services.getBundleProduct, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { bundleProducts: response.data } })
  }
}

export function* DELETEBUNDLEPROD(action) {
  // yield // console.log(action)
  const response = yield doApiCall(services.deleteBundleProd, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { bundleProducts: response.data } })
  }
}

export function* GETBUNDLEPRODUCTSBYID(action) {
  const response = yield doApiCall(services.getBundleProductByid, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { bundleProductsbyId: response.data } })
  }
}

export function* UPDATEBUNDLEPROD(action) {
  const res = yield doApiCall(services.updateBundles, action.payload.bundleData, actions.SET_STATE)
  if (res.success) {
    const response = yield doApiCall(services.updateBundleProd, action.payload, actions.SET_STATE)
    if (response) {
      yield put({ type: actions.SET_STATE, payload: { bundleProductsUpdated: response.data } })
      window.location.reload()
    }
  }
}

export function* BUNDLECAT(action) {
  const response = yield doApiCall(services.bundleCat, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { bundleCategory: response.data } })
  }
}

export function* UPDATEBUNDLEPRODREDEEMRANK(action) {
  const response = yield doApiCall(services.bundleRedeemRank, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { bundleRedeemRank: response.data } })
  }
}

export function* SYNCPINCODES(action) {
  const response = yield doApiCall(services.syncPincodes, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { syncPins: response.data } })
    notification.success({
      message: 'Pincodes Synced',
    })
    // window.location.reload()
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_BUNDLES, GETBUNDLES)])
  yield all([takeEvery(actions.GET_SINGLE_BUNDLE, GETSINGLEBUNDLES)])
  yield all([takeEvery(actions.UPDATE_BUNDLE, UPDATEBUNDLES)])
  yield all([takeEvery(actions.SAVE_BUNDLE, SAVEBUNDLES)])
  yield all([takeEvery(actions.SET_PRODUCT, SETPRODUCT)])
  yield all([takeEvery(actions.GET_BUNDLEPRODUCTS, GETBUNDLEPRODUCTS)])
  yield all([takeEvery(actions.DELETE_BUNDLEPROD, DELETEBUNDLEPROD)])
  yield all([takeEvery(actions.GET_BUNDLEPRODUCTS_BYID, GETBUNDLEPRODUCTSBYID)])
  yield all([takeEvery(actions.UPDATE_BUNDLEPROD, UPDATEBUNDLEPROD)])
  yield all([takeEvery(actions.BUNDLE_CAT, BUNDLECAT)])
  yield all([takeEvery(actions.UPDATE_BUNDLE_PROD_REDEEM_RANK, UPDATEBUNDLEPRODREDEEMRANK)])
  yield all([takeEvery(actions.SYNC_PINCODES, SYNCPINCODES)])
}
