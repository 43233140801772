const actions = {
  SET_STATE: 'webinars/SET_STATE',
  GET_WEBINARS: 'webinars/GET_WEBINARS',
  GET_SINGLEWEBINARS: 'webinars/GET_SINGLE',
  UPDATE_WEBINARS: 'webinars/UPDATE_WEBINARS',
  SAVE_WEBINARS: 'webinars/SAVE_WEBINARS',
  DELETE_WEBINARS: 'webinars/DELETE_WEBINARS',
}

export default actions
