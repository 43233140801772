const { apiClient1 } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class OfflineAppointments {
  constructor() {
    this.client = apiClient1
  }

  getRoundRobinSlots = (payload = {}) => {
    if (payload?.data?.clinicId && payload?.data?.serviceId) {
      return this.client
        .get(
          `${BACKEND_ROUTES.GET_DOCSLOTSV2_RR.replace(':serviceId', payload.data.serviceId).replace(
            ':clinicId',
            payload?.data?.clinicId || 0,
          )}`,
          {
            params: payload.data.params,
          },
        )
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    return {}
  }

  getDocSlots = (payload = {}) => {
    console.log(payload, ' get slots payload ')
    if (payload?.data?.docId && payload?.data?.serviceId) {
      return this.client
        .get(
          `${BACKEND_ROUTES.GET_DOCSLOTSV2.replace(':id', payload?.data?.docId)
            .replace(':serviceId', payload.data.serviceId)
            .replace(':clinicId', payload?.data?.clinicId || 0)}`,
          {
            params: payload.data.params,
          },
        )
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    return {}
  }

  getFollowUpSlots = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.FOLLOWUP_SLOTS}${payload.id}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getOfflineAppointments = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.CREATE_OFFLINEAPPOINTMENTS}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createBooking = (payload = {}) => {
    return this.client
      .post(
        `${BACKEND_ROUTES.CREATE_OFFLINEAPPOINTMENTS}`,
        { ...payload.data },
        { params: payload.params },
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  verifyPayment = (payload = {}) => {
    return this.client
      .post(
        `${BACKEND_ROUTES.CREATE_OFFLINEAPPOINTMENTS.concat(`/verify/${payload.id}`)}`,
        { ...payload.data.data },
        { params: payload.data.params },
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteAppointment = (payload = {}) => {
    return this.client
      .delete(`${BACKEND_ROUTES.CREATE_OFFLINEAPPOINTMENTS.concat(`/${payload.id}`)}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  resendLink = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.CREATE_OFFLINEAPPOINTMENTS.concat(`/resendPaymentLink`)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  showPaymentLink = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.CREATE_OFFLINEAPPOINTMENTS.concat(`/qr/${payload.id}`)}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleApp = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.CREATE_OFFLINEAPPOINTMENTS.concat(`/${payload.id}`)}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  verifyQR = (payload = {}) => {
    return this.client
      .post(
        `${BACKEND_ROUTES.CREATE_OFFLINEAPPOINTMENTS.concat(
          `/verify/qr/${payload.id}?include=users,payments,userProfile`,
        )}`,
        {
          // params: payload.data
        },
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default OfflineAppointments
