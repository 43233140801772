const { BACKEND_ROUTES } = require('common/constant')
const { apiClient } = require('utilities/configureAxios')

class SupportGroupsServices {
  constructor() {
    this.client = apiClient
    // https://api.pfhtesting.proactiveforher.com/api/v1
  }

  getSupportGroups = (payload = {}) => {
    // console.log('service called')
    // console.log(`${BACKEND_ROUTES.GET_WEBINARS}`)
    return this.client
      .get(`${BACKEND_ROUTES.GET_SUPPORTGROUPS}`, { params: payload })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleSupportGroup = (payload = {}) => {
    // console.log(`${BACKEND_ROUTES.GET_WEBINARS}`)
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_SUPPORTGROUPS.concat('/').concat(temp[1])}`, {})
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateSupportGroup = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_SUPPORTGROUPS.concat('/').concat(temp[1])}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveSupportGroup = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_SUPPORTGROUPS}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteSupportGroup = (payload = {}) => {
    const deleteID = payload.id.substring(1)
    return this.client
      .delete(`${BACKEND_ROUTES.GET_SUPPORTGROUPS}/${deleteID}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default SupportGroupsServices
