import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import appointments from './appointments/sagas'
import couponCodes from './coupon-codes/sagas'
import physician from './physician/sagas'
import blogs from './blogs/sagas'
import users from './users/sagas'
import authors from './authors/sagas'
import topics from './topics/sagas'
import aoc from './aoc/sagas'
import test from './testReferral/sagas'
import specialization from './specializations/sagas'
import payments from './payments/sagas'
import programs from './programs/sagas'
import categories from './categories/sagas'
import services from './services/sagas'
import clinics from './clinics/sagas'
import pincodes from './pincodes-cities/sagas'
import partners from './partners/sagas'
import labtest from './labtests/sagas'
import labtestprofiles from './labtestprofile/sagas'
import tags from './tags/sagas'
import products from './products/sagas'
import bundles from './bundles/sagas'
import webinars from './webinars/sagas'
import supportgroups from './supportgroups/sagas'
import questions from './questions/sagas'
import company from './companies/sagas'
import videos from './videos/sagas'
import symptoms from './symptoms/sagas'
import testimonials from './testimonials/sagas'
import clinic from './clinic/sagas'
import messages from './messages/sagas'
import keywords from './keywords/sagas'
import concerns from './concerns/sagas'
import sampleType from './sampleType/sagas'
import testType from './testType/sagas'
import faqs from './faq/sagas'
import diagnosticOrders from './diagnosticOrders/sagas'
import cpOrders from './cpOrders/sagas'
import doctorServices from './doctorServices/sagas'
import banners from './banners/sagas'
import cities from './cities/sagas'
import offlineAppointments from './offlineAppointments/sagas'
import serviceTags from './serviceTags/sagas'
import dgOfflineBooking from './dgOfflineBooking/sagas'
import cpOfflineBooking from './cpOfflineBooking/sagas'
import multiCart from './multiCart/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    serviceTags(),
    offlineAppointments(),
    banners(),
    doctorServices(),
    cities(),
    cpOrders(),
    faqs(),
    testType(),
    sampleType(),
    concerns(),
    keywords(),
    bundles(),
    webinars(),
    supportgroups(),
    messages(),
    diagnosticOrders(),
    testimonials(),
    videos(),
    company(),
    questions(),
    company(),
    videos(),
    symptoms(),
    testimonials(),
    clinic(),
    messages(),
    labtestprofiles(),
    labtest(),
    tags(),
    products(),
    partners(),
    pincodes(),
    clinics(),
    categories(),
    services(),
    specialization(),
    programs(),
    payments(),
    authors(),
    topics(),
    aoc(),
    appointments(),
    couponCodes(),
    users(),
    physician(),
    blogs(),
    test(),
    menu(),
    settings(),
    dgOfflineBooking(),
    cpOfflineBooking(),
    multiCart(),
  ])
}
