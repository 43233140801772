/* eslint-disable no-unused-vars */
import axios from 'axios'
import { BACKEND_ROUTES } from 'common/constant'
import { apiClient6 } from 'utilities/configureAxios'

class ProfileServices {
  constructor() {
    this.client = apiClient6
  }

  getProfiles = (payload = {}) => {
    // console.log('service fired')
    return this.client
      .get(`${BACKEND_ROUTES.GETLABTESTPROFILES}`, { params: payload })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleProfile = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GETLABTESTPROFILES.concat('/').concat(temp[1])}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateProfile = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GETLABTESTPROFILES.concat('/').concat(temp[1])}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveProfile = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GETLABTESTPROFILES}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default ProfileServices
