import axios from 'axios'
import { BACKEND_ROUTES } from 'common/constant'
import { store, history } from '../redux/store'

export const WEB_ADDRESS = process.env.WEBSITE

const BASE_URL = process.env.REACT_APP_PFH_API_HOST
export const apiClient = axios.create({
  baseURL: BASE_URL,
})
apiClient.interceptors.request.use((request) => {
  if (request.url === BACKEND_ROUTES.LOGIN) {
    return request
  }
  let accessToken = ''
  accessToken = store.getState().user.token
  if (!accessToken) {
    const check = JSON.parse(localStorage.getItem('persist:authType'))
    if (check) {
      const { user } = check
      const out = JSON.parse(user)
      const { token } = out
      accessToken = token
    }
    history.push('/auth/login')
  }
  // cons.token
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})
apiClient.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    throw error
  },
)

const BASE_URL1 = process.env.REACT_APP_PFH_API_V2
export const apiClient1 = axios.create({
  baseURL: BASE_URL1,
})
apiClient1.interceptors.request.use((request) => {
  let accessToken = ''
  accessToken = store.getState().user.token
  if (!accessToken) {
    const check = JSON.parse(localStorage.getItem('persist:authType'))
    if (check) {
      const { user } = check
      const out = JSON.parse(user)
      const { token } = out
      accessToken = token
    }
    history.push('/auth/login')
  }
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }

  return request
})
apiClient1.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    throw error
  },
)

const BASE_URL2 = process.env.REACT_APP_PFH_API_STRAPI
export const apiClient2 = axios.create({
  baseURL: BASE_URL2,
})
apiClient2.interceptors.request.use((request) => {
  let accessToken = localStorage.getItem('persist:DGkey')
  if (!accessToken) {
    const check = JSON.parse(localStorage.getItem('persist:authType'))
    if (check) {
      const { user } = check
      const out = JSON.parse(user)
      const { token } = out
      accessToken = token
    }
    history.push('/auth/login')
  }
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }

  return request
})
apiClient2.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    throw error
  },
)

export const apiClient3 = axios.create({
  baseURL: BASE_URL1,
})
apiClient3.interceptors.request.use((request) => {
  let accessToken = localStorage.getItem('persist:DGkey')
  if (!accessToken) {
    const check = JSON.parse(localStorage.getItem('persist:authType'))
    if (check) {
      const { user } = check
      const out = JSON.parse(user)
      const { token } = out
      accessToken = token
    }
    history.push('/auth/login')
  }
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }

  return request
})
apiClient3.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    throw error
  },
)

export const apiClient4 = axios.create({
  baseURL: BASE_URL2,
})
apiClient4.interceptors.request.use((request) => {
  let accessToken = ''
  accessToken = store.getState().user.token
  if (!accessToken) {
    const check = JSON.parse(localStorage.getItem('persist:authType'))
    if (check) {
      const { user } = check
      const out = JSON.parse(user)
      const { token } = out
      accessToken = token
    }
    history.push('/auth/login')
  }
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }

  return request
})
apiClient4.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    throw error
  },
)

// const BASE_URL3 = process.env.REACT_APP_PFH_API_HOST
export const apiClient5 = axios.create({
  baseURL: BASE_URL,
})
apiClient5.interceptors.request.use((request) => {
  let accessToken = ''
  accessToken = store.getState().user.token
  if (!accessToken) {
    const check = JSON.parse(localStorage.getItem('persist:authType'))
    if (check) {
      const { user } = check
      const out = JSON.parse(user)
      const { token } = out
      accessToken = token
    }
    history.push('/auth/login')
  }
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})
apiClient5.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    throw error
  },
)

const BASE_URL6 = process.env.REACT_APP_PFH_API_PACKAGES
export const apiClient6 = axios.create({
  baseURL: BASE_URL6,
})
apiClient6.interceptors.request.use((request) => {
  let accessToken = ''
  accessToken = store.getState().user.token
  if (!accessToken) {
    const check = JSON.parse(localStorage.getItem('persist:authType'))
    if (check) {
      const { user } = check
      const out = JSON.parse(user)
      const { token } = out
      accessToken = token
    }
    history.push('/auth/login')
  }
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})
apiClient6.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    throw error
  },
)
