const actions = {
  SET_STATE: 'cp_offline_booking/SET_STATE',
  CREATE_CP_BOOKING: 'cp_offline_booking/CREATE_DG_BOOKING',
  GET_TEMP_TRANSACTIONS: 'cp_offline_booking/GET_TEMP_TRANSACTIONS',
  GET_TEMP_TRANSACTIONS_BY_ID: 'cp_offline_booking/GET_TEMP_TRANSACTIONS_BY_ID',
  SHOW_QR_LINK: 'cp_offline_booking/SHOW_QR_LINK',
  RESEND_LINK: 'cp_offline_booking/RESEND_LINK',
  VERIFY_PAYMENT: 'cp_offline_booking/VERIFY_PAYMENT',
}

export default actions
