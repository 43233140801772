import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBrowserHistory } from 'history'
import { logger } from 'redux-logger'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, ...((process.env.NODE_ENV !== 'production' && [logger]) || [])]
// if (process.env.NODE_ENV === "development") {
//   middlewares.push(logger);
// }
const persistConfig = {
  key: 'authType',
  storage,
  whitelist: ['user'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer(history))
const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(...middlewares),
    // other store enhancers if any
  ),
)

const persistor = persistStore(store)
sagaMiddleware.run(rootSaga)
export { store, persistor, history }
