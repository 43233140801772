const actions = {
  SET_STATE: 'test/SET_STATE',
  GET_TESTS: 'test/GET_TESTS',
  GET_TEST_DETAILS: 'test/GET_TEST_DETAILS',
  CREATE_TEST: 'test/CREATE_TEST',
  UPDATE_TEST: 'test/UPDATE_TEST',
  DELETE_TEST: 'test/DELETE_TEST',
}

export default actions
