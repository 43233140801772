const actions = {
  SET_STATE: 'cporders/SET_STATE',
  GET_ALLCPORDERS: 'cporders/GET_ALLCPORDERS',
  GET_SINGLE_CPORDERS: 'cporders/GET_SINGLE_CPORDERS',
  UPDATE_CPORDERS: 'cporders/UPDATE_CPORDERS',
  SAVE_CPORDERS: 'cporders/SAVE_CPORDERS',
  DELETE_CPORDERS: 'cporders/DELETE_CPORDERS',
  AVAILTCORDER: 'cporders/AVAILTCORDER',
  GETCP_ORDER_ITEMS: 'cporders/GETCP_ORDER_ITEMS',
  GET_DOCTORSLOTS: 'cporders/GET_DOCTORSLOTS',
  GET_AVAILEDORDERS: 'cporders/GET_AVAILEDORDERS',
  UPDATE_CPORDER_ITEM: 'cporders/UPDATE_CPORDER_ITEM',
  EXPORT_DATA: 'cporders/EXPORT_DATA',
}

export default actions
