const actions = {
  SET_STATE: 'videos/SET_STATE',
  GET_VIDEOS: 'videos/GET_VIDEOS',
  GET_SINGLEVIDEOS: 'videos/GET_VIDEOS',
  UPDATE_VIDEOS: 'videos/UPDATE_VIDEOS',
  SAVE_VIDEOS: 'videos/SAVE_VIDEOS',
  DELETE_VIDEOS: 'videos/DELETE_VIDEOS',
}

export default actions
