import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import MessageServices from 'services/messages'
import actions from './actions'

const services = new MessageServices()

export function* WATCH_GET_MESSAGES(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.getMessages, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { messagesData: response.data } })
  }
}

export function* WATCH_GET_SINGLEMESSAGE(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.getSingleMessage, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleMessagesData: response.data } })
  }
}

export function* WATCH_UPDATE_MESSAGE(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.updateSingleMessage, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { updateMessagesData: response.data } })
  }
}

export function* WATCH_CREATE_MESSAGE(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.saveMessage, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { createMessagesData: response.data } })
  }
}

export function* WATCHUPLOADCSV(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.saveMessage, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { createMessagesData: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_MESSAGES, WATCH_GET_MESSAGES)])
  yield all([takeEvery(actions.GET_SINGLEMESSAGE, WATCH_GET_SINGLEMESSAGE)])
  yield all([takeEvery(actions.UPDATE_MESSAGE, WATCH_UPDATE_MESSAGE)])
  yield all([takeEvery(actions.CREATE_MESSAGE, WATCH_CREATE_MESSAGE)])
  yield all([takeEvery(actions.UPLOAD_CSV, WATCHUPLOADCSV)])
}
