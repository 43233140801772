const { apiClient1 } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class ServiceTags {
  constructor() {
    this.client = apiClient1
  }

  getTags = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.DOC_SERVICES}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleTag = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.DOC_SERVICES.concat(`/${payload.id}`)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createTag = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.DOC_SERVICES}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateTag = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.DOC_SERVICES.concat(`/${payload.id}`)}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default ServiceTags
