const actions = {
  SET_STATE: 'coupon-codes/SET_STATE',
  GET_COUPON_CODES: 'coupon-codes/GET_COUPON_CODES',
  GET_COUPON_CODES_DETAILS: 'coupon-codes/GET_COUPON_CODES_DETAILS',
  UPDATE_COUPON_CODE: 'coupon-codes/UPDATE_COUPON_CODE',
  ADD_COUPON_CODE: 'coupon-codes/ADD_COUPON_CODE',
  UPDATE_COUPON_CODE_BULK: 'coupon-codes/UPDATE_COUPON_CODE_BULK',
  GET_COUPON_CODES_CONSTRANT: 'coupon-codes/GET_COUPON_CODES_CONSTRANT',
  ADD_COUPON_CONSTRAINT: 'coupon-codes/ADD_COUPON_CONSTRAINT',
  UPDATE_COUPON_CONSTRAINT: 'coupon-codes/UPDATE_COUPON_CONSTRAINT',
  UPDATE_COUPON_CONSTRAINT_BULK: 'coupon-codes/UPDATE_COUPON_CODE_CONSTRAINT',
  DELETE_CONSTRAINT_BULK: 'coupon-codes/DELETE_CONSTRAINT_BULK',
  DELETE_COUPON_CONSTRAINT: 'coupon-codes/DELETE_COUPON_CONSTRAINT',
  GET_DOCTORS: 'GET_DOCTORS',
  GET_SPECIALIZATION: 'GET_SPECIALIZATION',
  GET_DIAGNOSTIC_PRODUCTS: 'coupon-codes/GET_DIAGNOSTIC_PRODUCTS',
  GET_DIAGNOSTIC_CUSTOMPRODUCTS: 'coupon-codes/GET_DIAGNOSTIC_CUSTOMPRODUCTS',
  ADD_COUPON_CODE_DIAGNOSTIC_PRODUCTS: 'coupon-codes/ADD_COUPON_CODE_DIAGNOSTIC_PRODUCTS',
  UPDATE_DIAGNOSTIC_PRODUCT: 'coupon-codes/UPDATE_DIAGNOSTIC_PRODUCT',
  GET_COUPONCODES_PRODUCT_CONSTR: 'coupon-codes/GET_COUPONCODES_PRODUCT_CONSTR',
  GET_LINKEDCONSTRAINTS: 'coupon-codes/GET_LINKEDCONSTRAINTS',
  UPDATE_BULKDOCS: 'coupon-codes/UPDATE_BULKDOCS',
  GET_ULINKEDCONSTRAINTS: 'coupon-codes/GET_ULINKEDCONSTRAINTS',
  UPDATE_BULKUSERS: 'coupon-codes/UPDATE_BULKUSERS',
  UPDATE_BULKDOCUSER: 'coupon-codes/UPDATE_BULKDOCUSER',
  UPDATE_BULKSPECUSER: 'coupon-codes/UPDATE_BULKSPECUSER',
  DELETE_BULK: 'coupon-codes/DELETE_BULK',
  UPDATE_USER_SPECIALZATION: 'coupon-codes/UPDATE_USER_SPECIALZATION',
  UPDATE_USER_DOCTOR: 'coupon-codes/UPDATE_USER_DOCTOR',
  VERIFY_COUPON: 'coupon-codes/VERIFY_COUPON',
}

export default actions
