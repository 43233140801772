import { apiClient6, apiClient, apiClient1 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'

class CpOrderService {
  constructor() {
    this.client2 = apiClient6
    this.client = apiClient
    this.client1 = apiClient1
  }

  getCpOrders = async (payload = {}) => {
    // console.log(payload)
    return this.client2
      .get(`${BACKEND_ROUTES.GET_CPORDERS}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleCpOrder = async (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_CPORDERS.concat('/').concat(payload.id.split(':')[1])}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getCPOrderItems = async (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GETCP_ORDERITEMS}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createCpOrder = async (payload = {}) => {
    // console.log(payload)
    return this.client2
      .post(`${BACKEND_ROUTES.GET_CPORDERS}`, payload.data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateCpOrder = async (payload = {}) => {
    // console.log(payload)
    return this.client2
      .patch(`${BACKEND_ROUTES.GET_CPORDERS.concat('/').concat(payload.id.split(':')[1])}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  availTCOrder = async (payload = {}) => {
    // console.log(payload)
    return this.client
      .post(`${BACKEND_ROUTES.AVAILTCORDER}`, {
        ...payload.submitdata,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getDoctorSlots = async (payload = {}) => {
    // console.log(payload)
    return this.client1
      .get(`${BACKEND_ROUTES.GET_DOCSLOTS.concat('/').concat(payload.id)}`, {
        params: payload.submitdata,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getAvailOrders = async (payload = {}) => {
    // console.log(payload)
    return this.client2
      .get(`${BACKEND_ROUTES.GET_AVAILEDORDERS.concat(payload.id)}`, {
        params: payload.submitdata,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateCpOrderItems = async (payload = {}) => {
    // console.log(payload)
    return this.client2
      .patch(`${BACKEND_ROUTES.UPDATE_CPORDER_ITEM.concat('/').concat(payload.id)}`, {
        ...payload.submitdata,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  exportData = async (payload = {}) => {
    let url = ''
    if (payload?.data?.status === 'All') {
      url = `${BACKEND_ROUTES.GET_CPORDERS.concat(
        `/getCsvData?sort=purchaseDate:desc&items=${payload.data.items}&startDate=${payload.data.startTIme}&endDate=${payload.data.endTime}`,
      )}`
    } else {
      url = `${BACKEND_ROUTES.GET_CPORDERS.concat(
        `/getCsvData?sort=purchaseDate:desc&items=${payload.data.items}&startDate=${payload.data.startTIme}&endDate=${payload.data.endTime}&status=${payload?.data?.status}`,
      )}`
    }
    return this.client1
      .post(url, {
        // .post(`${BACKEND_ROUTES.GET_CPORDERS.concat('/getCsvData')}`, {
        // params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default CpOrderService
