import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'helper'
import AppointmentsServices from 'services/appointments'
import { notification } from 'antd'
import { history } from 'redux/store'
import actions from './actions'
// import { notification } from 'antd';

const services = new AppointmentsServices()

export function* WATCH_GET_APPOINTMENTS(action) {
  const response = yield doApiCall(services.getAppointments, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { appointmentsData: response.data } })
  }
}
export function* WATCH_GET_APPOINTMENTS_DETAILS(action) {
  const response = yield doApiCall(services.getAppointment, action.payload, actions.SET_STATE)
  // const dispatch = useDispatch()
  if (response?.success) {
    // if (
    //   ['Gynaecology', 'Dermatology', 'Endocrinology', 'Nutrition', 'Psychology'].includes(
    //     response.data.doctorDetails.specialization.name,
    //   )
    // ) {
    //   type = 'general'
    // } else if (
    //   ['Psychiatry', 'Mental Health'].includes(response.data.doctorDetails.specialization.name)
    // ) {
    //   type = 'therapist'
    // } else if (['Fitness'].includes(response.data.doctorDetails.specialization.name)) {
    //   type = 'fitness'
    // }
    yield put({
      type: actions.SET_STATE,
      payload: {
        singleappointmentsData: response.data,
      },
    })

    // dispatch({
    //   type: actions.SLOT_DATE_CHECK,
    //   payload: {
    //     id: response.data.data.doctor.id,
    //   },
    // })
  }
}
export function* WATCH_GET_SLOT_DATE_AVAILABILITY(action) {
  const response = yield doApiCall(services.slotDateCheck, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        slotAvailableDate: response?.data,
      },
    })
    if (action.payload.followup) {
      yield put({
        type: actions.SLOT_AVAILABILITY_FOLLOWUP,
        payload: {
          id: action.payload?.appId,
          data: {
            date: response.data,
            tag: action?.payload?.tag,
          },
        },
      })
    } else {
      yield put({
        type: actions.SLOT_AVAILABILITY,
        payload: {
          id: action.payload.id,
          data: {
            date: response.data,
            tag: action?.payload?.tag,
          },
        },
      })
    }
  }
}
export function* WATCH_GET_SLOT_AVAILABILITY(action) {
  const response = yield doApiCall(services.slotTimingCheck, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        slotAvailableTime: response.data,
      },
    })
  }
}

export function* WATCH_GET_SLOT_AVAILABILITY_FOLLOWUP(action) {
  const response = yield doApiCall(
    services.slotTimingCheckFollowUp,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        slotAvailableTime: response.data,
      },
    })
  }
}
export function* WATCH_INACTIVE_APPOINTMENTS(action) {
  // eslint-disable-next-line no-unused-vars
  const response = yield doApiCall(services.inactiveAppointment, action.payload, actions.SET_STATE)
}
export function* WATCH_INITIATE_REFUND(action) {
  const response = yield doApiCall(services.intitiateRefund, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Refunded',
    })
  }
}
export function* WATCH_RESCHEDULE_APPOINTMENTS(action) {
  const response = yield doApiCall(
    services.rescheduleAppointment,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    notification.success({
      description: 'Successfully Reschduled',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        singleappointmentsData: response.data,
      },
    })
    // history.goBack(-1)
  }
}
export function* WATCH_GET_PRESCRIPTION(action) {
  const response = yield doApiCall(services.getPrescription, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: { mainprescriptionData: response?.data?.data[0] },
    })
  }
}
export function* WATCH_REPORT_DOWNLOAD(action) {
  // console.log(action.payload)
  const response = yield doApiCall(services.getReport, action.payload, actions.SET_STATE)
  if (response?.success) {
    const blob = new Blob([response.data.body], { type: 'text/csv' })
    const a = document.createElement('a')
    a.download = 'appointments.csv'
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
    notification.success({
      description: 'Successfully Downloaded',
    })
  }
}

export function* GET_ALLAPPOINTMENTS(action) {
  // console.log('inside get all appointments dispatch heard', action.payload)
  const response = yield doApiCall(services.getAllAppointments, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        appointmentsData: response?.data,
      },
    })
  }
}

export function* WATCH_GET_REFRESH_PAYMENTS(action) {
  const response = yield doApiCall(services.refreshPayments, action.payload, actions.SET_STATE)
  // console.log(response?.success)
  if (response?.success) {
    history.go(0)
  }
}

export function* UPDATE_CONSULT_STATUS(action) {
  const response = yield doApiCall(services.updateconsultstatus, action.payload, actions.SET_STATE)
  if (!response) {
    notification.success({
      description: 'Successfully Updated',
    })
    action.cb()
  }
}

export function* UPDATE_CONSULT_TAG(action) {
  const response = yield doApiCall(
    services.updateconsultationtag,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        singleappointmentsData: response.data,
      },
    })
    notification.success({
      description: 'Successfully Updated',
    })
  }
}

export function* NOTIFYUSER(action) {
  const response = yield doApiCall(services.notifyUser, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        singleappointmentsData: response.data,
      },
    })
    notification.success({
      description: 'Successfully Updated',
    })
  }
}

export function* GETAPPOINTMENTSBYUUID(action) {
  const response = yield doApiCall(
    services.getAppointmentsByUUID,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { appointmentByUUID: response.data } })
  }
}

export function* UPLOADPRESCRIPTION(action) {
  const response = yield doApiCall(services.getUrl, action.payload, actions.SET_STATE)
  if (response?.success) {
    const data1 = {
      prescriptionsPdf: `${action.payload?.data?.userId}/${response?.data[0]?.key}`,
    }
    const payload = {
      id: action.payload.data.appointmentId,
      data: data1,
    }
    // eslint-disable-next-line no-unused-vars
    const resp2 = services.updateAppointmentPRESC(payload)
    // yield put({ type: actions.SET_STATE, payload: { appointmentByUUID: response.data } })
  }
}

export function* GETINVOICE(action) {
  const response = yield doApiCall(services.getInvoice, action.payload, actions.SET_STATE)
  if (response) {
    console.log(response, ' get appointments')
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { appointmentByUUID: response.data } })
  }
}

export function* RESCHEDULEGENERALAPPOINT(action) {
  const response = yield doApiCall(services.reschGeneralAppoint, action.payload, actions.SET_STATE)
  if (response) {
    console.log(response, ' get appointments')
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { appointmentByUUID: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_APPOINTMENTS, WATCH_GET_APPOINTMENTS)])
  yield all([takeEvery(actions.GET_APPOINTMENTS_DETAILS, WATCH_GET_APPOINTMENTS_DETAILS)])
  yield all([takeEvery(actions.SLOT_DATE_CHECK, WATCH_GET_SLOT_DATE_AVAILABILITY)])
  yield all([takeEvery(actions.SLOT_AVAILABILITY, WATCH_GET_SLOT_AVAILABILITY)])
  yield all([takeEvery(actions.SLOT_AVAILABILITY_FOLLOWUP, WATCH_GET_SLOT_AVAILABILITY_FOLLOWUP)])
  yield all([takeEvery(actions.INACTIVE_APPOINTMENT, WATCH_INACTIVE_APPOINTMENTS)])
  yield all([takeEvery(actions.RESCHEDULE_APPOINTMENTS, WATCH_RESCHEDULE_APPOINTMENTS)])
  yield all([takeEvery(actions.GET_REPORT, WATCH_REPORT_DOWNLOAD)])
  yield all([takeEvery(actions.INITIATE_REFUND, WATCH_INITIATE_REFUND)])
  yield all([takeEvery(actions.GET_ALL_APOINTMENTS, GET_ALLAPPOINTMENTS)])
  yield all([takeEvery(actions.REFRESH_PAYMENTS, WATCH_GET_REFRESH_PAYMENTS)])
  yield all([takeEvery(actions.GET_PRESCRIPTION, WATCH_GET_PRESCRIPTION)])
  yield all([takeEvery(actions.UPDATE_CONSULT_STATUS, UPDATE_CONSULT_STATUS)])
  yield all([takeEvery(actions.UPDATE_CONSULT_TAG, UPDATE_CONSULT_TAG)])
  yield all([takeEvery(actions.NOTIFY_USER, NOTIFYUSER)])
  yield all([takeEvery(actions.GET_APPOINTMENTSBYUUID, GETAPPOINTMENTSBYUUID)])
  yield all([takeEvery(actions.UPLOAD_PRESCRIPTION, UPLOADPRESCRIPTION)])
  yield all([takeEvery(actions.GET_INVOICE, GETINVOICE)])
  yield all([takeEvery(actions.RESCHEDULE_GENERAL_APPOINT, RESCHEDULEGENERALAPPOINT)])
}
