import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import appointments from './appointments/reducers'
import couponCodes from './coupon-codes/reducers'
import physician from './physician/reducers'
import blogs from './blogs/reducers'
import users from './users/reducers'
import authors from './authors/reducers'
import aoc from './aoc/reducers'
import topics from './topics/reducers'
import test from './testReferral/reducers'
import specialization from './specializations/reducers'
import payments from './payments/reducers'
import programs from './programs/reducers'
import categories from './categories/reducers'
import services from './services/reducers'
import clinics from './clinics/reducers'
import pincodes from './pincodes-cities/reducers'
import partners from './partners/reducers'
import labtest from './labtests/reducers'
import labtestprofiles from './labtestprofile/reducers'
import tags from './tags/reducers'
import products from './products/reducers'
import bundles from './bundles/reducers'
import webinars from './webinars/reducers'
import supportgroups from './supportgroups/reducers'
import questions from './questions/reducers'
import company from './companies/reducers'
import videos from './videos/reducers'
import symptoms from './symptoms/reducers'
import clinic from './clinic/reducers'
import testimonials from './testimonials/reducers'
import messages from './messages/reducers'
import keywords from './keywords/reducers'
import concerns from './concerns/reducers'
import sampleType from './sampleType/reducers'
import testType from './testType/reducers'
import faqs from './faq/reducers'
import diagnosticOrders from './diagnosticOrders/reducers'
import cpOrders from './cpOrders/reducers'
import doctorServices from './doctorServices/reducers'
import banners from './banners/reducers'
import cities from './cities/reducers'
import offlineAppointments from './offlineAppointments/reducers'
import serviceTags from './serviceTags/reducers'
import dgOfflineBooking from './dgOfflineBooking/reducers'
import cpOfflineBooking from './cpOfflineBooking/reducers'
import multiCart from './multiCart/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    serviceTags,
    offlineAppointments,
    cities,
    doctorServices,
    banners,
    cpOrders,
    faqs,
    testType,
    sampleType,
    keywords,
    concerns,
    webinars,
    diagnosticOrders,
    messages,
    testimonials,
    clinics,
    supportgroups,
    questions,
    company,
    videos,
    symptoms,
    clinic,
    bundles,
    tags,
    labtestprofiles,
    products,
    labtest,
    partners,
    pincodes,
    services,
    categories,
    programs,
    specialization,
    menu,
    appointments,
    couponCodes,
    payments,
    physician,
    blogs,
    authors,
    topics,
    aoc,
    users,
    test,
    settings,
    dgOfflineBooking,
    cpOfflineBooking,
    multiCart,
  })
