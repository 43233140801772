import { apiClient } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'

class AuthServices {
  constructor() {
    this.client = apiClient
  }

  login = (payload = {}) => {
    return this.client
      .post(BACKEND_ROUTES.LOGIN, payload)
      .then((res) => {
        localStorage.setItem('persist:DGkey', `${process.env.REACT_APP_DG_TOKEN}`)
        return res
      })
      .catch((error) => {
        return error
      })
  }

  userProfile = (payload = {}) => {
    return this.client
      .get(BACKEND_ROUTES.userProfile, payload)
      .then((res) => {
        // call here or wherere
        return res
      })
      .catch((error) => {
        return error
      })
  }
}
export default AuthServices
