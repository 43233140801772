import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import KeywordServices from 'services/keywords'
import { history } from 'redux/store'
import actions from './actions'

const services = new KeywordServices()

export function* GETKEYWORDS(action) {
  const response = yield doApiCall(services.getKeyWords, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { keywordsData: response.data } })
  }
}

export function* GETSINGLEKEYWORD(action) {
  const response = yield doApiCall(services.getSingleKewyWord, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleKeyData: response.data } })
  }
}

export function* UPDATE_KEYWORD(action) {
  const response = yield doApiCall(services.updateKeyWord, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Keyword Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
    history.goBack(-1)
  }
}

export function* SAVE_KEYWORD(action) {
  const response = yield doApiCall(services.saveKeyWord, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Keyword Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveKeyword: response.data } })
  }
  history.goBack(-1)
}

export function* DELETEKEYWORD(action) {
  const response = yield doApiCall(services.deleteKeyword, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Status Changed',
    })
    yield put({ type: actions.SET_STATE, payload: { deleteKeyword: response.data } })
  }
  history.goBack(-1)
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_KEYWORDS, GETKEYWORDS)])
  yield all([takeEvery(actions.GET_SINGLE_KEYWORD, GETSINGLEKEYWORD)])
  yield all([takeEvery(actions.UPDATE_KEYWORD, UPDATE_KEYWORD)])
  yield all([takeEvery(actions.SAVE_KEYWORD, SAVE_KEYWORD)])
  yield all([takeEvery(actions.DELETE_KEYWORD, DELETEKEYWORD)])
}
