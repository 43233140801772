import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
// Sentry Imports
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { history, store } from './redux/store'
import StylesLoader from './stylesLoader'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'

// mocking api
import 'services/axios/fakeApi'

// middlewared
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_URL,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
// const sagaMiddleware = createSagaMiddleware()
// const routeMiddleware = routerMiddleware(history)
// const middlewares = [sagaMiddleware, routeMiddleware]
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }

ReactDOM.render(
  <Provider store={store}>
    <StylesLoader>
      <Localization>
        <Router history={history} />
      </Localization>
    </StylesLoader>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export default store
