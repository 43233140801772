const actions = {
  SET_STATE: 'faqs/SET_STATE',
  GET_FAQS: 'faqs/GET_FAQS',
  SAVE_FAQS: 'faqs/SAVE_FAQS',
  GET_SINGLEFAQ: 'faqs/GET_SINGLEFAQS',
  UPDATE_FAQ: 'faqs/UPDATE_FAQS',
  DELETE_FAQ: 'faws/DELETE_FAQ',
}

export default actions
