import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'helper'
import BlogsServices from 'services/blogs'
import { notification } from 'antd'
import { history } from 'redux/store'
import actions from './actions'

const services = new BlogsServices()

export function* WATCH_GET_BLOGS(action) {
  const response = yield doApiCall(services.getBlogs, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { blogsData: response.data } })
  }
}
export function* WATCH_GET_COMPANIES(action) {
  const response = yield doApiCall(services.getCompanies, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { companiesData: response.data } })
  }
}
export function* WATCH_GET_AOCS(action) {
  const response = yield doApiCall(services.getAocs, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { aocsData: response.data } })
  }
}
export function* WATCH_GET_TOPICS(action) {
  const response = yield doApiCall(services.getTopics, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { topicsData: response.data } })
  }
}
export function* WATCH_GET_BLOGS_DETAILS(action) {
  const response = yield doApiCall(services.getBlog, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { singleblogData: response.data } })
  }
}

export function* WATCH_POST_BLOGS(action) {
  const response = yield doApiCall(services.addBlog, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Added Succesfully',
    })
    yield history.push('/blogs')
  }
}
export function* WATCH_PATCH_BLOGS(action) {
  const response = yield doApiCall(services.updateBlog, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Updated Succesfully',
    })
    yield history.push('/blogs')
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_BLOGS, WATCH_GET_BLOGS)])
  yield all([takeEvery(actions.GET_COMPANIES, WATCH_GET_COMPANIES)])
  yield all([takeEvery(actions.GET_AOCS, WATCH_GET_AOCS)])
  yield all([takeEvery(actions.GET_TOPICS, WATCH_GET_TOPICS)])
  yield all([takeEvery(actions.GET_BLOGS_DETAILS, WATCH_GET_BLOGS_DETAILS)])
  yield all([takeEvery(actions.UPDATE_BLOGS_DATA, WATCH_PATCH_BLOGS)])
  yield all([takeEvery(actions.ADD_BLOGS_DATA, WATCH_POST_BLOGS)])
}
