import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import ProgramServices from 'services/programs'
import actions from './actions'

const services = new ProgramServices()

export function* GETPROGRAMS(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.getPrograms, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { programsData: response.data } })
  }
}

export function* GETPROGRAMSBYTYPE(action) {
  // console.log('dispatch heard', action.load)
  const response = yield doApiCall(services.getProgramByType, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { programsData: response.data } })
  }
}

export function* DOWNLOAD(action) {
  // console.log('dispatch heard', action.load)
  const response = yield doApiCall(services.download, action.payload, actions.SET_STATE)
  if (response?.success) {
    if (response?.success) {
      const blob = new Blob([response.data.body], { type: 'text/csv' })
      const a = document.createElement('a')
      a.download = 'payments.csv'
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
      notification.success({
        description: 'Successfully Downloaded',
      })
    }
    yield put({ type: actions.SET_STATE, payload: { downloadData: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_PROGRAMS, GETPROGRAMS)])
  yield all([takeEvery(actions.GET_PROGRAMBYTYPE, GETPROGRAMSBYTYPE)])
  yield all([takeEvery(actions.DOWNLOAD, DOWNLOAD)])
}
