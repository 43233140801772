import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'helper'
import CouponCodesServices from 'services/coupon-codes'
import { notification } from 'antd'
import { history } from 'redux/store'
import actions from './actions'
// import { notification } from 'antd';

const services = new CouponCodesServices()
export function* WATCH_GET_COUPON_CODES(action) {
  const response = yield doApiCall(services.getCouponCodes, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { couponCodesData: response.data } })
    yield put({ type: actions.SET_STATE, payload: { prodConst: null } })
  }
}
export function* WATCH_GET_COUPON_CODES_DETAILS(action) {
  const response = yield doApiCall(services.getCouponCode, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        singlecouponcodesData: {
          ...response.data,
        },
      },
    })
  }
}

export function* WATCH_UPDATE_COUPON_CODE(action) {
  const response = yield doApiCall(services.updateCouponCode, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Updated Successfully',
    })
    // history.push('/coupon-codes')
  }
}

export function* WATCH_UPDATE_COUPON_CODE_BULK(action) {
  const response = yield doApiCall(services.updateCouponCodeBulk, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Added Successfully',
    })
    action.payload.cb()
  }
}

export function* WATCH_UPDATE_COUPON_CONSTRAINT_BULK(action) {
  const response = yield doApiCall(
    services.updateCouponCodeConstriantBulk,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    notification.success({
      message: 'Added Successfully',
    })
    action.payload.cb()
  }
}

export function* WATCH_ADD_COUPON_CODE(action) {
  const response = yield doApiCall(services.addCouponCode, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { createdCouponCode: response.data } })
    notification.success({
      message: 'Coupon Code Added Successfully',
    })
    history.push('/coupon-codes')
  }
}

export function* WATCH_ADD_COUPON_CONSTRAINT(action) {
  const response = yield doApiCall(services.addCouponConstraint, action.payload, actions.SET_STATE)
  if (response?.success) {
    action.payload.cb()
  }
}

export function* WATCH_UPDATE_COUPON_CONSTRANT(action) {
  const response = yield doApiCall(
    services.updateCouponConstraint,
    action.payload,
    actions.SET_STATE,
  )
  if (response?.success) {
    action.payload.cb()
    // yield put({
    //   type: actions.GET_COUPON_CODES_CONSTRANT,
    //   payload: {
    //     id: action.payload.couponId,
    //     params: {
    //       perPage: 10,
    //       page: 1,
    //       include: BucketType.indexOf(action.payload.name.toUpperCase()),
    //     },
    //   },
    // })
  }
}

export function* WATCH_GET_COUPON_CODES_CONSTRANT(action) {
  const response = yield doApiCall(services.getCouponConstraint, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        constraintData: {
          ...response.data,
        },
      },
    })
  }
}

export function* WATCH_GET_DOCTORS(action) {
  const response = yield doApiCall(services.getDoctor, action.payload, actions.SET_STATE)
  if (response?.success) {
    if (action.payload.body.associated) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          constraintData: { ...response.data },
        },
      })
    } else if (action.payload.name === 'user') {
      yield put({
        type: actions.SET_STATE,
        payload: {
          userData: response.data,
        },
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          doctorData: response.data,
        },
      })
    }
  }
}

export function* WATCH_DELETE_COUPON_CODES_CONSTRANT(action) {
  const response = yield doApiCall(
    services.deleteCouponConstraint,
    action.payload,
    actions.SET_STATE,
  )
  if (!response) {
    action.payload.cb()
  }
}

export function* WATCH_DELETE_COUPON_CODES_CONSTRANT_BULK(action) {
  const response = yield doApiCall(
    services.deleteCouponConstraintBulk,
    action.payload,
    actions.SET_STATE,
  )
  if (!response) {
    action.payload.cb()
  }
}

export function* DELETEBULKSPECUSER(action) {
  const response = yield doApiCall(
    services.deleteBulkConstarints,
    action.payload,
    actions.SET_STATE,
  )
  if (!response) {
    action.payload.cb()
  }
}

export function* UPDATEUSERSPECIALZATION(action) {
  const response = yield doApiCall(
    services.updateUserSpecializationBulkConstarints,
    action.payload,
    actions.SET_STATE,
  )
  if (!response) {
    action.payload.cb()
  }
}

export function* UPDATEUSERDOCTOR(action) {
  const response = yield doApiCall(
    services.updateUserDoctorBulkConstarints,
    action.payload,
    actions.SET_STATE,
  )
  if (!response) {
    action.payload.cb()
  }
}

export function* WATCH_GET_SPECIALIZATION(action) {
  const response = yield doApiCall(services.getSpecialization, action.payload, actions.SET_STATE)
  if (response?.success) {
    if (action.payload.associated) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          constraintData: { ...response.data },
        },
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          specializationData: response.data,
        },
      })
    }
  }
}

export function* WATCH_GET_DIAGNOSTIC_PRODUCTS(action) {
  const response = yield doApiCall(services.getAllProducts, action.payload, actions.SET_STATE)
  if (response?.data && response?.meta) {
    yield put({ type: actions.SET_STATE, payload: { diagnosticProducts: response.data } })
  }
}

export function* WATCH_GET_DIAGNOSTIC_CUSTOMPRODUCTS(action) {
  const response = yield doApiCall(services.getCustomProducts, action.payload, actions.SET_STATE)
  if (response?.data && response?.meta) {
    yield put({ type: actions.SET_STATE, payload: { customProducts: response.data } })
  }
}

export function* WATCH_GET_COUPONCODES_PRODUCT_CONSTR(action) {
  const response = yield doApiCall(services.getCouponProdConst, action.payload, actions.SET_STATE)
  if (response?.data) {
    yield put({ type: actions.SET_STATE, payload: { prodConst: response.data } })
  }
}

export function* WATCH_ADD_COUPON_CODE_DIAGNOSTIC_PRODUCTS(action) {
  const response = yield doApiCall(services.addDiagProds, action.payload, actions.SET_STATE)
  if (response?.data && response?.meta) {
    yield put({ type: actions.SET_STATE, payload: { diagnosticProducts: response.data } })
    history.push('/coupon-codes')
  }
}

export function* WATCH_UPDATE_DIAGNOSTIC_PRODUCT(action) {
  const response = yield doApiCall(services.updateDiagProds, action.payload, actions.SET_STATE)
  if (response?.data && response?.meta) {
    yield put({ type: actions.SET_STATE, payload: { diagnosticUpdateProducts: response.data } })
  }
}

export function* GETLINKEDCONSTRAINTS(action) {
  const response = yield doApiCall(services.getConstraints, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { constraints: response.data } })
  }
}

export function* UPDATEBULKDOCS(action) {
  const response = yield doApiCall(services.updateBulkDocs, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { constraints: response.data } })
    window.location.reload()
  }
}

export function* UPDATEBULKUSERS(action) {
  const response = yield doApiCall(services.updateBulkUsers, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { constraints: response.data } })
    window.location.reload()
  }
}

export function* UPDATEBULKDOCUSER(action) {
  const response = yield doApiCall(services.updateBulkDocUsers, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { constraints: response.data } })
    window.location.reload()
  }
}

export function* UPDATEBULKSPECUSER(action) {
  const response = yield doApiCall(services.updateBulkSpecUsers, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { constraints: response.data } })
    window.location.reload()
  }
}

export function* GETULINKEDCONSTRAINTS(action) {
  const response = yield doApiCall(services.getConstraints, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { unlinkedconstraints: response.data } })
  }
}

export function* VERIFY_COUPON(action) {
  const response = yield doApiCall(services.verifyCoupon, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { verify: response.data } })
    notification.success({
      description: 'Coupon Applied Successfully',
    })
  } else {
    console.log('werr ', response)
    yield put({ type: actions.SET_STATE, payload: { verify: response } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_COUPON_CODES, WATCH_GET_COUPON_CODES)])
  yield all([takeEvery(actions.GET_COUPON_CODES_DETAILS, WATCH_GET_COUPON_CODES_DETAILS)])
  yield all([takeEvery(actions.UPDATE_COUPON_CODE, WATCH_UPDATE_COUPON_CODE)])
  yield all([takeEvery(actions.ADD_COUPON_CODE, WATCH_ADD_COUPON_CODE)])
  yield all([takeEvery(actions.UPDATE_COUPON_CODE_BULK, WATCH_UPDATE_COUPON_CODE_BULK)])
  yield all([takeEvery(actions.GET_COUPON_CODES_CONSTRANT, WATCH_GET_COUPON_CODES_CONSTRANT)])
  yield all([takeEvery(actions.UPDATE_COUPON_CONSTRAINT_BULK, WATCH_UPDATE_COUPON_CONSTRAINT_BULK)])
  yield all([takeEvery(actions.DELETE_CONSTRAINT_BULK, WATCH_DELETE_COUPON_CODES_CONSTRANT_BULK)])
  yield all([takeEvery(actions.ADD_COUPON_CONSTRAINT, WATCH_ADD_COUPON_CONSTRAINT)])
  yield all([takeEvery(actions.UPDATE_COUPON_CONSTRAINT, WATCH_UPDATE_COUPON_CONSTRANT)])
  yield all([takeEvery(actions.DELETE_COUPON_CONSTRAINT, WATCH_DELETE_COUPON_CODES_CONSTRANT)])
  yield all([takeEvery(actions.GET_DOCTORS, WATCH_GET_DOCTORS)])
  yield all([takeEvery(actions.GET_SPECIALIZATION, WATCH_GET_SPECIALIZATION)])
  yield all([takeEvery(actions.GET_DIAGNOSTIC_PRODUCTS, WATCH_GET_DIAGNOSTIC_PRODUCTS)])
  yield all([takeEvery(actions.GET_DIAGNOSTIC_CUSTOMPRODUCTS, WATCH_GET_DIAGNOSTIC_CUSTOMPRODUCTS)])
  yield all([
    takeEvery(
      actions.ADD_COUPON_CODE_DIAGNOSTIC_PRODUCTS,
      WATCH_ADD_COUPON_CODE_DIAGNOSTIC_PRODUCTS,
    ),
  ])
  yield all([takeEvery(actions.UPDATE_DIAGNOSTIC_PRODUCT, WATCH_UPDATE_DIAGNOSTIC_PRODUCT)])
  yield all([
    takeEvery(actions.GET_COUPONCODES_PRODUCT_CONSTR, WATCH_GET_COUPONCODES_PRODUCT_CONSTR),
  ])
  yield all([takeEvery(actions.GET_LINKEDCONSTRAINTS, GETLINKEDCONSTRAINTS)])
  yield all([takeEvery(actions.UPDATE_BULKDOCS, UPDATEBULKDOCS)])
  yield all([takeEvery(actions.UPDATE_BULKUSERS, UPDATEBULKUSERS)])
  yield all([takeEvery(actions.GET_ULINKEDCONSTRAINTS, GETULINKEDCONSTRAINTS)])
  yield all([takeEvery(actions.UPDATE_BULKDOCUSER, UPDATEBULKDOCUSER)])
  yield all([takeEvery(actions.UPDATE_BULKSPECUSER, UPDATEBULKSPECUSER)])
  yield all([takeEvery(actions.DELETE_BULK, DELETEBULKSPECUSER)])
  yield all([takeEvery(actions.UPDATE_USER_SPECIALZATION, UPDATEUSERSPECIALZATION)])
  yield all([takeEvery(actions.UPDATE_USER_DOCTOR, UPDATEUSERDOCTOR)])
  yield all([takeEvery(actions.VERIFY_COUPON, VERIFY_COUPON)])
}
