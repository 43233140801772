import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'helper'
import AuthorServices from 'services/authors'
import { notification } from 'antd'
import { history } from 'redux/store'
import actions from './actions'

const services = new AuthorServices()

export function* WATCH_GET_AUTHORS(action) {
  const response = yield doApiCall(services.getAuthors, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { authorsData: response.data } })
  }
}
export function* WATCH_GET_AUTHORS_DETAILS(action) {
  const response = yield doApiCall(services.getAuthor, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { authorsData: response.data } })
  }
}

export function* WATCH_POST_AUTHORS(action) {
  const response = yield doApiCall(services.addAuthor, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Added Succesfully',
    })
    yield history.push('/authors')
  }
}
export function* WATCH_PATCH_AUTHORS(action) {
  const response = yield doApiCall(services.updateAuthor, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Updated Succesfully',
    })
    yield history.push('/authors')
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_AUTHORS, WATCH_GET_AUTHORS)])
  yield all([takeEvery(actions.GET_AUTHORS_DETAILS, WATCH_GET_AUTHORS_DETAILS)])
  yield all([takeEvery(actions.UPDATE_AUTHOR_DATA, WATCH_PATCH_AUTHORS)])
  yield all([takeEvery(actions.ADD_AUTHOR_DATA, WATCH_POST_AUTHORS)])
}
