const actions = {
  SET_STATE: 'stags/SET_STATE',
  GET_SERVICETAGS: 'stags/GET_SERVICETAGS',
  GET_SINGLESERVICETAG: 'stags/GET_SINGLESERVICETAG',
  UPDATE_SERVICETAG: 'stags/UPDATE_SERVICETAG',
  SAVE_SERVICETAG: 'stags/SAVE_SERVICETAG',
  DELETE_SERVICETAG: 'stags/DELETE_SERVICETAG',
}

export default actions
