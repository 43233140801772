const actions = {
  SET_STATE: 'testType/SET_STATE',
  GET_TESTTYPE: 'testType/GET_TESTTYPE',
  GET_SINGLETESTTYPE: 'testType/GET_SINGLETESTTYPE',
  UPDATE_TESTTYPE: 'testType/UPDATE_TESTTYPE',
  SAVE_TESTTYPE: 'testType/SAVE_TESTTYPE',
  DELETE_TESTTYPE: 'testType/DELETE_TESTTYPE',
}

export default actions
