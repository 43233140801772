import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import { history } from 'redux/store'
import PincodesCitiesServices from 'services/pincodescities'
import actions from './actions'

const services = new PincodesCitiesServices()

export function* GETPINCODESCITIES(action) {
  const response = yield doApiCall(services.getAllPincodesCities, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { pincodesData: response.data } })
  }
}

export function* GETSINGLEPINCODECITY(action) {
  const response = yield doApiCall(services.getSinglePincodeCity, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singlePincodeCity: response.data } })
  }
}

export function* UPDATEPINCODECITY(action) {
  const response = yield doApiCall(services.updatePincodeCity, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Clinic Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
    history.goBack(-1)
  }
}

export function* SAVEPINCODECITY(action) {
  const response = yield doApiCall(services.savePincodeCity, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Pincode Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCategory: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_PINCODESCITIES, GETPINCODESCITIES)])
  yield all([takeEvery(actions.GET_SINGLE_PINCODECITY, GETSINGLEPINCODECITY)])
  yield all([takeEvery(actions.UPDATE_PINCODECITY, UPDATEPINCODECITY)])
  yield all([takeEvery(actions.SAVE_PINCODECITY, SAVEPINCODECITY)])
}
