const { BACKEND_ROUTES } = require('common/constant')
const { apiClient1 } = require('utilities/configureAxios')

class MultiCartServices {
  constructor() {
    this.client = apiClient1
  }

  create = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.MULTI_CART}`, { ...payload?.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getOrders = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.MULTI_CART}`, { params: payload?.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getOrderById = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_MULTI_CART_ORDER_ID.replace(':id', payload.id)}`, {
        params: payload?.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getQRLink = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_MULTI_CART_ORDER_QR_LINK.replace(':id', payload.id)}`, {
        ...payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  resendPaymentLink = (payload = {}) => {
    return this.client
      .get(
        `${BACKEND_ROUTES.GET_MULTI_CART_ORDER_RESEND_PAYMENT_LINK.replace(':id', payload.id)}`,
        {
          ...payload,
        },
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  verifyPayment = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_MULTI_CART_ORDER_VERIFY_PAYMENT.replace(':id', payload.id)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default MultiCartServices
