import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import TestType from 'services/testType'
import { history } from 'redux/store'
import actions from './actions'

const services = new TestType()

export function* GETTESTTYPE(action) {
  const response = yield doApiCall(services.getTests, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { testTypes: response.data } })
  }
}

export function* GETSINGLETESTTYPE(action) {
  const response = yield doApiCall(services.getSingletestType, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleTestType: response.data } })
  }
}

export function* UPDATETESTTYPE(action) {
  const response = yield doApiCall(services.updateTestType, action.payload, actions.SET_STATE)
  if (response?.success) {
    console.log(response, ' update test type response')
    notification.success({
      description: 'Test Type Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updateTestType: response.data } })
    history.push('/submenu/testType')
  }
}

export function* SAVETESTTYPE(action) {
  const response = yield doApiCall(services.saveTestType, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Test Type Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveTestType: response.data } })
    history.goBack(-1)
  }
}

export function* DELETETESTTYPE(action) {
  const response = yield doApiCall(services.deleteTestType, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Test Type Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { deleteTestType: response.data } })
  }
  history.goBack(-1)
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_TESTTYPE, GETTESTTYPE)])
  yield all([takeEvery(actions.GET_SINGLETESTTYPE, GETSINGLETESTTYPE)])
  yield all([takeEvery(actions.UPDATE_TESTTYPE, UPDATETESTTYPE)])
  yield all([takeEvery(actions.SAVE_TESTTYPE, SAVETESTTYPE)])
  yield all([takeEvery(actions.DELETE_TESTTYPE, DELETETESTTYPE)])
}
