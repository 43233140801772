import { apiClient6 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'

class CityServices {
  constructor() {
    this.client2 = apiClient6
  }

  // eslint-disable-next-line no-unused-vars
  getCities = async (payload = {}) => {
    // console.log(payload)
    if (!payload.id) {
      return this.client2
        .get(`${BACKEND_ROUTES.GET_CITY}`, { params: payload })
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    return true
  }

  getSingleCity = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client2
      .get(`${BACKEND_ROUTES.GET_CITY.concat('/').concat(temp[1])}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateCity = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client2
      .patch(`${BACKEND_ROUTES.GET_CITY.concat('/').concat(temp[1])}`, { ...payload.submitdata })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveCity = async (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.GET_CITY}`, { ...payload.submitdata })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  // getSingleCategory = async (payload = {}) => {
  //   // return this.client
  //   return this.client
  //     .get(`${BACKEND_ROUTES.GET_CATEGORIES}`, {
  //       params: payload.id,
  //     })
  //     .then((res) => {
  //       return res
  //     })
  //     .catch((error) => {
  //       throw error
  //     })
  // }
}

export default CityServices
