import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import BannerService from 'services/banners'
import { history } from 'redux/store'
import actions from './actions'

const services = new BannerService()

export function* GETALLBANNERS(action) {
  const response = yield doApiCall(services.getBanners, action.payload, actions.SET_STATE)
  if (response?.success) {
    console.log(response?.data)
    yield put({ type: actions.SET_STATE, payload: { banners: response.data } })
  }
}

export function* GETSINGLEBANNERS(action) {
  const response = yield doApiCall(services.getSingleBanner, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleBanner: response.data } })
  }
}

export function* UPDATEBANNERS(action) {
  const response = yield doApiCall(services.updateBanner, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Banner Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updateBanner: response.data } })
  }
  history.goBack(-1)
}

export function* SAVEBANNERS(action) {
  const response = yield doApiCall(services.saveBanner, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Banner Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveBanner: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALLBANNERS, GETALLBANNERS)])
  yield all([takeEvery(actions.GET_SINGLE_BANNERS, GETSINGLEBANNERS)])
  yield all([takeEvery(actions.UPDATE_BANNERS, UPDATEBANNERS)])
  yield all([takeEvery(actions.SAVE_BANNERS, SAVEBANNERS)])
}
