const { BACKEND_ROUTES } = require('common/constant')
const { apiClient } = require('utilities/configureAxios')

class SymptomsService {
  constructor() {
    this.client = apiClient
    // https://api.pfhtesting.proactiveforher.com/api/v1
  }

  getSymptoms = (payload = {}) => {
    // console.log('service called')
    // console.log(`${BACKEND_ROUTES.GET_VIDEOS}`)
    return this.client
      .get(`${BACKEND_ROUTES.GET_SYMPTOMS}`, { params: payload })
      .then((res) => {
        // console.log(res, `${BACKEND_ROUTES.GET_SYMPTOMS}`)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleSymptom = (payload = {}) => {
    // console.log(`${BACKEND_ROUTES.GET_VIDEOS}`)
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_SYMPTOMS.concat('/').concat(temp[1])}`, { params: payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateSymptom = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_SYMPTOMS.concat('/').concat(temp[1])}`, {
        ...payload.submitdata,
      })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveSymptom = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_SYMPTOMS}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default SymptomsService
