import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import { history } from 'redux/store'
import CategoryServices from 'services/categories'
import actions from './actions'

const services = new CategoryServices()

export function* GETCATEGORIES(action) {
  if (!action.payload.id && !action.payload.data) {
    const response = yield doApiCall(services.getCategories, action.payload, actions.SET_STATE)
    if (response?.success) {
      yield put({ type: actions.SET_STATE, payload: { categoriesData: response.data } })
    }
  }
}

export function* GETSINGLECATEGORY(action) {
  const response = yield doApiCall(services.getSingleCategory, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleCategoryData: response.data } })
  }
}

export function* UPDATE_CATEGORY(action) {
  if (action.payload.id && action.payload.data) {
    const response = yield doApiCall(services.updateCategory, action.payload, actions.SET_STATE)
    if (response?.success) {
      notification.success({
        description: 'Category Updated',
      })
      yield put({ type: actions.SET_STATE, payload: { updatedData: response.data } })
      history.goBack(-1)
    }
  }
}

export function* SAVE_CATEGORY(action) {
  if (!action.payload.id && action.payload.data) {
    const response = yield doApiCall(services.saveCategory, action.payload, actions.SET_STATE)
    if (response?.success) {
      notification.success({
        description: 'Category Addedd',
      })
      yield put({ type: actions.SET_STATE, payload: { saveCategory: response.data } })
      history.goBack(-1)
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_CATEGORIES, GETCATEGORIES)])
  yield all([takeEvery(actions.GET_SINGLE_CATEGORY, GETSINGLECATEGORY)])
  yield all([takeEvery(actions.UPDATE_CATEGORY, UPDATE_CATEGORY)])
  yield all([takeEvery(actions.SAVE_CATEGORY, SAVE_CATEGORY)])
}
