import actions from './actions'

const initialState = {
  loading: false,
  loading2: true,
  appointmentsData: [],
  slotAvailableTime: [],
  slotAvailableDate: '',
  mainprescriptionData: {},
  filterAppointment: null,
}

export default function appointmentsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
