/* eslint-disable no-unused-vars */
import axios from 'axios'
import { BACKEND_ROUTES } from 'common/constant'
import { apiClient6 } from 'utilities/configureAxios'

class TagsServices {
  constructor() {
    this.client2 = apiClient6
  }

  getTags = (payload = {}) => {
    // console.log('service fired')
    return this.client2
      .get(`${BACKEND_ROUTES.GET_TAGS}`, { params: payload })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleTag = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client2
      .get(`${BACKEND_ROUTES.GET_TAGS.concat(`/${temp[1]}`)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateTag = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client2
      .patch(`${BACKEND_ROUTES.GET_TAGS.concat(`/${temp[1]}`)}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveTag = (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.GET_TAGS}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteTag = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client2
      .delete(`${BACKEND_ROUTES.GET_TAGS.concat('/').concat(temp[1])}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default TagsServices
