import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import { history } from 'redux/store'
import ServicesServices from 'services/service'
import actions from './actions'

const services = new ServicesServices()

export function* GETSERVICES(action) {
  const response = yield doApiCall(services.getServices, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { servicesData: response.data } })
  }
}

export function* GETSINGLECATEGORY(action) {
  const response = yield doApiCall(services.getSingleService, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleServiceData: response.data } })
  }
}

export function* UPDATEATEGORY(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.updateService, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Updated Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { updateServic: response.data } })
    history.goBack(-1)
  }
}

export function* SAVESERVICE(action) {
  const response = yield doApiCall(services.saveService, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Service Created Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { saveData: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GETALLSERVICES, GETSERVICES)])
  yield all([takeEvery(actions.GET_SINGLE_SERVICE, GETSINGLECATEGORY)])
  yield all([takeEvery(actions.UPDATE_SERVICE, UPDATEATEGORY)])
  yield all([takeEvery(actions.SAVE_SERVICE, SAVESERVICE)])
}
