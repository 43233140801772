const { BACKEND_ROUTES } = require('common/constant')
const { apiClient } = require('utilities/configureAxios')

class WebinarsServices {
  constructor() {
    this.client = apiClient
  }

  getWebinars = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_WEBINARS}`, { params: payload })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleWebinars = (payload = {}) => {
    // console.log(`${BACKEND_ROUTES.GET_WEBINARS}`)
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_WEBINARS.concat('/').concat(temp[1])}`, {})
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateWebinars = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_WEBINARS.concat('/').concat(temp[1])}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveWebinars = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_WEBINARS}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteWebinars = (payload = {}) => {
    const deleteID = payload.id.substring(1)
    return this.client
      .delete(`${BACKEND_ROUTES.GET_WEBINARS}/${deleteID}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default WebinarsServices
