import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import actions from './actions'

const { default: PaymentServices } = require('services/payments')

const services = new PaymentServices()

export function* GETPAYMENTS(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(services.getPayments, action.payload, actions.SET_STATE)
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { paymentsData: response.data } })
  }
}

export function* GETPAYMENTSBYKEY(action) {
  // console.log('dispatch heard ', action.payload)
  const response = yield doApiCall(services.getPaymentKey, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { paymentsData: response.data } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_PAYMENTS, GETPAYMENTS)])
  yield all([takeEvery(actions.GET_PAYMENTSKEY, GETPAYMENTSBYKEY)])
}
