const { BACKEND_ROUTES } = require('common/constant')
const { apiClient } = require('utilities/configureAxios')

class TestimonialService {
  constructor() {
    this.client = apiClient
  }

  getTestimonials = (payload = {}) => {
    return this.client
      .get(
        `${BACKEND_ROUTES.GET_TESTIMONIALS}?page=${
          payload.active ?? payload.page ?? 1
        }&getDefault=true`,
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleTestimonials = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_TESTIMONIALS.concat('/').concat(temp[1])}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateTestimonials = (payload = {}) => {
    const temp = payload.id.split(':')
    console.log('service called', payload)
    return this.client
      .patch(`${BACKEND_ROUTES.GET_TESTIMONIALS.concat('/').concat(temp[1])}`, {
        ...payload.data,
      })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveTestimonials = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_TESTIMONIALS}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default TestimonialService
