const actions = {
  SET_STATE: 'companies/SET_STATE',
  GET_COMPANIES: 'companies/GET_COMPANIES',
  GET_SINGLECOMPANIES: 'companies/GET_SINGLECOMPANIES',
  UPDATE_COMPANIES: 'companies/UPDATE_COMPANIES',
  SAVE_COMPANIES: 'companies/SAVE_COMPANIES',
  GET_CUSTOMERS: 'companies/GET_CUSTOMERS',
  GET_STATS: 'companies/GET_STATS',
  GET_SINGLEUSER: 'companies/GET_SINGLEUSER',
  ADD_USER: 'companies/ADD_USER',
  LOGIN_USER: 'companies/LOGIN_USER',
  UPDATE_USER: 'companies/UPDATE_USER',
  SAVE_USERS: 'companies/SAVE_USER',
  GET_CSV_DWNLD: 'companies/GET_CSV_DWNLD',
}

export default actions
