import { apiClient6 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'

class BundleServices {
  constructor() {
    this.client2 = apiClient6
  }

  // eslint-disable-next-line no-unused-vars
  getBundles = async (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_BUNDLES}`, { params: payload })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleBundles = async (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_BUNDLES.concat('/').concat(payload.id)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateBundles = async (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client2
      .patch(`${BACKEND_ROUTES.GET_BUNDLES.concat('/').concat(temp[1])}`, payload.data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveBundles = async (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.GET_BUNDLES}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  bundleProduct = async (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.CREATE_BUNDLEPRODS}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getBundleProduct = async (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.CREATE_BUNDLEPRODS}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteBundleProd = async (payload = {}) => {
    // console.log(payload)
    return this.client2
      .delete(`${BACKEND_ROUTES.CREATE_BUNDLEPRODS.concat('/').concat(payload.id)}`, {
        params: payload?.data,
      })
      .then((res) => {
        window.location.reload()
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getBundleProductByid = async (payload = {}) => {
    const temp = payload?.id?.split(':')
    return this.client2
      .get(`${BACKEND_ROUTES.CREATE_BUNDLEPRODS.concat('/getbybundleid/').concat(temp[1])}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateBundleProd = async (payload = {}) => {
    return this.client2
      .patch(`${BACKEND_ROUTES.CREATE_BUNDLEPRODS.concat('/').concat(payload.id)}`, {
        ...payload.data,
      })
      .then((res) => {
        // window.location.reload()
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  bundleCat = async (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.BUNDLE_CAT}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  bundleRedeemRank = async (payload = {}) => {
    return this.client2
      .patch(
        `${BACKEND_ROUTES.GET_BUNDLES.concat('/')
          .concat(payload.id)
          .concat('/bundleProductRanks')}`,
        {
          ...payload.redeemtable,
        },
      )
      .then((res) => {
        // window.location.reload()
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  syncPincodes = (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.GET_BUNDLES.concat('/syncPincode')}`, {
        ...payload.submitdata,
      })
      .then((res) => {
        window.location.reload()
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default BundleServices
