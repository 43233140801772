const actions = {
  SET_STATE: 'messages/SET_STATE',
  GET_MESSAGES: 'messages/GET_MESSAGES',
  GET_SINGLEMESSAGE: 'messages/GET_SINGLEMESSAGE',
  UPDATE_MESSAGE: 'messages/UPDATE_MESSAGE',
  CREATE_MESSAGE: 'messages/CREATE_MESSAGE',
  UPLOAD_CSV: 'messages/UPLOAD_CSV',
}

export default actions
