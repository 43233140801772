import { apiClient, apiClient1 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'
import dayjs from 'dayjs'
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { notification } from 'antd'
import { history } from 'redux/store'
// import { store } from 'redux/store'

class AppointmentsServices {
  constructor() {
    this.client = apiClient
    this.client1 = apiClient1
  }

  getAppointments = (payload = {}) => {
    // const { user } = store.getState().auth
    return this.client
      .get(`${BACKEND_ROUTES.GET_APPOINTMENTS.concat('?limit=15')}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getPrescription = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_PRESCRIPTION}`, {
        params: payload.params,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateconsultstatus = (payload = {}) => {
    return this.client1
      .patch(`${BACKEND_ROUTES.UPDATE_CONSULT_STATUS.replace(':id', payload.id)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateconsultationtag = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.UPDATE_APPOINTMENT_TAG.replace(':id', payload.id)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getAllAppointments = (payload = {}) => {
    return this.client1
      .get(`${BACKEND_ROUTES.GET_APPOINTMENTS.concat('?limit=15')}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getAppointment = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_APPOINTMENTS_DETAILS}/${payload.id}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  slotDateCheck = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.SLOT_DATE_CHECK}/${payload.id}`, {
        params: {
          date: dayjs().format('YYYY-MM-DD'),
        },
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  slotTimingCheck = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.SLOT_AVAILABILITY_TIME}/${payload.id}`, {
        params: payload?.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  slotTimingCheckFollowUp = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.SLOT_AVAILABILITY_TIME.concat('/followUp')}/${payload.id}`, {
        params: payload?.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  inactiveAppointment = (payload = {}) => {
    return this.client1
      .delete(`${BACKEND_ROUTES.INACTIVE_APPOINTMENTS}/${payload.id}`, {
        params: { message: payload.message },
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getReport = (payload = {}) => {
    // console.log(payload, ' service')
    return this.client
      .post(BACKEND_ROUTES.GET_REPORT.replace(':resource', 'appointments'), { params: payload })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  intitiateRefund = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.INITIATE_REFUND.replace(':id', payload.id)}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  refreshPayments = (payload = {}) => {
    return this.client
      .put(`${BACKEND_ROUTES.REFRESH_PAYMENTS.replace(':id', payload.id)}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  rescheduleAppointment = (payload = {}) => {
    return this.client1
      .put(`${BACKEND_ROUTES.RESCHEDULE_GENERAL_APPOINT}${payload.id}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  notifyUser = (payload = {}) => {
    // const temp = payload.id.split(':')
    return this.client
      .put(`${BACKEND_ROUTES.NOTIFY_USER.replace(':id', payload.id)}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getAppointmentsByUUID = (payload = {}) => {
    // const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_APPOINTMENTS.concat('/users/').concat(payload.id)}`, {
        params: payload.params,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getUrl = (payload = {}) => {
    const data = {
      n: 1,
      type: 'PRESCRIPTION_TELECONSULATION',
      userId: payload?.data?.userId,
    }
    return this.client1
      .get(`${BACKEND_ROUTES.UPLOAD_LINKSDG}`, {
        params: data,
      })
      .then(async (res) => {
        if (res) {
          const headers = {
            'Content-Type': `${payload.data.file.type}`,
          }
          if (res?.data?.data[0]?.url?.length > 0) {
            await axios
              .put(res?.data?.data[0]?.url, payload.data.file, { headers })
              .then(async (uploadresp) => {
                if (uploadresp) {
                  return res
                }
                return false
              })
          }
        }
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateAppointmentPRESC = (payload = {}) => {
    this.client
      .patch(`${BACKEND_ROUTES.GET_APPOINTMENTS_DETAILS.concat(`/${payload.id}`)}`, {
        ...payload.data,
      })
      .then((finalresp) => {
        if (finalresp?.status === 200) {
          notification.success({
            message: 'Prescription Uploaded Successfully',
          })
          history.goBack(-1)
          return finalresp
        }
        return false
      })
      .catch((error) => {
        throw error
      })
  }

  getInvoice = (payload = {}) => {
    this.client1
      .get(`${BACKEND_ROUTES.GET_INVOICES}/${payload?.type}/${payload.id}`, {
        params: payload?.data,
      })
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          window.open(res?.data?.data)
        } else {
          console.log(res?.data)
          notification.error({
            message: 'No url of invoice',
          })
        }

        return res
      })
      .catch((error) => {
        throw error
      })
  }

  reschGeneralAppoint = (payload = {}) => {
    this.client
      .put(`${BACKEND_ROUTES.RESCHEDULE_GENERAL_APPOINT.concat(`${payload.id}`)}`, {
        ...payload.data,
      })
      .then((finalresp) => {
        if (finalresp?.status === 200) {
          history.goBack(-1)
          return finalresp
        }
        return false
      })
      .catch((error) => {
        throw error
      })
  }
}
export default AppointmentsServices
