/* eslint-disable no-unused-expressions */

const { apiClient6 } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class ProductsServices {
  constructor() {
    this.client = apiClient6
  }

  getProducts = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_PRODUCTS}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleProducts = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_PRODUCTS.concat('/').concat(temp[1])}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateProducts = (payload = {}) => {
    // console.log('service called')
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_PRODUCTS.concat('/').concat(temp[1])}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveProducts = (payload = {}) => {
    // console.log('service called')
    return this.client
      .post(`${BACKEND_ROUTES.GET_PRODUCTS}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default ProductsServices
