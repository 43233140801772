import { apiClient6 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'

class SampleTypeService {
  constructor() {
    this.client2 = apiClient6
  }

  getSamples = async (payload = {}) => {
    // console.log(payload)
    return this.client2
      .get(`${BACKEND_ROUTES.GET_SAMPLES}`, { params: payload })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleSamples = async (payload = {}) => {
    console.log(payload)
    return this.client2
      .get(`${BACKEND_ROUTES.GET_SAMPLES.concat('/').concat(payload.id.split(':')[1])}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createSamples = async (payload = {}) => {
    // console.log(payload)
    return this.client2
      .post(`${BACKEND_ROUTES.GET_SAMPLES}`, payload.data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateSample = async (payload = {}) => {
    // console.log(payload)
    return this.client2
      .patch(`${BACKEND_ROUTES.GET_SAMPLES.concat('/').concat(payload.id.split(':')[1])}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default SampleTypeService
