/* eslint-disable no-unused-vars */
import axios from 'axios'
import { BACKEND_ROUTES } from 'common/constant'
import { apiClient6 } from 'utilities/configureAxios'

class FaqServices {
  constructor() {
    this.client2 = apiClient6
  }

  getFaqs = (payload = {}) => {
    // console.log('service fired')
    return this.client2
      .get(`${BACKEND_ROUTES.GET_FAQ}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleFaq = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client2
      .get(`${BACKEND_ROUTES.GET_FAQ.concat(`/${temp[1]}`)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateFaq = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client2
      .put(`${BACKEND_ROUTES.GET_FAQ.concat(`/${temp[1]}`)}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveFaq = (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.GET_FAQ}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteFaq = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client2
      .delete(`${BACKEND_ROUTES.GET_FAQ.concat('/').concat(temp[1])}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default FaqServices
