import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import ProtectedRoute from 'ProtectedRoute'
import { connect } from 'react-redux'
import Layout from 'layouts'
import { config } from '../config'
// import { config } from '../config'

// 1. Please see Config for the role array

const routes = [
  // VB:REPLACE-START:ROUTER-CONFIG
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
    role: null,
  },

  {
    path: '/specializations',
    Component: lazy(() => import('pages/specializations/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/appointments',
    Component: lazy(() => import('pages/appointments')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'dgAdmin',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r', 'w'],
      },
      {
        user: 'supportRead',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/webinars',
    Component: lazy(() => import('pages/webinars/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/webinars',
    Component: lazy(() => import('pages/webinars/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/webinars/add',
    Component: lazy(() => import('pages/webinars/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/webinars/edit/:id',
    Component: lazy(() => import('pages/webinars/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/specializations',
    Component: lazy(() => import('pages/specializations/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },

  {
    path: '/specializations/edit/:id',
    Component: lazy(() => import('pages/specializations/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },

      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/specializations/add',
    Component: lazy(() => import('pages/specializations/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },

      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/payments',
    Component: lazy(() => import('pages/payments/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/payments/details/:id',
    Component: lazy(() => import('pages/payments/details')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/programmes',
    Component: lazy(() => import('pages/programs/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r'],
      },
      {
        user: 'dgAdmin',
        access: ['r'],
      },
      {
        user: 'supportRead',
        access: ['r'],
      },
    ],
  },
  {
    path: '/testing',
    Component: lazy(() => import('pages/coupon-codes/index.js')),
    exact: true,
    role: null,
  },
  {
    path: '/appointments/edit/:id',
    Component: lazy(() => import('pages/appointments/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'supportRead',
        access: ['r'],
      },
    ],
  },
  {
    path: '/coupon-codes',
    Component: lazy(() => import('pages/coupon-codes')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/coupon-codes/edit/:id',
    Component: lazy(() => import('pages/coupon-codes/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
    ],
  },

  {
    path: '/coupon-codes/add',
    Component: lazy(() => import('pages/coupon-codes/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/coupon-codes/constraints/:id',
    Component: lazy(() => import('pages/coupon-codes/constraints')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/physicians',
    Component: lazy(() => import('pages/physicians')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/physicians/edit/:id',
    Component: lazy(() => import('pages/physicians/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/physicians/add',
    Component: lazy(() => import('pages/physicians/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },

  {
    path: '/aoc',
    Component: lazy(() => import('pages/aoc')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
    ],
  },
  {
    path: '/aoc/edit/:id',
    Component: lazy(() => import('pages/aoc/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
    ],
  },
  {
    path: '/aoc/add',
    Component: lazy(() => import('pages/aoc/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
    ],
  },
  {
    path: '/topics',
    Component: lazy(() => import('pages/topics')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
    ],
  },
  {
    path: '/topics/add',
    Component: lazy(() => import('pages/topics/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
    ],
  },
  {
    path: '/topics/edit/:id',
    Component: lazy(() => import('pages/topics/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
    ],
  },
  {
    path: '/test',
    Component: lazy(() => import('pages/test')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/test/edit/:id',
    Component: lazy(() => import('pages/test/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/test/add',
    Component: lazy(() => import('pages/test/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/authors',
    Component: lazy(() => import('pages/authors')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/authors/add',
    Component: lazy(() => import('pages/authors/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/authors/edit/:id',
    Component: lazy(() => import('pages/authors/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },

  {
    path: '/blogs',
    Component: lazy(() => import('pages/blogs')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/blogs/edit/:id',
    Component: lazy(() => import('pages/blogs/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/blogs/add',
    Component: lazy(() => import('pages/blogs/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/diagnostic/orders',
    Component: lazy(() => import('pages/diagnosticOrders/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'supportRead',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/diagnostic/orders/edit/:id',
    Component: lazy(() => import('pages/diagnosticOrders/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'supportRead',
        access: ['r'],
      },
    ],
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,

    role: null,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
    role: null,
  },
  {
    path: '/testing/add',
    Component: lazy(() => import('pages/coupon-codes/edit.js')),
    exact: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/testing/:id',
    Component: lazy(() => import('pages/coupon-codes/edit.js')),
    exact: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/icons',
    Component: lazy(() => import('pages/icons/index')),
    exact: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/icons/feather-icons',
    Component: lazy(() => import('pages/icons/feather-icons/index')),
    exact: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/icons/fontawesome',
    Component: lazy(() => import('pages/icons/fontawesome/index')),
    exact: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/icons/linearicons-free',
    Component: lazy(() => import('pages/icons/linearicons-free/index')),
    exact: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },

  {
    path: '/submenu/bundles',
    Component: lazy(() => import('pages/bundles/index')),
    exact: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/bundles/add',
    Component: lazy(() => import('pages/bundles/edit')),
    exact: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/bundles/edit/:id',
    Component: lazy(() => import('pages/bundles/edit')),
    exact: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/products',
    Component: lazy(() => import('pages/products/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },

      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/products/add',
    Component: lazy(() => import('pages/products/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },

      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/products/edit/:id',
    Component: lazy(() => import('pages/products/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },

      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/categories',
    Component: lazy(() => import('pages/categories/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/categories/add',
    Component: lazy(() => import('pages/categories/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/categories/edit/:id',
    Component: lazy(() => import('pages/categories/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/services',
    Component: lazy(() => import('pages/services/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/services/edit/:id',
    Component: lazy(() => import('pages/services/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/services/add',
    Component: lazy(() => import('pages/services/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      //    {
      //     user: 'admin',
      //    access: ['r', 'w'],
      //      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/clinics',
    Component: lazy(() => import('pages/clinics/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/clinics/add',
    Component: lazy(() => import('pages/clinics/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },

  {
    path: '/submenu/clinics/edit/:id',
    Component: lazy(() => import('pages/clinics/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/pincodes-cities',
    Component: lazy(() => import('pages/pincodes-cities/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/pincodes-cities/edit/:id',
    Component: lazy(() => import('pages/pincodes-cities/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/pincodes-cities/add',
    Component: lazy(() => import('pages/pincodes-cities/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/partners',
    Component: lazy(() => import('pages/partners/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/partners/add',
    Component: lazy(() => import('pages/partners/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/partners/edit/:id',
    Component: lazy(() => import('pages/partners/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/partners/branch',
    Component: lazy(() => import('pages/branch/branch')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/partners/branch/add',
    Component: lazy(() => import('pages/branch/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/partners/branch/edit/:id',
    Component: lazy(() => import('pages/branch/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/labtests',
    Component: lazy(() => import('pages/labtests/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/labtests/edit/:id',
    Component: lazy(() => import('pages/labtests/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/labtests/add',
    Component: lazy(() => import('pages/labtests/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/labtestprofiles',
    Component: lazy(() => import('pages/labtestprofile/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/labtestprofiles/add',
    Component: lazy(() => import('pages/labtestprofile/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/labtestprofiles/edit/:id',
    Component: lazy(() => import('pages/labtestprofile/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/Tags',
    Component: lazy(() => import('pages/tags/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/Tags/add',
    Component: lazy(() => import('pages/tags/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/Tags/edit/:id',
    Component: lazy(() => import('pages/tags/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/keywords',
    Component: lazy(() => import('pages/keywords/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/keywords/add',
    Component: lazy(() => import('pages/keywords/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/keywords/edit/:id',
    Component: lazy(() => import('pages/keywords/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/concerns',
    Component: lazy(() => import('pages/concerns/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/concerns/edit/:id',
    Component: lazy(() => import('pages/concerns/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/concerns/add',
    Component: lazy(() => import('pages/concerns/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/sampleType',
    Component: lazy(() => import('pages/sampleType/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/sampleType/add',
    Component: lazy(() => import('pages/sampleType/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/sampleType/edit/:id',
    Component: lazy(() => import('pages/sampleType/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/testType',
    Component: lazy(() => import('pages/testType/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/testType/edit/:id',
    Component: lazy(() => import('pages/testType/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/testType/add',
    Component: lazy(() => import('pages/testType/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/faqs',
    Component: lazy(() => import('pages/faqs/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/faqs/add',
    Component: lazy(() => import('pages/faqs/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/faqs/edit/:id',
    Component: lazy(() => import('pages/faqs/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/doctorServices',
    Component: lazy(() => import('pages/doctorServices/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/doctorServices/add',
    Component: lazy(() => import('pages/doctorServices/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/doctorServices/edit/:id',
    Component: lazy(() => import('pages/doctorServices/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/cpOrders',
    Component: lazy(() => import('pages/cpOrders/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'supportRead',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/cpOrders/edit/:id',
    Component: lazy(() => import('pages/cpOrders/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'supportRead',
        access: ['r'],
      },
    ],
  },
  {
    path: '/submenu/cities',
    Component: lazy(() => import('pages/cities/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/cities/add',
    Component: lazy(() => import('pages/cities/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/submenu/cities/edit/:id',
    Component: lazy(() => import('pages/cities/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/banner',
    Component: lazy(() => import('pages/banners/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/banner/edit/:id',
    Component: lazy(() => import('pages/banners/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/banner/add',
    Component: lazy(() => import('pages/banners/edit.js')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/support-groups',
    Component: lazy(() => import('pages/supportgroups/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/support-groups/add',
    Component: lazy(() => import('pages/supportgroups/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/support-groups/edit/:id',
    Component: lazy(() => import('pages/supportgroups/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/questions',
    Component: lazy(() => import('pages/questions/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/questions/edit/:id',
    Component: lazy(() => import('pages/questions/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/questions/add',
    Component: lazy(() => import('pages/questions/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/companies',
    Component: lazy(() => import('pages/companies/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r', 'w'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'supportRead',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/companies/edit/:id',
    Component: lazy(() => import('pages/companies/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/companies/add',
    Component: lazy(() => import('pages/companies/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/clinics',
    Component: lazy(() => import('pages/clinic/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/clinics/add',
    Component: lazy(() => import('pages/clinic/create')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/clinics/edit/:id',
    Component: lazy(() => import('pages/clinic/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/videos',
    Component: lazy(() => import('pages/videos/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/videos/edit/:id',
    Component: lazy(() => import('pages/videos/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/videos/add',
    Component: lazy(() => import('pages/videos/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/symptoms',
    Component: lazy(() => import('pages/symptoms/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/symptoms/add',
    Component: lazy(() => import('pages/symptoms/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/symptoms/edit/:id',
    Component: lazy(() => import('pages/symptoms/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/testimonials',
    Component: lazy(() => import('pages/testimonials/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/testimonials/edit/:id',
    Component: lazy(() => import('pages/testimonials/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/testimonials/add',
    Component: lazy(() => import('pages/testimonials/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },
  {
    path: '/user/edit/:id',
    Component: lazy(() => import('pages/companies/useredit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'supportRead',
        access: ['r'],
      },
    ],
  },
  {
    path: '/user/add',
    Component: lazy(() => import('pages/companies/useradd')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'supportRead',
        access: ['r'],
      },
    ],
  },
  {
    path: '/walk-in/:id',
    Component: lazy(() => import('pages/walk-in/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
      {
        user: 'carePackage',
        access: ['r', 'w'],
      },
      {
        user: 'admin',
        access: ['r'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'supportRead',
        access: ['r'],
      },
    ],
  },
  {
    path: '/messages',
    Component: lazy(() => import('pages/messages/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'Content + Marketing',
        access: ['r', 'w'],
      },
      {
        user: 'support',
        access: ['r'],
      },
    ],
  },

  {
    path: '/serviceTags',
    Component: lazy(() => import('pages/serviceTags/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/serviceTags/edit/:id',
    Component: lazy(() => import('pages/serviceTags/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/serviceTags/add',
    Component: lazy(() => import('pages/serviceTags/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
    ],
  },
  {
    path: '/multicart-booking',
    Component: lazy(() => import('pages/walk-in/orders/index')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
    ],
  },

  {
    path: '/multicart-booking/edit/:id',
    Component: lazy(() => import('pages/walk-in/orders/edit')),
    exact: true,
    protect: true,
    role: [
      {
        user: 'superAdmin',
        access: ['r', 'w'],
      },
      {
        user: 'dgAdmin',
        access: ['r', 'w'],
      },
    ],
  },
]

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  user,
})

// eslint-disable-next-line no-unused-vars
const Router = ({ history, routerAnimation, user }) => {
  let redirectRole = ''
  const check = JSON.parse(localStorage.getItem('persist:authType'))
  if (check) {
    const data = JSON.parse(check.user)
    redirectRole = data.role || user.user.role
  }
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <Redirect
                          to={redirectRole ? config[redirectRole].defaultRoute : '/appointments'}
                        />
                      )}
                    />
                    {routes.map(({ path, Component, exact, role, protect }) => {
                      if (protect) {
                        return (
                          <ProtectedRoute
                            component={Component}
                            path={path}
                            exact={exact}
                            key={path}
                            role={role}
                            routerAnimation={routerAnimation}
                          />
                        )
                      }
                      return (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      )
                    })}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

// VB:REPLACE-END:ROUTER-CONFIG

export default connect(mapStateToProps)(Router)
