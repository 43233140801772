import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import { history } from 'redux/store'
import ServiceTags from 'services/serviceTags'
import actions from './actions'

const services = new ServiceTags()

export function* GETSERVICETAGS(action) {
  const response = yield doApiCall(services.getTags, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { serviceTag: response } })
  }
}

export function* GETSINGLESERVICETAG(action) {
  const response = yield doApiCall(services.getSingleTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleServiceTag: response.data } })
  }
}

export function* UPDATESERVICETAG(action) {
  const response = yield doApiCall(services.updateTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedServiceTag: response.data } })
    history.goBack(-1)
  }
}

export function* SAVESERVICETAG(action) {
  const response = yield doApiCall(services.createTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield put({ type: actions.SET_STATE, payload: { newServiceTag: response.data } })
    history.goBack(-1)
  }
}

export function* DELETESERVICETAG(action) {
  const response = yield doApiCall(services?.deleteTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Deleted',
    })
    yield put({ type: actions.SET_STATE, payload: { newQuestionData: response.data } })
    history.goBack(-1)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_SERVICETAGS, GETSERVICETAGS)])
  yield all([takeEvery(actions.GET_SINGLESERVICETAG, GETSINGLESERVICETAG)])
  yield all([takeEvery(actions.UPDATE_SERVICETAG, UPDATESERVICETAG)])
  yield all([takeEvery(actions.SAVE_SERVICETAG, SAVESERVICETAG)])
  yield all([takeEvery(actions.DELETE_SERVICETAG, DELETESERVICETAG)])
}
