const { BACKEND_ROUTES } = require('common/constant')
const { apiClient } = require('utilities/configureAxios')

class ClinicsServices {
  constructor() {
    this.client = apiClient
    // https://api.pfhtesting.proactiveforher.com/api/v1
  }

  // eslint-disable-next-line no-unused-vars
  getClinic = (payload = {}) => {
    if (!payload.id) {
      return this.client
        .get(`${BACKEND_ROUTES.GET_CLINICS}`, { params: payload.data })
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
      // eslint-disable-next-line no-else-return
    } else {
      const temp = payload.id.split(':')
      return this.client
        .get(`${BACKEND_ROUTES.GET_CLINICS.concat('/').concat(temp[1])}`, {})
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
  }

  // eslint-disable-next-line no-unused-vars
  getSingleClinicss = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_CLINICS.concat('/').concat(temp[1])}`, {})
      .then((res) => {
        console.log(res, ' at sagas')
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateClinics = (payload = {}) => {
    // console.log(payload)
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_CLINICS.concat('/').concat(temp[1])}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveClinics = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.GET_CLINICS}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default ClinicsServices
