import { apiClient6 } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'

class ConcernServices {
  constructor() {
    this.client2 = apiClient6
  }

  // eslint-disable-next-line no-unused-vars
  getConcerns = async (payload = {}) => {
    // const sCategories = await axios.get(
    //   'https://sapi.pfhtesting.proactiveforher.com/api/concerns?sort[0]=rank:asc', { params : {
    //     'pagination[page]': 1,
    //     'pagination[pageSize]': 100
    //   }}
    // )
    // console.log(sCategories?.data)
    // sCategories.data.data.map(async(element)=>{

    //     // eslint-disable-next-line no-unused-vars
    //     const data = {
    //       name: element.name,
    //       status: 'ACTIVE',
    //       rank: parseInt(element.rank, 10),
    //       slug: element.slug,
    //       description: element.description,
    //       backgroundColor: '#000000',
    //     }

    //   // await this.saveConcerns(payload= {
    //   //   data
    //   // })
    //   // console.log('success', index)
    //   return true
    // })

    // // console.log(payload)
    return this.client2
      .get(`${BACKEND_ROUTES.GET_CONCERNS}`, { params: payload })
      .then((res) => {
        console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleConcerns = async (payload = {}) => {
    // return this.client
    if (payload.id && !payload.data) {
      const temp = payload.id.split(':')
      return this.client2
        .get(
          `${BACKEND_ROUTES.GET_CONCERNS.concat('/')
            .concat(temp[1])
            .concat('?include=parentId,servicesId')}`,
          { params: payload.data },
        )
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    return true
  }

  updateConcerns = async (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client2
      .patch(`${BACKEND_ROUTES.GET_CONCERNS.concat('/').concat(temp[1])}`, payload.data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveConcerns = async (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.GET_CONCERNS}`, payload.data)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteConcerns = async (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client2
      .delete(`${BACKEND_ROUTES.GET_CONCERNS.concat('/').concat(temp[1])}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default ConcernServices
