/* eslint-disable no-unused-vars */
import axios from 'axios'
import { BACKEND_ROUTES } from 'common/constant'
import { apiClient6 } from 'utilities/configureAxios'

class ServicesServices {
  constructor() {
    this.client2 = apiClient6
  }

  getServices = (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GETSERVICES}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleService = (payload = {}) => {
    // console.log(payload, ' service called')
    return this.client2
      .get(`${BACKEND_ROUTES.GETSINGLESERVICES.replace('id', payload.id)}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateService = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client2
      .put(`${BACKEND_ROUTES.GETSINGLESERVICES.replace('id', temp[1])}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveService = (payload = {}) => {
    console.log(payload, ' at service')
    return this.client2
      .post(`${BACKEND_ROUTES.GETSERVICES}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default ServicesServices
