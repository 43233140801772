import actions from './actions'

const initialState = {
  loading: false,
  blogsData: [],
  companiesData: [],
  aocsData: [],
  topicsData: [],
  singleblogData: [],
}

export default function appointmentsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
