import { apiClient } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'
// import { store } from 'redux/store'

class BlogsServices {
  constructor() {
    this.client = apiClient
  }

  getBlogs = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_BLOGS}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getCompanies = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_COMPANIES}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getAocs = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_AOC}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getTopics = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_TOPICS}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getBlog = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_BLOGS}/${payload.id}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateBlog = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.GET_BLOGS}/${payload.id}`, payload.body)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addBlog = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_BLOGS}`, payload)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}
export default BlogsServices
