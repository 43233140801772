/* eslint-disable no-unused-vars */
import axios from 'axios'
import { BACKEND_ROUTES } from 'common/constant'
import { apiClient6 } from 'utilities/configureAxios'

class PincodesCitiesServices {
  constructor() {
    this.client = apiClient6
  }

  getAllPincodesCities = (payload = {}) => {
    // console.log('service fired')
    return this.client
      .get(`${BACKEND_ROUTES.GET_PINCODES}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSinglePincodeCity = (payload = {}) => {
    if (payload.data.pin === 'pin') {
      let temp = payload.id
      temp = temp.split(':')
      console.log('here', `${BACKEND_ROUTES.GET_PINCODES.concat('/').concat(temp[1])}`)
      return (
        this.client
          // .get(`${BACKEND_ROUTES.GET_PINCODES.replace('id', temp[1])}`, { params: payload.data })
          .get(`${BACKEND_ROUTES.GET_PINCODES.concat('/').concat(temp[1])}`)
          .then((res) => {
            return res
          })
          .catch((error) => {
            throw error
          })
      )
    }
    return true
  }

  updatePincodeCity = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client
      .put(`${BACKEND_ROUTES.GET_PINCODES.concat('/').concat(temp[1])}`, { ...payload.submitdata })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  savePincodeCity = (payload = {}) => {
    console.log(payload, ' save fired')
    return this.client
      .post(`${BACKEND_ROUTES.GET_PINCODES}`, { ...payload.submitdata })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default PincodesCitiesServices
