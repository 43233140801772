import { all, put, takeEvery } from 'redux-saga/effects'
import { doApiCall } from 'helper'
import AocServices from 'services/aoc'
import { notification } from 'antd'
import { history } from 'redux/store'
import actions from './actions'

const services = new AocServices()

export function* WATCH_GET_AOCS(action) {
  const response = yield doApiCall(services.getAocs, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { aocsData: response.data } })
  }
}
export function* WATCH_GET_AOCS_DETAILS(action) {
  const response = yield doApiCall(services.getAoc, action.payload, actions.SET_STATE)
  if (response?.success) {
    // const arr = response.data.results.filter((data) => data.status !== 'INACTIVE')
    yield put({ type: actions.SET_STATE, payload: { aocsData: response.data } })
  }
}

export function* WATCH_POST_AOC(action) {
  const response = yield doApiCall(services.addAoc, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Added Succesfully',
    })
    yield history.push('/aoc')
  }
}
export function* WATCH_PATCH_AOCS(action) {
  const response = yield doApiCall(services.updateAoc, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Updated Succesfully',
    })
    yield history.push('/aoc')
  }
}
export function* WATCH_DELETE_AOC(action) {
  const response = yield doApiCall(services.deleteAoc, action.payload, actions.SET_STATE)
  if (!response) {
    yield history.push('/aoc')
  }
}

export function* WATCH_SET_CLINIC(action) {
  const response = yield doApiCall(services.setClinic, action.payload, actions.SET_STATE)
  if (response) {
    console.log(response)
    yield history.push(`/clinics`)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_AOCS, WATCH_GET_AOCS)])
  yield all([takeEvery(actions.GET_AOCS_DETAILS, WATCH_GET_AOCS_DETAILS)])
  yield all([takeEvery(actions.UPDATE_AOC_DATA, WATCH_PATCH_AOCS)])
  yield all([takeEvery(actions.ADD_AOC_DATA, WATCH_POST_AOC)])
  yield all([takeEvery(actions.DELETE_AOC, WATCH_DELETE_AOC)])
  yield all([takeEvery(actions.SET_CLINIC, WATCH_SET_CLINIC)])
}
