const { apiClient1 } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class DoctorServiceServices {
  constructor() {
    this.client = apiClient1
  }

  getDoctorServices = (payload = {}) => {
    // console.log('service called')
    return this.client
      .get(`${BACKEND_ROUTES.DOCTOR_SERVICE}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleDoctorService = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.DOCTOR_SERVICE.concat('/').concat(payload.id)}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createDoctorService = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.DOCTOR_SERVICE}`, { ...payload.submitdata })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateDoctorService = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.DOCTOR_SERVICE.concat(`/${payload.id}`)}`, {
        ...payload.submitdata,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addPools = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_PAYMENTS.replace('id', payload.page)}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteLinkedPoolId = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.DELETE_DOC_SERVICE_AVAILABILITY}`, {
        ...payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  checkDocService = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.DOCTOR_SERVICE}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default DoctorServiceServices
