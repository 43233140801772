import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import WebinarsServices from 'services/webinars'
import { history } from 'redux/store'
import actions from './actions'

const services = new WebinarsServices()

export function* GETWEBINARS(action) {
  const response = yield doApiCall(services.getWebinars, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { webinarsData: response.data } })
  }
}

export function* GETSINGLEWEBINARS(action) {
  const response = yield doApiCall(services.getSingleWebinars, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleWebinarData: response.data } })
  }
}

export function* UPDATEWEBINARS(action) {
  const response = yield doApiCall(services.updateWebinars, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updateWebinarData: response.data } })
    yield history.push('/webinars')
  }
}

export function* SAVEWEBINARS(action) {
  const response = yield doApiCall(services.saveWebinars, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Created',
    })
    yield put({ type: actions.SET_STATE, payload: { newWebinarData: response.data } })
    yield history.push('/webinars')
  }
}

export function* DELETEWEBINARS(action) {
  const response = yield doApiCall(services.deleteWebinars, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Successfully Deleted',
    })
    yield put({ type: actions.SET_STATE, payload: { newWebinarData: response.data } })
    yield history.push('/webinars')
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_WEBINARS, GETWEBINARS)])
  yield all([takeEvery(actions.GET_SINGLEWEBINARS, GETSINGLEWEBINARS)])
  yield all([takeEvery(actions.UPDATE_WEBINARS, UPDATEWEBINARS)])
  yield all([takeEvery(actions.SAVE_WEBINARS, SAVEWEBINARS)])
  yield all([takeEvery(actions.DELETE_WEBINARS, DELETEWEBINARS)])
}
