const { apiClient } = require('utilities/configureAxios')
const { BACKEND_ROUTES } = require('common/constant')

class ProgramServices {
  constructor() {
    this.client = apiClient
  }

  getPrograms = (payload = {}) => {
    // console.log('service called')
    return this.client
      .get(`${BACKEND_ROUTES.GET_PAYMENTS.replace('id', payload.page)}`, {
        params: payload.data,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getProgramByType = (payload = {}) => {
    const api = `${BACKEND_ROUTES.GET_PAYMENTS}`
    return this.client
      .get(`${api.replace('id', payload.id)}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  download = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.DOWNLOAD_PAYMENTS}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default ProgramServices
