const { BACKEND_ROUTES } = require('common/constant')
const { apiClient } = require('utilities/configureAxios')

class CompanyServices {
  constructor() {
    this.client = apiClient
    // https://api.pfhtesting.proactiveforher.com/api/v1
  }

  // eslint-disable-next-line no-unused-vars
  getCompanies = (payload = {}) => {
    // console.log('service called')
    // console.log(`${BACKEND_ROUTES.GET_WEBINARS}`)
    return this.client
      .get(`${BACKEND_ROUTES.GET_COMPANIES.concat('?getDefault=true')}`, {})
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  // eslint-disable-next-line no-unused-vars
  getSingleCompanies = (payload = {}) => {
    if (payload?.id?.length > 0) {
      const temp = payload.id.split(':')
      return this.client
        .get(`${BACKEND_ROUTES.GET_COMPANIES.concat('/').concat(temp[1])}`, {})
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error
        })
    }
    return true
  }

  updateCompanies = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_COMPANIES.concat('/').concat(temp[1])}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveCompanies = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_COMPANIES}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getCustomers = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_CUSTOMERS}`, { params: payload })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getStats = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_CUSTOMERS.concat('/stats')}`, { params: payload })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleUser = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_CUSTOMERS.concat('/').concat(temp[1])}`, {})
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveUser = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_CUSTOMERS}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateUser = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_CUSTOMERS.concat('/').concat(temp[1])}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  loginUser = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.LOGIN_USER}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  signupUser = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.SIGNUP_CUSTOMER}`, { ...payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addUser = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.SIGNUP_USER}`, { ...payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getCSV = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.EXPORTUSERS}`, { params: payload.data })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default CompanyServices
