const actions = {
  SET_STATE: 'pincodes/SET_STATE',
  GET_PINCODESCITIES: 'pincodes/GET_PINCODES',
  GET_SINGLE_PINCODECITY: 'pincodes/GET_SINGLE_PINCODES',
  UPDATE_PINCODECITY: 'pincodes/UPDATE_PINCODES',
  SAVE_PINCODECITY: 'pincodes/SAVE_PINCODES',
  DELETE_PIN: 'pincodes/DELETE_PIN',
}

export default actions
