import { doApiCall } from 'helper'
import { put, all, takeEvery, select } from 'redux-saga/effects'
import MultiCartServices from 'services/multiCart'
import { history } from 'redux/store'
import actions from './actions'

const services = new MultiCartServices()
const getCart = (state) => state.multiCart

export function* CREATE_MULTI_CART(action) {
  const response = yield doApiCall(services.create, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield history.push('/multicart-booking')
    yield put({
      type: actions.CLEAR_CART,
      data: {},
    })
  }
}

export function* ADD_MULTI_CART(action) {
  const cartState = yield select(getCart)
  yield put({
    type: actions.SET_STATE,
    payload: {
      cart: [...cartState?.cart, action?.payload?.cart],
      sellingPrice: cartState?.sellingPrice + action?.payload?.cart?.meta?.sellingPrice,
      discountedAmount:
        +cartState?.discountedAmount + +action?.payload?.cart?.meta?.discountedAmount,
    },
  })
}

export function* REMOVE_MULTI_CART(action) {
  const cartState = yield select(getCart)
  yield put({
    type: actions.SET_STATE,
    payload: {
      cart: [
        ...cartState.cart.slice(0, action?.payload?.index),
        ...cartState.cart.slice(action?.payload?.index + 1),
      ],
      sellingPrice: +cartState?.sellingPrice - +action?.payload?.record?.meta?.sellingPrice,
      discountedAmount:
        +cartState?.discountedAmount - +action?.payload?.record?.meta?.discountedAmount,
    },
  })
}

export function* EDIT_MULTI_CART(action) {
  const cartState = yield select(getCart)
  const updatedList = cartState.cart[action?.payload?.index]
  const type = updatedList?.type
  const newCart = cartState.cart.map((item, index) => {
    if (index === action?.payload?.index) {
      if (type === 'TC') {
        item = {
          ...item,
          meta: {
            ...item.meta,
            discountedAmount: action?.payload?.discount,
          },
          [type]: {
            ...item[type],
            discount: action?.payload?.discount,
          },
        }
      } else {
        item = {
          ...item,
          meta: {
            ...item.meta,
            discountedAmount: action?.payload?.discount,
          },
          [type]: {
            ...item[type],
            cart: {
              ...item[type].cart,
              discount: action?.payload?.discount,
            },
          },
        }
      }

      return {
        ...item,
        discountedAmount: action?.payload?.discount,
      }
    }
    return item
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      cart: newCart,
      discountedAmount:
        +cartState?.discountedAmount -
        +updatedList?.meta?.discountedAmount +
        +action?.payload?.discount,
    },
  })
}

export function* GET_ORDERS(action) {
  const response = yield doApiCall(services.getOrders, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { orders: response.data } })
  }
}

export function* GET_ORDERS_BY_ID(action) {
  const response = yield doApiCall(services.getOrderById, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { order: response.data } })
  }
}

export function* SHOW_QR_LINK(action) {
  const response = yield doApiCall(services.getQRLink, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { qrLink: response.data } })
  }
}

export function* RESEND_LINK(action) {
  const response = yield doApiCall(services.resendPaymentLink, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { paymentLink: response } })
  }
}

export function* VERIFY_PAYMENT(action) {
  const response = yield doApiCall(services.verifyPayment, action.payload, actions.SET_STATE)
  if (response) {
    yield put({ type: actions.SET_STATE, payload: { paymentLink: response } })
    yield history.push('/multicart-booking')
  }
}

export function* CLEAR_CART() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      cart: [],
      sellingPrice: 0,
      discountedAmount: 0,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_MULTI_CART, CREATE_MULTI_CART)])
  yield all([takeEvery(actions.ADD_MULTI_CART, ADD_MULTI_CART)])
  yield all([takeEvery(actions.REMOVE_MULTI_CART, REMOVE_MULTI_CART)])
  yield all([takeEvery(actions.EDIT_MULTI_CART, EDIT_MULTI_CART)])
  yield all([takeEvery(actions.GET_ORDERS, GET_ORDERS)])
  yield all([takeEvery(actions.GET_ORDERS_BY_ID, GET_ORDERS_BY_ID)])
  yield all([takeEvery(actions.SHOW_QR_LINK, SHOW_QR_LINK)])
  yield all([takeEvery(actions.RESEND_LINK, RESEND_LINK)])
  yield all([takeEvery(actions.VERIFY_PAYMENT, VERIFY_PAYMENT)])
  yield all([takeEvery(actions.CLEAR_CART, CLEAR_CART)])
}
