import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history, store } from 'redux/store'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import { doApiCall } from 'helper'
import actions from './actions'
import AuthServices from '../../services/auth'
// import { notification } from 'antd'
const services = new AuthServices()
const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN(action) {
  // const { email, password } = payload
  const response = yield doApiCall(services.login, action.payload, actions.SET_STATE)
  if (response?.success) {
    const { id, email, fullName, mobile, status, role } = response.data
    const { token } = response.meta
    yield put({
      type: actions.SET_STATE,
      payload: {
        user: {
          id,
          email,
          fullName,
          mobile,
          status,
          role,
        },
        role,
        token,
        authorized: true,
      },
    })

    const { user } = store.getState().user

    if (user) {
      yield history.push('/')
    }
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  // const response = yield doApiCall(services.userProfile, {}, actions.SET_STATE)

  // if (response?.success) {
  //   const { id, email, fullName, mobile, status, roleId } = response.data

  //   yield put({
  //     type: actions.SET_STATE,
  //     payload: {
  //       user: {
  //         id,
  //         email,
  //         fullName,
  //         mobile,
  //         status,
  //         roleId,
  //       },
  //       authorized: true,
  //     },
  //   })
  //   // // console.log('hererere')
  //   history.push('/')
  // }
  const check = yield JSON.parse(localStorage.getItem('persist:authType'))
  if (check) {
    const { authorized } = JSON.parse(check.user)
    if (authorized) {
      const { user } = check
      const out = JSON.parse(user)
      const { token } = out
      if (token) {
        if (window.location.pathname === '/auth/login') {
          window.location.assign('/')
        }
      }
    } else {
      yield history.push('/auth/login')
    }
  }
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      user: {},
      token: null,
      role: null,
      authorized: false,
      loading: false,
    },
  })
  yield history.push('/auth/login')
}

export default function* rootSaga() {
  yield all([
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
  ])
}
