import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import { history } from 'redux/store'
import OfflineAppointments from 'services/offlineAppointments'
import actions from './actions'

const services = new OfflineAppointments()

export function* GETOFFLINEAPPOINTMENTS(action) {
  // console.log('dispatch heard')
  const response = yield doApiCall(
    services.getOfflineAppointments,
    action.payload,
    actions.SET_STATE,
  )
  // console.log(response)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { offlineAppointments: response.data } })
  }
}

export function* GETSLOTS(action) {
  const response = yield doApiCall(services.getDocSlots, action.payload, actions.SET_STATE)
  yield put({ type: actions.SET_STATE, payload: { docSlots: [] } })
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { docSlots: response.data } })
  }
}

export function* GETRRSLOTS(action) {
  const response = yield doApiCall(services.getRoundRobinSlots, action.payload, actions.SET_STATE)
  yield put({ type: actions.SET_STATE, payload: { rrSlots: [] } })
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { rrSlots: response.data } })
  }
}

export function* GETFOLLOWUPSLOTS(action) {
  const response = yield doApiCall(services.getFollowUpSlots, action.payload, actions.SET_STATE)
  yield put({ type: actions.SET_STATE, payload: { docSlots: [] } })
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { docSlots: response.data } })
  }
}

export function* CREATEBOOKING(action) {
  const response = yield doApiCall(services.createBooking, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Appointment Created Successfully',
    })
    yield put({ type: actions.SET_STATE, payload: { createdBooking: response.data } })
  }
}

export function* VERIFYPAYMENT(action) {
  const response = yield doApiCall(services.verifyPayment, action.payload, actions.SET_STATE)
  if (response?.success) {
    if (response?.data?.status?.toLowerCase() === 'success') {
      notification.success({
        message: 'Payment Verified Appointment Booked',
      })
      yield put({ type: actions.SET_STATE, payload: { verifiedPayment: response.data } })
      history.goBack(-1)
    } else if (response?.data?.status?.toLowerCase() === 'pending') {
      notification.warning({
        message: 'Payment Pending',
      })
    } else if (response?.data?.status?.toLowerCase() === 'failed') {
      notification.error({
        message: 'Payment Failed',
      })
    }
  }
}

export function* DELETEAPPOINTMENT(action) {
  const response = yield doApiCall(services.deleteAppointment, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Booking Removed',
    })
    yield put({ type: actions.SET_STATE, payload: { deletedAppointment: response.data } })
    history.goBack(-1)
  }
}

export function* RESENDLINK(action) {
  const response = yield doApiCall(services.resendLink, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      message: 'Link sent to the registered Mobile No.',
    })
    yield put({ type: actions.SET_STATE, payload: { resendLink: response.data } })
  }
}

export function* SHOWPAYMENTLINK(action) {
  // console.log('dispatch heard', action.load)
  const response = yield doApiCall(services.showPaymentLink, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { paymentLink: response.data } })
  }
}

export function* GETSINGLEOFFAPP(action) {
  // console.log('dispatch heard', action.load)
  const response = yield doApiCall(services.getSingleApp, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleApp: response.data } })
  }
}

export function* VERIFYQR(action) {
  const response = yield doApiCall(services.verifyQR, action.payload, actions.SET_STATE)
  if (response?.success) {
    if (response?.data?.status?.toLowerCase() === 'success') {
      notification.success({
        message: 'Payment Verified Appointment Booked',
      })
      yield put({ type: actions.SET_STATE, payload: { verifiedPayment: response.data } })
      history.goBack(-1)
    } else if (response?.data?.status?.toLowerCase() === 'pending') {
      notification.warning({
        message: 'Payment Pending',
      })
    } else if (response?.data?.status?.toLowerCase() === 'failed') {
      notification.error({
        message: 'Payment Failed',
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_OFFLINEAPPOINTMENTS, GETOFFLINEAPPOINTMENTS)])
  yield all([takeEvery(actions.GET_SLOTS, GETSLOTS)])
  yield all([takeEvery(actions.GET_RR_SLOTS, GETRRSLOTS)])
  yield all([takeEvery(actions.CREATE_BOOKING, CREATEBOOKING)])
  yield all([takeEvery(actions.VERIFY_PAYMENT, VERIFYPAYMENT)])
  yield all([takeEvery(actions.DELETE_APPOINTMENT, DELETEAPPOINTMENT)])
  yield all([takeEvery(actions.RESEND_LINK, RESENDLINK)])
  yield all([takeEvery(actions.SHOW_PAYMENTLINK, SHOWPAYMENTLINK)])
  yield all([takeEvery(actions.GET_SINGLEOFFAPP, GETSINGLEOFFAPP)])
  yield all([takeEvery(actions.VERIFY_QR, VERIFYQR)])
  yield all([takeEvery(actions.GET_FOLLOWUPSLOTS, GETFOLLOWUPSLOTS)])
}
