/* eslint-disable no-unused-vars */
import axios from 'axios'
import { BACKEND_ROUTES } from 'common/constant'
import { apiClient6 } from 'utilities/configureAxios'

class PartnerServices {
  constructor() {
    this.client2 = apiClient6
  }

  getPartners = (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_PARTNERS}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSinglePartner = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client2
      .get(`${BACKEND_ROUTES.GET_PARTNERS.concat('/').concat(temp[1])}`, { params: payload.params })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updatePartner = (payload = {}) => {
    let temp = payload.id
    temp = temp.split(':')
    return this.client2
      .put(`${BACKEND_ROUTES.GET_PARTNERS.concat('/').concat(temp[1])}`, {
        ...payload.submitdata,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  savePartner = (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.GET_PARTNERS}`, { ...payload.submitdata })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  createBranch = (payload = {}) => {
    return this.client2
      .post(`${BACKEND_ROUTES.GET_PROVIDER_BRANCHES}`, { ...payload.submitdata })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateBranch = (payload = {}) => {
    return this.client2
      .put(`${BACKEND_ROUTES.GET_PROVIDER_BRANCHES}/${payload.id}`, { ...payload.submitdata })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleBranch = (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_PROVIDER_BRANCHES}/${payload.id}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getBranches = (payload = {}) => {
    return this.client2
      .get(`${BACKEND_ROUTES.GET_PROVIDER_BRANCHES}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default PartnerServices
