import { apiClient } from 'utilities/configureAxios'
import { BACKEND_ROUTES } from 'common/constant'
// import { store } from 'redux/store'

class AuthorsServices {
  constructor() {
    this.client = apiClient
  }

  getAuthors = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_USERS}`, {
        params: payload,
      })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getAuthor = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_USERS}/${payload.id}`, {})
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateAuthor = (payload = {}) => {
    return this.client
      .patch(`${BACKEND_ROUTES.GET_USERS}/${payload.id}`, payload.body)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  addAuthor = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_USERS}`, payload.body)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}
export default AuthorsServices
