import { call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'redux/store'

// const errors = (err) => {
//   const messages = {}
//   Object.keys(err).forEach((key) => {
//     const errorMessage = err[key]
//     if (!errorMessage) return ''
//     messages[key] = typeof errorMessage === 'string' ? errorMessage : errorMessage.join(', ')
//     return null
//   })
//   return messages
// }
export function* doApiCall(service, payload, ACTION_SET_STATE) {
  yield showLoading(ACTION_SET_STATE, true)
  yield put({ type: ACTION_SET_STATE, payload: { formErrors: {} } })
  try {
    const response = yield call(service, payload)
    yield showLoading(ACTION_SET_STATE, false)
    if (response.status === 204 && !payload.consult) {
      notification.success({
        description: 'Deleted Successfully',
      })
      const pathname = history.location.pathname.split('/')[1]
      history.push(`/${pathname}`)
    }
    if (response.status === 201 || response.status === 200) {
      if (response?.data?.message) {
        notification.success({
          description: response?.data?.message,
        })
      }
      // if (response.status === 403) {
      //   notification.error({
      //     message: 'Forbidden',
      //   })
      //   history.push('/auth/login')
      // }
      return response.data
    }
    if (response.status === 402 || response.status === 401) {
      notification.error({
        message: 'Unauthorized Access',
      })
      history.push('/auth/login')
    }
    if (response?.response) {
      const response1 = response.response
      if (response1.status === 422) {
        const errorData = response1?.data?.errors
        if (errorData && Object.keys(errorData).length > 0) {
          Object.values(errorData).map((val) => {
            notification.error({
              message: val,
            })
            return true
          })
        } else {
          notification.error({
            message: response1?.data?.message,
          })
        }
        // yield put({
        //   type: ACTION_SET_STATE,
        //   payload: { formErrors: errors(data.errors) },
        // })
      }
      if (response1.status === 402 || response1.status === 401 || response1.status === 403) {
        notification.error({
          message: response1?.data?.message || 'Unauthorized Access',
        })
        history.push('/auth/login')
      }
    }
    return response.data
  } catch (e) {
    yield showLoading(ACTION_SET_STATE, false)
    const { response } = e
    if (response) {
      const { status, data } = response
      if (status === 422) {
        const errorData = response?.data?.errors
        if (errorData && Object.keys(errorData).length > 0) {
          Object.values(errorData).map((val) => {
            notification.error({
              message: val,
            })
            return true
          })
        } else {
          notification.error({
            message: response?.data?.message,
          })
        }
        // yield put({
        //   type: ACTION_SET_STATE,
        //   payload: { formErrors: errors(data.errors) },
        // })
      }
      if (status === 400) {
        notification.error({
          message: data.message,
        })
      }
      if (status === 402 || status === 401) {
        notification.error({
          message: 'Unauthorized Access',
        })
        history.push('/auth/login')
      }
      if (status === 404) {
        if (data?.message) {
          notification.error({
            message: data.message,
          })
          // history.goBack(-1)
        }
        // history.push('/auth/404')
      }
      if (status === 500) {
        notification.warning({
          message: 'Internal Server Error',
        })
      }
    } else {
      notification.error({
        message: 'Network Error Detected.',
        description: 'Please check your internet connection. or Something went Wrong',
      })
      // history.push('/')
    }
  }
  return { success: false }
}

export function* showLoading(action, loading) {
  yield put({ type: action, payload: { loading } })
}
