const { BACKEND_ROUTES } = require('common/constant')
const { apiClient } = require('utilities/configureAxios')

class VideosServices {
  constructor() {
    this.client = apiClient
  }

  getVideos = (payload = {}) => {
    return this.client
      .get(`${BACKEND_ROUTES.GET_VIDEOS}`, { params: payload })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleVideos = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .get(`${BACKEND_ROUTES.GET_VIDEOS.concat('/').concat(temp[1])}`, { params: payload.data })
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  updateVideos = (payload = {}) => {
    const temp = payload.id.split(':')
    return this.client
      .patch(`${BACKEND_ROUTES.GET_VIDEOS.concat('/').concat(temp[1])}`, { ...payload.submitdata })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  saveVideos = (payload = {}) => {
    return this.client
      .post(`${BACKEND_ROUTES.GET_VIDEOS}`, { ...payload.submitdata })
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }

  deleteVideos = (payload = {}) => {
    const deleteID = payload.id.substring(1)
    return this.client
      .delete(`${BACKEND_ROUTES.GET_VIDEOS}/${deleteID}`, {})
      .then((res) => {
        //  console.log(res)
        return res
      })
      .catch((error) => {
        throw error
      })
  }
}

export default VideosServices
