import { notification } from 'antd'
import { doApiCall } from 'helper'
import { put, all, takeEvery } from 'redux-saga/effects'
import CpOrderService from 'services/cpOrders'
import { history } from 'redux/store'
import actions from './actions'

const services = new CpOrderService()

export function* GETALLCPORDERS(action) {
  const response = yield doApiCall(services.getCpOrders, action.payload, actions.SET_STATE)
  if (response?.success) {
    console.log(response?.data)
    yield put({ type: actions.SET_STATE, payload: { cpOrders: response.data } })
  }
}

export function* GETSINGLECPORDERS(action) {
  const response = yield doApiCall(services.getSingleCpOrder, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { singleCpOrder: response.data } })
  }
}

export function* UPDATECPORDERS(action) {
  const response = yield doApiCall(services.updateCpOrder, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Order Updated',
    })
    yield put({ type: actions.SET_STATE, payload: { updatedCpOrder: response.data } })
  }
  history.goBack(-1)
}

export function* SAVECPORDERS(action) {
  const response = yield doApiCall(services.saveTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Order Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCpOrder: response.data } })
    history.goBack(-1)
  }
}

export function* DELETECPORDERS(action) {
  const response = yield doApiCall(services.deleteTag, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Tag Addedd',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCpOrder: response.data } })
  }
  history.goBack(-1)
}

export function* AVAILTCORDER(action) {
  const response = yield doApiCall(services.availTCOrder, action.payload, actions.SET_STATE)
  if (response?.success) {
    notification.success({
      description: 'Appointment Created',
    })
    yield put({ type: actions.SET_STATE, payload: { saveCpOrder: response.data } })
  }
  history.goBack(-1)
}

export function* GETCPORDERITEMS(action) {
  const response = yield doApiCall(services.getCPOrderItems, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { cpOrderItems: response.data } })
  }
}

export function* GETDOCTORSLOTS(action) {
  const response = yield doApiCall(services.getDoctorSlots, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { docSlots: response.data } })
  }
}

export function* GETAVAILEDORDERS(action) {
  const response = yield doApiCall(services.getAvailOrders, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { availEdOrders: response.data } })
  }
}

export function* UPDATECPORDERITEM(action) {
  const response = yield doApiCall(services.updateCpOrderItems, action.payload, actions.SET_STATE)
  if (response?.success) {
    yield put({ type: actions.SET_STATE, payload: { updatedCpOrderItem: response.data } })
    window.location.reload()
  }
}

export function* EXPORTDATA(action) {
  const response = yield doApiCall(services.exportData, action.payload, actions.SET_STATE)
  if (response?.success) {
    const blob = new Blob([response.data.body], { type: 'text/csv' })
    const a = document.createElement('a')
    a.download = 'packageorders.csv'
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
    notification.success({
      description: 'Successfully Downloaded',
    })
    yield put({ type: actions.SET_STATE, payload: { csvOrders: response.data } })
    // window.location.reload()
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALLCPORDERS, GETALLCPORDERS)])
  yield all([takeEvery(actions.GET_SINGLE_CPORDERS, GETSINGLECPORDERS)])
  yield all([takeEvery(actions.UPDATE_CPORDERS, UPDATECPORDERS)])
  yield all([takeEvery(actions.SAVE_CPORDERS, SAVECPORDERS)])
  yield all([takeEvery(actions.DELETE_CPORDERS, DELETECPORDERS)])
  yield all([takeEvery(actions.AVAILTCORDER, AVAILTCORDER)])
  yield all([takeEvery(actions.GETCP_ORDER_ITEMS, GETCPORDERITEMS)])
  yield all([takeEvery(actions.GET_DOCTORSLOTS, GETDOCTORSLOTS)])
  yield all([takeEvery(actions.GET_AVAILEDORDERS, GETAVAILEDORDERS)])
  yield all([takeEvery(actions.UPDATE_CPORDER_ITEM, UPDATECPORDERITEM)])
  yield all([takeEvery(actions.EXPORT_DATA, EXPORTDATA)])
}
