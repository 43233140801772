import actions from './actions'

const initialState = {
  loading: true,
  physiciansData: [],
  filteredPhysiciansData: [],
  singlephysicianData: [],
  specializationData: {},
  clinicDetails: [],
  availableSlots: {},
  unavailableSlots: [],
  image: {},
  signature: {},
}

export default function appointmentsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.UPDATEIMAGE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
