const actions = {
  SET_STATE: 'questions/SET_STATE',
  GET_QUESTIONS: 'questions/GET_QUESTIONS',
  GET_SINGLEQUESTION: 'questions/GET_SINGLEQUESTIONS',
  UPDATE_QUESTION: 'questions/UPDATE_QUESTIONS',
  SAVE_QUESTION: 'questions/SAVE_QUESTIONS',
  DELETE_QUESTION: 'questions/DELETE_QUESTIONS',
}

export default actions
