const actions = {
  SET_STATE: 'topics/SET_STATE',
  GET_TOPICS: 'topics/GET_TOPICS',
  GET_TOPICS_DETAILS: 'topics/GET_TOPICS_DETAILS',
  UPDATE_TOPICS_DATA: 'topics/UPDATE_TOPICS_DATA',
  ADD_TOPICS_DATA: 'topics/ADD_TOPICS_DATA',
  DELETE_TOPIC: 'topics/DELETE_TOPIC',
}

export default actions
